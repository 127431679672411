import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../resources/images";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
    calculateSeactionEndDate, convertedNumber, getCapitalized, getCurrentDateInFormat,
    getSequnceSort, handleSeatingsDate, getDateByAddDays, addDayNameInDate, getUsStatnderdTime
} from "../../utils/helper";
import { getDimensionPlanData, getDimensionUpdatePlan } from "../../redux/actions/APIs";
import DatePicker from "react-date-picker";
import { changePace } from "../../redux/actions";

const LearnerModulePlanDetails = ({ handleShowPlanModule }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    let sittings = ["1", "2", "3", "4", "5", "6", "7"];
    let minutes = ["15", "30", "45", "60"];
    const [loader, setLoader] = useState(false);

    const { defaultChildData, changePaceResp, dashboardData, response, loggedInUser } = useSelector((state) => state.collections);

    const [activityDetail, setActivityDetail] = useState();
    const [selectedSitting, setSelectedSitting] = useState(changePaceResp?.courseName?.sittings);
    const [selectedMinutes, setSelectedMinutes] = useState(changePaceResp?.courseName?.duration);
    const [userDate, setUserDate] = useState(changePaceResp?.courseName?.startDate);
    const [courseSitting, setCourseSitting] = useState(changePaceResp?.sittings)
    const [courseDuration, setCourseDuration] = useState(changePaceResp?.duration);
    const [getUserTimeZone, setUserTimeZone] = useState(loggedInUser?.timeZone ? loggedInUser?.timeZone : "US/Eastern");

    useEffect(() => {
        if (changePaceResp) {
            setActivityDetail(getSequnceSort(changePaceResp?.courseName?.activities));
            console.log('changePaceResp?.courseName?.index : ', changePaceResp?.courseName?.index);
        }
    }, [changePaceResp])

    const handleCalculateStartDateEndDate = (selectedCourse, sitting, duration) => {
        //  console.log("handleCalculateStartDateEndDate : ", selectedCourse, sitting, duration);
        //  changePaceResp?.courseName?.index
        let tempArray = [...changePaceResp?.myPlanCourses];
        tempArray?.map((courses, index) => {
            courses.skill = []
            if (courses?.id === selectedCourse?.id) {
                //  console.log("handleCalculateStartDateEndDate 2 : ", selectedCourse, sitting, duration, changePaceResp?.courseName);
                getSequnceSort(courses?.activities)?.map((module, key) => {
                    let getDay = handleSeatingsDate(
                        parseInt(sitting),
                        parseInt(duration),
                        index,
                        key + 1
                    );
                    let currentDate = new Date();
                    if (key === 0) {
                        currentDate = userDate;
                    } else {
                        currentDate = getDateByAddDays(
                            courses?.activities[key - 1]?.endDate, 1
                        );
                    }
                    module.startDate = new Date(currentDate).toUTCString().toLocaleString("en-us", {
                        month: "short",
                        year: "numeric",
                        day: "numeric",
                    });
                    module.endDate = new Date(
                        getDateByAddDays(module.startDate, getDay - 1)
                    ).toUTCString().toLocaleString("en-us", {
                        month: "short",
                        year: "numeric",
                        day: "numeric",
                    });
                });
                courses.startDate = courses?.activities[0]?.startDate;
                courses.endDate = courses?.activities[3]?.endDate;
                courses.sittings = sitting;
                courses.duration = duration;
                dispatch(changePace({ type: "showModuleDetail", courseName: courses, myPlanCourses: tempArray, planMode: changePaceResp?.getMyEditPlan, planId: changePaceResp?.planId, timeZoneValue: changePaceResp?.timeZoneValue }));
            }
        })
    };

    const handleModuleDate = (sitting, duration) => {
        let mySitting = sitting ? sitting : selectedSitting;
        let myDuration = duration ? duration : selectedMinutes;
        handleCalculateStartDateEndDate(changePaceResp?.courseName, mySitting, myDuration);
        setSelectedSitting(mySitting);
        setSelectedMinutes(myDuration);
    }

    useEffect(() => {
        if (userDate) {
            handleModuleDate();
        }
    }, [userDate])

    const handleCourseDate = () => {
        let array = {
            sittings: courseSitting,
            duration: courseDuration,
            iscourseupdate: true,
            userId: defaultChildData?.id ? defaultChildData?.id : params?.id,
            planId: changePaceResp?.planId,
            timeZone: loggedInUser?.timeZone ? loggedInUser?.timeZone : changePaceResp?.timeZoneValue,
            courses: changePaceResp?.myPlanCourses
        };
        setLoader(true);
        console.log("changePaceResp?.myPlanCourses array : ", changePaceResp?.myPlanCourses, array);
        dispatch(getDimensionUpdatePlan(loggedInUser?.id, array));
    }

    useEffect(() => {
        if (response?.success) {
            setLoader(false);
            handleShowPlanModule(false);
        }
    }, [response])

    return (
        <div className="modal fade show d-block newinfo_popup newinfo_popupdimension newEnrollCoursPopup" role="dialog">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <div className="heading border-0 p-0 w-100">
                            <h2 className="flex">
                                <span>
                                    <img src={image.mortarboard} alt="" className="mr-2" />
                                    {changePaceResp?.courseName?.name}
                                </span>
                                <button className="btn btn-primary" data-dismiss="modal" onClick={() => handleShowPlanModule(false)}>
                                    <i className="fa-regular fa-xmark m-0"></i>
                                </button>
                            </h2>
                        </div>
                    </div>
                    {
                        activityDetail && (
                            <div className="modal-body pt-0 p-3 ">
                                {/*Start Added by alfaiz on 20-05-2024 */}
                                <div className="plan_startdate" id="LearnerModulePlanDetails">
                                    {
                                        (changePaceResp?.courseName?.planMode !== "New Plan" && !changePaceResp?.courseName?.isStarted && !changePaceResp?.courseName?.isCompleted) && (
                                            <div className="input-group calender d-flex align-items-center justify-content-between">
                                                <div className="form-group w-50">
                                                    <h5 className="p-0 pr-2 startdateLabel"> Start Date: </h5>
                                                    <label className="flexone position-relative w-60 mt-0">
                                                        <span className="clenderIcon">
                                                            <img src={image.Calendericon} />
                                                        </span>
                                                        <DatePicker className="form-control signupChildClass p-0 w-100"
                                                            clearIcon={null}
                                                            oneTap
                                                            onChange={(value) => {
                                                                setUserDate(value)
                                                            }}
                                                            value={userDate}
                                                            dayPlaceholder={"dd"}
                                                            monthPlaceholder={"mm"}
                                                            yearPlaceholder={"yyyy"}
                                                            format="MM/dd/yyyy"
                                                            placement={"topEnd"}
                                                            minDate={new Date()}
                                                        />
                                                    </label>
                                                </div>
                                                <div className="learner_pLan_dropdown">
                                                    <h5>Sessions per week</h5>
                                                    <span aria-haspopup="true"
                                                        className="pointer dropdownDiv"
                                                        aria-expanded="false"
                                                        id=""
                                                        data-toggle="dropdown">
                                                        <span className="pe-2 dropdownLabel">{selectedSitting + (selectedSitting === 1 ? " Session" : " Sessions")}</span>
                                                        <i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
                                                    </span>
                                                    <ul className="dropdown-menu dropdown-menu-end lPDropdwnList" aria-labelledby="">
                                                        {sittings?.map((value, index) => (
                                                            <li key={index} onClick={() => handleModuleDate(value, "")}>
                                                                {value} Session{index > 0 ? "s" : ""}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                <div className="learner_pLan_dropdown">
                                                    <h5>Duration per session</h5>
                                                    <span aria-haspopup="true"
                                                        className="pointer dropdownDiv"
                                                        aria-expanded="false"
                                                        id=""
                                                        data-toggle="dropdown">
                                                        <span className="pe-2 dropdownLabel">{selectedMinutes + " minutes"}</span>
                                                        <i className="fa fa-angle-down fa-lg" aria-hidden="true"></i>
                                                    </span>

                                                    <ul className="dropdown-menu dropdown-menu-end lPDropdwnList" aria-labelledby="">
                                                        {minutes?.map((value, index) => (
                                                            <li key={index} onClick={() => handleModuleDate("", value)}>
                                                                {value} minutes
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div class='mt-3 table-responsive'>
                                        <table class='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th className="text-start">
                                                        <span className="ps-2">
                                                            Modules
                                                        </span>
                                                    </th>
                                                    <th className="text-center"> Start Date</th>
                                                    <th className="text-center"> End Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activityDetail?.map((module, key) => (
                                                    <tr key={key}>
                                                        <td className="ps-3">
                                                            <span>
                                                                <i
                                                                    className="fa fa-angle-right fa-sm pe-2"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </span>
                                                            {module?.name}
                                                        </td>
                                                        <td>{addDayNameInDate(getUsStatnderdTime(module?.startDate, getUserTimeZone))}</td>
                                                        <td>{addDayNameInDate(getUsStatnderdTime(module?.endDate, getUserTimeZone))}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/*End Added by alfaiz on 20-05-2024 */}
                            </div>
                        )
                    }
                    <div className="modal-footer">
                        <div className="form-group BDsubmitbutton d-flex m-0">
                            <div className="buttonDistribotion">
                                <div className="buttonDistribotion justify-content-end">
                                    <button
                                        type="button"
                                        className="btn-blue btn-login d-block mb-5 cancelbutton"
                                        onClick={() => handleShowPlanModule(false)}
                                    >
                                        <span><i className="fa-solid fa-xmark"></i> </span>Close
                                    </button>
                                    {
                                        (changePaceResp?.courseName?.planMode !== "New Plan" && !changePaceResp?.courseName?.isStarted && !changePaceResp?.courseName?.isCompleted) && (
                                            loader ? (
                                                <div className="justify-content-end">
                                                    <button
                                                        className="btn-blue btn-login d-block ml-auto mr-2  w-auto"
                                                        disabled
                                                    >
                                                        <span className="RounAnimation mr-1"></span> Please
                                                        Wait...
                                                    </button>
                                                </div>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn-blue btn-login d-block pt-1 pb-1 w-auto"
                                                    onClick={() => handleCourseDate()}
                                                ><i class="fa-solid fa-paper-plane mr-2" aria-hidden="true">
                                                    </i>Submit
                                                </button>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnerModulePlanDetails;
