import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../resources/images";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { convertedNumber, getCapitalized } from "../../utils/helper";
import { PATHS } from "../../utils";
import { resetResponse, showModal } from "../../redux/actions";
import { NavLink } from "react-bootstrap";
const MyLearnerPlanRedirectPopup = ({ handleRedirectPopUp }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { defaultChildData, loggedInUser } = useSelector((state) => state.collections);

    return (
        <div className="sharepopup  AreyousurePopup areyousurenewpopup welcome_new_poup">
            <div className="modal  show d-flex" id="schoolactivity161" role="dialog">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content courseInformation schoolcourse ">
                        <div className="modal-body p-5 pb-3 mx-3">
                            <div className="sharewithfrnd ">
                                <h3 className="text-center">Welcome to the Learning Plan builder!</h3>
                                <p className="mb-3 pb-2 pt-2 mt-3 text-left" >
                                    You do not currently have a learning plan for {getCapitalized(defaultChildData?.firstName)}.
                                    Building a plan only takes a few minutes and can be changed or added at any time. Click below to begin, you will see a recommended plan to start with.

                                </p>
                            </div>
                            {loggedInUser?.role?.name === "PARENT" && (
                                <div className="welcomeAgain_Poup skipnow p-5 pt-2 pb-2">
                                    <ul className="flex pointer">
                                        <li onClick={() => dispatch(showModal())}>
                                            <p className="pointer">Skip Now</p>
                                        </li>
                                    </ul>
                                </div>
                            )}
                            <div className="buttonDistribotion justify-content-center flex-wrap mt-3 skipnow">
                                {
                                    loggedInUser?.role?.name === "PARENT" ? (
                                        <button
                                            type="button"
                                            className="btn-blue btn-login d-block mb-5 w-auto"
                                            onClick={() => handleRedirectPopUp(false)}
                                        >
                                            Create a Learning Plan
                                        </button>
                                    ) : (
                                        <ul className="flex ">
                                            <li onClick={() => dispatch(showModal())}>
                                                <p className="pointer">Skip Now</p>
                                            </li>
                                        </ul>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MyLearnerPlanRedirectPopup;