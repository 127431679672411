import React, { useEffect, useState } from "react";
import { Circle } from "rc-progress";
import * as image from "../../resources/images";
import { getSequnceSort } from "../../utils/helper";
import ReactTooltip from "react-tooltip";
import { ShimmerTable } from "react-shimmer-effects";

const StudentAdvancementSummary = ({ dashBoardplanSummaryData, activitiesData, reportType, courseObj, showDimProgress }) => {

	const [openAccordionIndex, setOpenAccordionIndex] = useState(0);
	const toggleAccordion = (index) => {
		setOpenAccordionIndex(openAccordionIndex === index ? null : index);
	};
	return (
		<>
			<h4>
				<img src={image.PlanSummaryIcon} alt="" className="mr-2" />
				Advancement Summary
			</h4>
			{getSequnceSort(dashBoardplanSummaryData)?.map((courses, index) => (
				(
					(courses?.id === courseObj?.id || showDimProgress || reportType === "PlanSummary") && (
						<div class="AccordianTabl mb-3 mt-3 advancementreportsumm">
							{showDimProgress && (
								<h5>
									<div class="PlanTblCourse_Name flexone">
										<span>C{courses?.sequence}</span>
										<div>
											<strong>{courses?.name}</strong>
										</div>
									</div>

									<p className="Coursecompprcent position-relative pointer ml-auto mr-3">
										<span>
											{" "}
											<span className="PercentCompleted">
												{courses.progress}
												<span className="percenrSign">%</span>
											</span>
										</span>
										<Circle
											percent={courses.progress}
											strokeWidth={20}
											strokeColor="#329fff"
										/>
									</p>
									<span
										class="GrowthSurvyCollapse flexone pointer"
										href={"#GrowthSurvytbl0" + index}
										aria-expanded={openAccordionIndex === index}
										onClick={() => toggleAccordion(index)}
									>
										<i class="fa-light fa-chevron-down"></i>
									</span>
								</h5>
							)}
							<div
								className={`${showDimProgress && `StugrowthSrvy_bg GrowthSurvytbl px-3 pb-3 panel-collapse collapse ${openAccordionIndex === index ? "show" : ""}`}`}
								id={`GrowthSurvytbl0${index}`}
							>
								<div className="d-flex pt-4 plansummryheading">
									<h3>
										<img
											src={image.courselevel1}
											className="mr-2"
											alt=""
											style={{ width: "26px" }}
										/>{" "}
										Module
									</h3>
									<h3>
										<img src={image.LessonIcon1} className="mr-2" alt="" /> Lesson
									</h3>
									<h3>
										<img src={image.Scenenavigation} className="mr-2" alt="" />{" "}
										Knowledge Check
									</h3>
									<h3>
										<img src={image.RPExcercise} className="mr-2" alt="" />{" "}
										Excercise
									</h3>
								</div>
								{getSequnceSort(courses?.activities)?.map((module, key) => (
									(module?.id === activitiesData?.id || !activitiesData || ["PlanSummary"]?.includes(reportType)) && (
										<div
											className="d-flex justify-content-between TeacherDashboard"
											key={key}
										>
											<p className="Coursecompprcent position-relative flexone">
												<div className="">
													{/* <span className="modulecountcir">M{key + 1}</span> */}

													<div
														className="moduleName"
														data-for={`modulename-${key}`}
														data-tip
													>
														{/* <strong>{module?.name}</strong> */}
														<span className="PercentCompleted">
															{module?.progress}
															<span className="percenrSign">%</span>
														</span>
														<Circle
															percent={module?.progress}
															strokeWidth={12}
															strokeColor="#329fff"
														/>

														<ReactTooltip
															id={`modulename-${key}`}
															place="top"
															className="tooltip text-center"
														>
															<p>{module?.name}</p>
														</ReactTooltip>
														<p className="modulenumprog">M{key + 1}</p>
													</div>
												</div>
											</p>
											<p className="Coursecompprcent position-relative flexone">
												<span>
													{" "}
													<span className="PercentCompleted">
														{module?.lesson}
														<span className="percenrSign">%</span>
													</span>
													<Circle
														percent={module?.lesson}
														strokeWidth={12}
														strokeColor="#3ec093"
													/>
												</span>
											</p>

											<p className="Coursecompprcent position-relative pt-1 pb-2 w-100">
												<span>
													{" "}
													<div className="d-flex knowledgeCheckCircleMain align-items-center">
														<div className="indivisectionprog">
															<span className="mb-1 titleTxt">Completion </span>
															<div className="graphbatteryprogress">
																<span
																	className="batterycells"
																	style={{
																		height: module?.kc + "%",
																	}}
																></span>
															</div>
															<span className="numberDigit">
																{module?.kcUserCount}
																<small>/{module?.kcTotalCount}</small>
															</span>
														</div>
														<div className="knowledgeCheckCircle d-flex flex-column align-items-center pt-1">
															<span className="titleTxt">Understanding</span>
															<span className="PercentCompleted">
																{module?.understanding}
																<span className="percenrSign">%</span>
															</span>
															<Circle
																percent={module?.understanding}
																strokeWidth={12}
																strokeColor="#6B84B9"
															/>
															{/* <span className="numberDigit">10<small>/20</small></span> */}
															<span className="numberDigit">
																{module?.kcUserCount}
																<small>/{module?.kcTotalCount}</small>
															</span>
														</div>
													</div>
												</span>
											</p>

											<p className="Coursecompprcent position-relative flexone">
												<span>
													{" "}
													<span className="PercentCompleted">
														{module?.ex}
														<span className="percenrSign">%</span>
													</span>
													<Circle
														percent={module?.ex}
														strokeWidth={12}
														strokeColor="#FF9800"
													/>
												</span>
											</p>
										</div>
									)
								))}
							</div>
						</div>
					)
				)

			))}
		</>
	);
};

export default StudentAdvancementSummary;
