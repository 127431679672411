import React, { useState } from "react";
import * as image from "../../resources/images";
import {
	getCapitalized,
	getDimIcon,
	getDimIconWithSpace,
	getProfileName,
	getRankSort,
	getSequnceSort,
	isDimension,
	showRoundValue,
	textTrim,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import CourseProgress from "./CourseProgress";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { holisticViewData, setCourseModal } from "../../redux/actions";
import CourseComingSoon from "./CourseComingSoon";
import {
	getCourseDetails,
	getDashboard,
	showOverallModal,
	sideBarProgress,
} from "../../redux/actions/Home";
import CongratulationCard from "../widget/CongratulationCard";
import LessonCongratulationsPopup from "../dimensions/course/vickycourses/LessonCongratulationsPopup";
import ShowOverallProgressPopup from "./ShowOverallProgressPopup";
import ShareCoursePopup from "./ShareCoursePopup";
import {
	getSocialActivityDetail,
	getStartEnrollCourses,
	popularCourses,
} from "../../redux/actions/APIs";
import { PATHS } from "../../utils";
import { ShimmerCategoryItem, useHistory } from "../../utils/Packages";

const Sidebar = ({ data, toggleState, handleToggleState }) => {
	const {
		dimViewObj,
		skillProg,
		popularCourseObj,
		dashboardData,
		courseDetails,
	} = useSelector((state) => state.home);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		// dispatch(getPopularCourse());
	}, []);
	const {
		selectedDim,
		defaultChildData,
		startEnrollCourses,
		courseEnrollResponse,
		showHolisticViewData,
	} = useSelector((state) => state.collections);

	const { dimSkillObj } = useSelector((state) => state.home);
	const [showProgress, setShowProgress] = useState(true);
	const [numberOfCourses, setNumberOfCourses] = useState(1);
	const [skills, setSkills] = useState([]);
	const [dimSkils, setDimSkills] = useState([]);
	const [dimPercentage , setDimPercentage] = useState("");

	useEffect(()=>{
		if(showHolisticViewData){
			setShowDimCourse(false);
		}
	},[showHolisticViewData])

	
	useEffect(() => {
		const selectedDimension = dashboardData?.dimensions?.find(
			(dim) => dim?.name === selectedDim?.value
		);
		setDimPercentage(selectedDimension);
		if (selectedDimension) {
			setSkills(selectedDimension?.connectedSkill);
			setDimSkills(getSequnceSort(selectedDimension?.skills));
		}
	}, [selectedDim, dashboardData]);
	//   const hasProgress = skills?.some((skill) => skill.scprogress > 0);


	const showEnrolledCourse = (result) => {
		setShowProgress(!showProgress);
	};
	let totalProgress = 0;
	let numDimensions = 0;

	dashboardData &&
		dashboardData?.dimensions &&
		dashboardData.dimensions.forEach((vl, ky) => {
			totalProgress += vl.progress;
			numDimensions++;
		});

	const avgValue = totalProgress / numDimensions + 20;

	useEffect(() => {
		if (courseDetails) {
			//  console.log('courseDetails : ', courseDetails);
			courseDetails.totalLevel = totalLevel;
			dispatch(setCourseModal(courseDetails));
		}
	}, [courseDetails]);

	const [totalLevel, setTotalLevels] = useState(0);

	const handleExploreClick = (courseId, totalLevel) => {
		setTotalLevels(totalLevel);
		dispatch(getCourseDetails(courseId, defaultChildData.id));
	};

	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [congratulationPopup, setCongratulationPopup] = useState(false);
	const [courseDetail, setCourseDetail] = useState([]);

	const [courseActivityId, setCourseActivityId] = useState([]);

	const handleCongratulationPopup = (vl) => {
		setCongratulationPopup(vl);
	};

	const getDataFromCourseProgress = (result, data, course) => {
		setCourseDetail(course);
		setCourseActivityId(data);
		dispatch(getSocialActivityDetail());
		if (course?.isAcademic) {
			setCongratulationPopup(result);
		} else {
			setShowCongratulationModel(result);
		}
	};

	const showOverall = () => {
		dispatch(showOverallModal(true));
	};

	const [showOverallProgressPopup, setShowOverallProgressPopup] =
		useState(false);
	const [shareCoursePopup, setShareCoursePopup] = useState();

	const handleShowOverallProgressPopup = (result) => {
		setShowOverallProgressPopup(result);
	};

	const handleSharePopup = (value) => {
		setShareCoursePopup(value);
		if (defaultChildData?.id) {
			// dispatch(getStartEnrollCourses(defaultChildData?.id));
			dispatch(getDashboard(defaultChildData?.id));
		}
	};

	const [showDimCourse, setShowDimCourse] = useState(false);
	const [dimCourse, setDimCourse] = useState([]);
	let [enrollCount, setEnrollCount] = useState(0);

	useEffect(() => {
		setShowDimCourse(false);
		getSequnceSort(dashboardData?.dimensions)?.map((dimension, key) => {
			if (dimension?.id === selectedDim?.key) {
				setDimCourse(dimension?.skills);
				let counter = 0;
				dimension?.skills?.map((course, index) => {
					if (
						course?.completion > 0 ||
						(course?.isEnrolled && course?.type !== "VICKY")
					) {
						counter++;
					}
				});
				setEnrollCount(counter);
			}
		});
	}, [selectedDim?.key]);

	// const handleShowDimCourse = (result) => {
	// 	dispatch(holisticViewData(false));
	// 	setShowDimCourse(result);
	// };

	const handleShowDimCourse = (result, isCourse) => {
		dispatch(sideBarProgress());
		dispatch(holisticViewData(false));
		setShowDimCourse(result);
	
	};

	useEffect(() => {
		if (courseEnrollResponse && defaultChildData?.id) {
			dispatch(popularCourses(defaultChildData?.id));
		}
	}, [courseEnrollResponse]);

	const [showAllPopularCourses, setShowAllPopularCourses] = useState(false);

	const [courseList, setCourseList] = useState([]);
	useEffect(() => {
		if (popularCourseObj) {
			const allCourses =
				popularCourseObj.records.length > 0 &&
				getSequnceSort(popularCourseObj.records).flatMap((dimension) => {
					const skills = getSequnceSort(dimension.skills);
					if (skills.length > 0) {
						return (
							skills.length > 0 &&
							skills.flatMap((skill) => {
								const courses = getSequnceSort(skill.courses);

								if (courses) {
									return courses.map((course) => {
										const modifiedCourse = {
											...course,
											skills: {
												id: skill.id,
												name: skill.name,
											},
											dimension: {
												key: dimension?.id,
												value: dimension?.name,
											},
										};

										return modifiedCourse;
									});
								} else {
									return [];
								}
							})
						);
					} else {
						return [];
					}
				});

			setCourseList(getRankSort(allCourses));
		}
	}, [popularCourseObj]);

	const visiblePopularCourses = showAllPopularCourses
		? courseList &&
		courseList?.length > 0 &&
		courseList.slice(0, 5 * numberOfCourses)
		: courseList &&
		courseList?.length > 0 &&
		courseList.slice(0, 5 * numberOfCourses);
	const totlecal = showRoundValue(dashboardData?.progress) * 0.3;

	const showLearnerPlan = () => {
		//	history.push(PATHS.STR_LEARNER_PLAN + defaultChildData?.id)
	};

	console.log('selectedDim : ', selectedDim);

	return (
		<>
			<div className='RightbarPannel p-0 rightpannelSticky'>
				{dimViewObj && !skillProg ? (
					<div className='heading p-0 border-0'>

						<h2 className='flex'>
							{/* <span>
								<img src={image.Chartimgicon} alt='' className='mr-2' />
								Holistic View
							</span>
							<div className='form-check form-switch m-0 flex p-0'>
								<label
									className='form-check-label pointer'
									htmlFor='flexSwitchCheckDefault'>
									Courses
								</label>
								<input
									className='form-check-input avltogglebutton pointer'
									type='radio'
									role='switch'
									id='flexSwitchCheckDefault'
									checked={showDimCourse}
									onClick={() => handleShowDimCourse(!showDimCourse)}
									disabled={enrollCount > 0 ? false : true}
								/>
							</div> */}
							<div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
								<h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
									<span className="flexone">
										{
											showDimCourse ? (
												<>
													<span className="mr-2"><img src={image.mortarboard} alt="" /></span>
													Course Progress
												</>
											) : (
												dimViewObj && !skillProg && dashboardData && !showDimCourse && showHolisticViewData ? 
												<><span className="mr-2"><img src={image.skillsConnectionIcon} alt="" /></span> Skills Connection</>
												:
												<span> {getDimIconWithSpace(selectedDim?.value)} {selectedDim?.value + " Dimension"}</span>
											)
										}
										{/* <span> {getDimIconWithSpace(selectedDim?.value)} {selectedDim?.value + " Dimension"}</span> */}
										{/* <img src={selectedTab == "Search" ? image.leanerPlan : image.leanerPlan1} className='mr-2' alt="" /> */}
										{/* {selectedTab == "Search" ? <span>  <img src={image.searchandtag} alt="" /> Search & Tags</span>
											: selectedTab == "Dimensions" ? <span>  <img src={image.dimension3d} alt="" />Dimensions & Skills</span>
												: <> <span> <i class="fa-light fa-rocket-launch mr-2 mt-2"></i></span> Recommend A Plan</>
										} */}
									</span>
									{
										!showHolisticViewData && (
											<div className="learner_pLan_dropdown">
												<span aria-haspopup="true"
													className="pointer"
													aria-expanded="false"
													id="leaneringplndwn"
													data-toggle="dropdown">
													<i class="fa-sharp fa-solid fa-bars"></i>
												</span>
												<ul className="dropdown-menu dropdown-menu-end lPDropdwnList search_tag_dimens" aria-labelledby="leaneringplndwn">
													<>
														<li class={`tabs ${!showDimCourse ? "active-tabs" : ""} w-100`} onClick={() => handleShowDimCourse(false)}>
															{/* <img src={image.Chartimgicon} alt="" />Holistic View */}
															<span> {getDimIconWithSpace(selectedDim?.value)} {selectedDim?.value + " Dimension"}</span>
														</li>
														<li class={`tabs ${dimSkils.filter((skill) => skill?.isEnrolled)?.length > 0 ? "" : "pe-none disabled"} ${showDimCourse ? "active-tabs" : ""} w-100`} onClick={() => handleShowDimCourse(true)}>
															<span className="mr-2"><img src={image.mortarboard} alt="" /></span>
															Course Progress
														</li>
													</>
												</ul>
											</div>
										)
									}
								</h2>
							</div>
							{/* <div class="tabgrid w-100 m-0  ">
								<ul>
									<li class={`tabs ${!showDimCourse ? "active-tabs" : ""} w-50`} onClick={() => handleShowDimCourse(false)}>
										<img src={image.Chartimgicon} alt="" />Holistic View</li>
									<li class={`tabs ${showDimCourse ? "active-tabs" : ""} w-50`} onClick={() => handleShowDimCourse(true)}>
										<img src={image.mortarboard} alt="" />
										Courses</li>
								</ul>
							</div> */}
							{/* <span>
								<img
									src={image.chat_icon}
									className="chat_icon pointer"
									alt=""
									onClick={() => showOverall()}
								/>
							</span> */}
						</h2>
					</div>

				) : isDimension(selectedDim?.value) ? (
					<div className='heading'>
						<h2 className='flex'>
						{(skillProg && dimViewObj) ? 	
							<><span className="flexone"><span className="mr-2"><img src={image.mortarboard} alt="" /></span>Course Progress </span></>
							: 
							<span>{getDimIcon(selectedDim.value)} {selectedDim.value} Dimension</span>
						}

							{/* <div className="form-check form-switch m-0 flex">
								<label
									className="form-check-label pointer"
									htmlFor="flexSwitchCheckDefault"
								>
									Section
								</label>
								<input
									className="form-check-input avltogglebutton pointer"
									type="radio"
									role="switch"
									id="flexSwitchCheckDefault"
									onClick={() => showEnrolledCourse(showProgress)}
									checked={showProgress}
								/>
							</div> */}
						</h2>
					</div>
				) : (
					<div className='heading'>
						<h2 className='flex summery_plan'>
							<span>
								<img src={image.mortarboard} alt='' className='mr-2' />
								Most Popular Courses
							</span>
							{dashboardData?.userPlans?.length > 0 && (
								<div className='form-check form-switch fixedexpandall flexone p-0 '>
									<label class='form-check-label' for='flexSwitchCheckDefault'>
										Plan
									</label>
									<input
										className='form-check-input'
										type='checkbox'
										role='switch'
										checked={toggleState}
										id='flexSwitchCheckDefault'
										onClick={() => handleToggleState(true)}
									/>
								</div>
							)}
						</h2>
					</div>
				)}

				{!dimViewObj && visiblePopularCourses?.length > 0 ? (
					<div className='popularcourse pt-2'>
						<ul>
							{visiblePopularCourses &&
								visiblePopularCourses.map((value, key) => (
									<li key={key}>
										{value.name.length > 26 ? (
											<ReactTooltip id={value?.name}>
												{value?.name}
											</ReactTooltip>
										) : (
											""
										)}
										<span
											data-for={value.name}
											data-event-off=''
											data-tip
											className='pointer'
											onClick={() =>
												handleExploreClick(value?.id, value?.totalLevel)
											}>
											<img src={value.imageUrl} alt='' />
											{textTrim(value.name, 26)}
										</span>
										<button
											type='button'
											className='btn-blue btn-login d-block pt-1 pb-1 w-auto left_auto'
											onClick={() =>
												handleExploreClick(value?.id, value?.totalLevel)
											}>
											<i className='fa-sharp fa-regular fa-magnifying-glass-arrow-right mr-2'></i>
											Explore{" "}
										</button>
									</li>
								))}

							{/* {!showAllPopularCourses && courseList.length > 5 ? (
								<li>
									<a href="#" onClick={() => setShowAllPopularCourses(true)}>
										Show More...
									</a>
								</li>
							) : courseList.length === 5 ? (
								""
							) : (
								<li>
									<a href="#" onClick={() => setShowAllPopularCourses(false)}>
										Show Less
									</a>
								</li>
							)} */}

							{courseList &&
								courseList.length > 5 &&
								(courseList.length === visiblePopularCourses?.length ? (
									<li>
										<a
											href='#'
											onClick={() => {
												setShowAllPopularCourses(false), setNumberOfCourses(1);
											}}>
											Show Less
										</a>
									</li>
								) : (
									<li>
										<a
											href='#'
											onClick={() => {
												setShowAllPopularCourses(true),
													setNumberOfCourses(numberOfCourses + 2);
											}}>
											Show More...
										</a>
									</li>
								))}
						</ul>
						<CourseComingSoon handleSharePopup={handleSharePopup} />
					</div>
				) : !dimViewObj && (
					<div >
						<ShimmerCategoryItem items={3} categoryStyle='STYLE_SIX' />
					</div>
				)}

				{dimViewObj && !skillProg && dashboardData && !showDimCourse ? (
					<>
						{showHolisticViewData ? (
							<>
								<div class="backpageStrip flex pb-2">
									<a href="javascript:void(0)" onClick={() => handleShowDimCourse(false)}>
										<span class="">
											<i class="fa-solid fa-angle-left mr-1"></i>
										</span>Back
									</a>
								</div>
								<div className={`SchHolistcFlowrthree`}>
									<div className="Skills_Connection_List">
										<ul>
											{skills?.map(skill => (
												<li key={skill?.conSkillId}>
													<span>
														{skill?.scProgress}<sub>%</sub>
													</span>
													{skill?.conSkillname}
												</li>
											))}
										</ul>
									</div>
								</div>
							</>
						) : (
							<div className={`pagescrollauto skillsProgress`}>
								<div className="holisticflowr dimension_skilflow rghtpnl_Skill justify-content-center"
									// style={{
									// 	marginTop: `${((progressObj?.skills[7]?.progress) * .75)}px`,
									// 	marginBottom: `${((progressObj?.skills[6]?.progress) * .75)}px`
									// }}
									style={{
										marginTop: `${((dimSkils[2]?.progress) * .75)}px`,
										marginBottom: `${((dimSkils[4]?.progress) * .75)}px`
									}}
								>
									<div className={`skills_Progress_Dimen`}>
										<div className="skilanddimensioncircle">
											<div
												className={`dimensionskills ${selectedDim?.value}_Skils_center`}
												style={{
													height: `calc(${dimPercentage?.progress}% + 30px - ${dimPercentage?.progress * 0.3}px)`,
													width: `calc(${dimPercentage?.progress}% + 30px - ${dimPercentage?.progress * 0.3}px)`,
												}}
											>
												<p>
													{showRoundValue(dimPercentage?.dimPro)}
													<span className="percentage_sign">%</span>
												</p>
											</div>
											{/* {
											Skill_innercirclegrey
											Skill_innercircleblue
									} */}
											<div className="skillCircleList AllSkillschart">
												{
													dimSkils?.map((skill, index) =>
														<div className={`skill_pnlcircle`} key={index}>
															<div
																key={index}
																className={`Skill_innercircle flextwo ${skill?.isEnrolled ? 'Skill_innercircleblue' : 'Skill_innercirclegrey'}`}
																style={{
																	height: `calc(${skill?.progress}% + 18.39px)`,
																	minWidth: `calc(${skill?.progress}% + 18.39px) `,
																	width: `calc(${skill?.progress}% + 18.39px)`,
																}}
															>
																<span className={`skils_list ${showRoundValue(dimPercentage?.progress) > 80 ? 'skill_progLabel' : ''}`}>{++index}</span>
																{/* <span className={`skils_list ${skill?.progress > 80 ? 'skill_progLabel' : ''}`}>{++index}</span> */}
																<p
																	className={`${skill?.progress > 9
																		? "SkillPlanatryprog"
																		: skill?.progress === 100
																			? "SkillPlanatryprog"
																			: skill?.progress === 0
																				? "Zeroprogress"
																				: skill?.progress < 0.5
																					? "SkillPlantry"
																					: ""
																		}`}
																>
																	{showRoundValue(skill?.progress)}
																	<span className="percentage_sign">%</span>
																</p>
															</div>
														</div>
													)
												}
											</div>
										</div>
									</div>
								</div>
								<div className="Skillslegent">
									{
										dimSkils.filter((skill) => skill?.isEnrolled)?.length > 0 && (<h5 className="pl-2 pt-2 pb-2">Skills {getCapitalized(defaultChildData?.firstName)} is working on:</h5>)
									}
									<ul className="flextwo flex-wrap justify-content-start">
										{getSequnceSort(dimSkils)?.map(
											(skill, index) =>
												skill?.isEnrolled && (
													<li
														key={index}
														className={`${skill?.name.length > 20 ? "skillcount_txt" : ""
															}`}
													>
														<strong className="listviewnumberEnable">{++index}</strong>
														<span className="">
															{" "}
															{skill?.name}{" "}
															{/* <span className="skillCountlegend">
																{" "}
																{skill?.showCourseCount > 0 &&
																	" [" + skill?.showCourseCount + "]"}
															</span> */}
														</span>
													</li>
												)
										)}
									</ul>
									<>
										<h6 className="pl-2 pt-2 pb-2">More skills to consider in this dimension:</h6>
										<ul className="flextwo flex-wrap justify-content-start">
											{getSequnceSort(dimSkils)?.map(
												(skill, index) =>
													!skill?.isEnrolled && (
														<li
															key={index}
															className={`${skill?.name?.length > 20 ? "skillcount_txt" : ""
																}`}
														>
															<strong className="listviewnumberDisable">{++index}</strong>
															<span className="">
																{" "}
																{skill?.name}{" "}
															</span>
														</li>
													)
											)}
										</ul>
									</>
								</div>
								{/* <div className='HGmaincircle flextwo'>
									<div
										className='HGInner_cirlce flextwo flex-wrap'
										style={{
											height: `calc(${showRoundValue(
												dashboardData?.progress
											)}% + 30px - ${totlecal}px)`,
											width: `calc(${showRoundValue(
												dashboardData?.progress
											)}% + 30px - ${totlecal}px)`,
											minWidth: `calc(${showRoundValue(
												dashboardData?.progress
											)}% + 30px - ${totlecal}px)`,
										}}
									>
										<p className='w-100 text-center'>
											{showRoundValue(dashboardData?.progress)}
											<span className='percentage_sign'>%</span>
										</p>
									</div>

									{dashboardData &&
										dashboardData?.dimensions &&
										dashboardData.dimensions.map((vl, ky) => {
											return (
												<div
													className={`HolinnerProgcircle HG${vl.name}crl flextwo`}
													key={ky}>
													<div
														className='Dimen_Circle_inner '
														style={{
															height: `calc(${vl?.progress}% + 9px)`,
															width: `calc(${vl?.progress}% + 9px)`,
															minWidth: `calc(${vl?.progress}% + 9px)`,
														}}>
														<p
															className={
																vl.progress > 9
																	? "planetProgress"
																	: vl.progress === 100
																		? "fullprogress"
																		: vl.dimPro === 0
																			? "plazeroprogress"
																			: vl.dimPro < 0.51
																				? "planetprog"
																				: ""
															}>
															{showRoundValue(vl.dimPro)}															
															<span className='percentage_sign'>%</span>
														</p>
													</div>
												</div>
											);
										})}
								</div>
								<h4 className='text-center mt-4 pt-5'>
									{getCapitalized(defaultChildData.firstName)}'s Overall Progress{" "}
									<img
										src={image.chat_icon}
										className='chat_icon pointer'
										alt=''
										onClick={() => handleShowOverallProgressPopup(true)}
									/>
								</h4>
								<div className='Holisticcharttengent'>
									<ul className='flex flex-wrap justify-content-center'>
										<li>
											<span className='GRtengent OverallTeggent'></span>Overall
											Progress
										</li>
										{getSequnceSort(dashboardData.dimensions).map((vl, ky) => (
											<li key={ky}>
												<span className={`GRtengent ${vl?.name}Teggent`}></span>{vl?.name}
											</li>
										))}
									</ul>
								</div> */}
							</div>
						)}
					</>
				) : (
					showDimCourse &&
						!skillProg ? (
						<>
							<div class="backpageStrip flex pb-2">
								<a href="javascript:void(0)" onClick={() => handleShowDimCourse(false)}>
									<span class="">
										<i class="fa-solid fa-angle-left mr-1"></i>
									</span>Back
								</a>
							</div>
							<CourseProgress
								courseObj={dimCourse}
								showProgress={showProgress}
								getDataFromCourseProgress={getDataFromCourseProgress}
							/>
						</>
					) : !visiblePopularCourses && (
						<div >
							<ShimmerCategoryItem items={3} categoryStyle='STYLE_SIX' />
						</div>
					)
				)
				}
				{
					skillProg && dimViewObj && (
						<>
							<div class="backpageStrip flex pb-2">
								<a href="javascript:void(0)" onClick={() => handleShowDimCourse(false)}>
									<span class="">
										<i class="fa-solid fa-angle-left mr-1"></i>
									</span>Back
								</a>
							</div>
							<CourseProgress
								showProgress={showProgress}
								getDataFromCourseProgress={getDataFromCourseProgress}
							/>{" "}
						</>
					)
				}
			</div >
			{showCongratulationModel && !courseDetail?.isAcademic && (
				<CongratulationCard
					courseActivityId={courseActivityId}
					handleOpenpopup={getDataFromCourseProgress}
					isContinueButtonClick={false}
				/>
			)
			}
			{
				congratulationPopup && courseDetail?.isAcademic && (
					<LessonCongratulationsPopup
						handleCongratulationPopup={handleCongratulationPopup}
						courseActivityId={courseActivityId}
					/>
				)
			}
			{
				showOverallProgressPopup && (
					<ShowOverallProgressPopup
						handleShowOverallProgressPopup={handleShowOverallProgressPopup}
					/>
				)
			}

			{
				shareCoursePopup && (
					<ShareCoursePopup handleSharePopup={handleSharePopup} />
				)
			}
		</>
	);
};

export default Sidebar;