import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { getSequnceSort } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import Home from "../Home";
import StudentDashBord from "./StudentDashBord";
import LearnerPlanRightPanel from "../learnerplan/LearnerPlanRightPanel";
import CongratulationCard from "../widget/CongratulationCard";
import LessonCongratulationsPopup from "../dimensions/course/vickycourses/LessonCongratulationsPopup";
import { getSocialActivityDetail } from "../../redux/actions/APIs";
const StudentProfileDashbord = () => {
	const dispatch = useDispatch();
	const { defaultChildData, loggedInUser, getMyPlanData, showPanelObj } = useSelector(
		(state) => state.collections
	);

	const [showPlanCourseData, setShowPlanCourseData] = useState([]);
	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [congratulationPopup, setCongratulationPopup] = useState(false);
	const [courseDetail, setCourseDetail] = useState([]);
	const [courseActivityId, setCourseActivityId] = useState([]);

	const gotoSelectedPlan = (planIndex) => {
		let courses = [];
		getMyPlanData?.records[planIndex]?.dimensions?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				skill?.courses?.map((course, cKey) => {
					course.skill = skill;
					course.dimension = { key: dim?.id, value: dim?.name };
					courses.push(course);
				});
			});
		});
		setShowPlanCourseData(courses);
	};

	useEffect(() => {
		if (getMyPlanData?.records?.length > 0) {
			gotoSelectedPlan(0);
		}
	}, [getMyPlanData]);

	const handleCongratulationPopup = (vl) => {
		setCongratulationPopup(vl);
	};

	const getDataFromCourseProgress = (result, data, course) => {
		setCourseDetail(course);
		setCourseActivityId(data);
		dispatch(getSocialActivityDetail());
		if (course?.isAcademic) {
			setCongratulationPopup(result);
		} else {
			setShowCongratulationModel(result);
		}
	};
	return (
		<div>
			<Home>
				<div className="d-flex flex-wrap SpecialLeftpanel w-100">
					<div className="d-flex w-100 align-items-start overflow-visible">
						<StudentDashBord />

						{/* </div>
              </div> */}
						{
							showPanelObj?.type !== "showGrowthSurveyPanel" && (
								<div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl">
									<LearnerPlanRightPanel
										screen={1}
										selectedOption={"home"}
										coursePlanTree={[]}
										timezoneValue={"US/Eastern"}
										gotoSelectedPlan={gotoSelectedPlan}
										showPlanCourseData={showPlanCourseData}
										getDataFromCourseProgress={getDataFromCourseProgress}
									/>
								</div>
							)
						}
					</div>

				</div>
				{showCongratulationModel && !courseDetail?.isAcademic && (
					<CongratulationCard
						courseActivityId={courseActivityId}
						handleOpenpopup={getDataFromCourseProgress}
						isContinueButtonClick={false}
					/>
				)}

				{congratulationPopup && courseDetail?.isAcademic && (
					<LessonCongratulationsPopup
						handleCongratulationPopup={handleCongratulationPopup}
						courseActivityId={courseActivityId}
					/>
				)}

			</Home>
		</div>
	)

}
export default StudentProfileDashbord;