import React, { useState } from "react";
import { useSelector } from "react-redux";
import ProgressBar from "../controls/ProgressBar";
import AcquisitionResultPopup from "./AcquisitionResultPopup";
import { getAcquistionIcon, getSequnceSort } from "../../utils/helper";
import { ShimmerCategoryList } from "react-shimmer-effects";

const IntelligenceAcquisitionResult = ({
	quizResponse,
	loadRespone,
	showResult,
}) => {
	const [showPopup, setShowPopup] = useState(false);
	const [skillName, setSkillName] = useState("");
	const [skillImage, setSkillImage] = useState("");
	const [skillResult, setSkillResult] = useState("");

	const { userAttemptedResponse } = useSelector((state) => state.collections);

	let data =
		userAttemptedResponse && userAttemptedResponse?.records
			? userAttemptedResponse?.records
			: quizResponse;

	const handleShow = (name, img, result) => {
		setSkillName(name);
		setSkillImage(img);
		setSkillResult(result);
		setShowPopup(true);
	};

	document.body.addEventListener("click", function (event) {
		if (
			(event.target && event.target.classList.contains("fa-xmark")) ||
			event.target.getAttribute("fill") === "currentColor" ||
			event.target.getAttribute("data-dismiss") === "modal"
		) {
			setShowPopup(false);
		}
	});

	return (
		<>
			{showResult && (
				<div className="quizblok">
        
					<div className="smartquizwrapper">
						{loadRespone ? (
							<ShimmerCategoryList items={5} categoryStyle="STYLE_SIX" />
						) : (
							<>
								{data && (
									<h3 className="mb-3 quizrestitle">Your Different "Skills"</h3>
								)}

								<div className="smqzQueslist inequeresultwrap">
									{data &&
										getSequnceSort(data)?.map((userAttempt, ky) => (
											<div
												key={ky}
												className="flex intequResult pointer"
												onClick={() =>
													handleShow(userAttempt?.skillName, userAttempt, userAttempt?.result)
												}
											>
												<div className="intellegencename">
													{userAttempt?.skillName}
													{getAcquistionIcon(userAttempt)}
												</div>
												<ProgressBar value={userAttempt?.result} data={data} />
												<div className="procompt">{userAttempt?.result}%</div>
											</div>
										))}
								</div>
							</>
						)}
					</div>
				</div>
			)}
			{showPopup && (
				<AcquisitionResultPopup skillImage={skillImage} skillName={skillName} skillResult={skillResult} />
			)}
		</>
	);
};

export default IntelligenceAcquisitionResult;
