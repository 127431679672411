import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { addDayNameInDate, formatDate, getCalenderViewData, getDatesBetween, getInProgressCourse, getMonthAndDayByDate, getProfileName, getSequnceSort, getUsStatnderdTime, handleClenderVaiew, nameSorting, showRoundValue } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import RoundProgress from "../controls/RoundProgress";
import { textTrim } from "../../utils/helper";
import { ShimmerSimpleGallery, ShimmerTable, ShimmerTitle } from "react-shimmer-effects";
import ReactTooltip from "react-tooltip";
import Home from "../Home";
import { Circle } from "rc-progress";
import { showModal } from "../../redux/actions";
import { PATHS } from "../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getSkillProgress } from "../../redux/actions/APIs";
import BurgerTab from "./BurgerTab";

const TeacherDashboard = ({ handleReports }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { defaultChildData, loggedInUser, getMyPlanData, modalData } = useSelector((state) => state.collections);
    const { dashboardData, showoverModal } = useSelector((state) => state.home);
    const [calenderData, setCalenderData] = useState([]);
    const [getUserTimeZone, setUserTimeZone] = useState("US/Eastern");


    useEffect(() => {
        if (loggedInUser?.timeZone) {
            setUserTimeZone(loggedInUser?.timeZone)
        }
    }, [loggedInUser])

    console.log("loggedInUser?.timeZone", loggedInUser?.timeZone, getUserTimeZone);


    useEffect(() => {
        if (getMyPlanData?.records?.length > 0) {
            const modalObj = handleClenderVaiew(getMyPlanData?.records[0]);
            dispatch(showModal(modalObj));
        }

    }, [getMyPlanData])

    useEffect(() => {
        dispatch(getSkillProgress());
    }, [])

    useEffect(() => {
        if (modalData?.data?.length > 0 && !modalData?.type) {
            setCalenderData(getCalenderViewData(modalData));
        }
    }, [modalData])

    const [getCurrentCourse, setCurrentCourse] = useState();
    useEffect(() => {
        if (calenderData?.length > 0) {
            setCurrentCourse(getInProgressCourse(calenderData));
        }
    }, [calenderData])

    const showCalenderScreen = () => {
        const modalObj = handleClenderVaiew(getMyPlanData?.records[0], "CalenderVaiew");
        dispatch(showModal(modalObj));
    }

    /*
    const handleClenderVaiew = (myPlan) => {
        let data = handleSelectedPlanCourses(myPlan?.dimensions, "getCourseSeq");
        let dayOfWeek = myPlan?.dayOfWeek ? myPlan?.dayOfWeek?.split(",") : [];
        let holidaysArr = [];
        myPlan?.holidays?.map((myDate, key) => {
            let obj = { date: addDayNameInDate(myDate?.date), name: myDate?.name }
            holidaysArr?.push(obj);
        })
        dispatch(showModal({ type: "", data: data, getUserTimeZone: "US/Eastern", dayOfWeek: dayOfWeek, holidays: holidaysArr }));
    }
    */

    /*
    const handleSelectedPlanCourses = (myPlanData, type) => {
        let myPlanCourses = [...myPlanData];
        let coursesArr = [];
        let myPlanTree = [];
        myPlanCourses?.map((dim, index) => {
            let dimObj = { ...dim, skills: [] }
            dim?.skills?.map((skill, sKey) => {
                let skilObj = { ...skill, courses: [] }
                dimObj?.skills?.push(skilObj);
                skill?.courses?.map((course, cKey) => {
                    dimObj?.skills[sKey]?.courses?.push(course);
                    course.isSelected = true;
                    coursesArr?.push({ ...course });
                })
            })
            myPlanTree?.push(dimObj);
        })
        return getSequnceSort(coursesArr);
    }
    */

    /*
    const weekday = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    useEffect(() => {
        let dateArr = [];
        let weekArr = [];
        if (modalData?.data) {
            getSequnceSort(modalData?.data)?.map((course, key) => {
                getSequnceSort(course?.activities)?.map((module, index) => {
                    let modSequence = index + 1;
                    let getAllDates = getDatesBetween(module, modSequence, course, modalData?.getUserTimeZone, modalData?.dayOfWeek)
                    dateArr?.push(...getAllDates);
                })


            })
            if (dateArr?.length > 0) {
                let weekCount = 0;
                let weekWiseData = [];
                const weekKey = weekday?.findIndex((item) => item === dateArr[0]?.day)
                if (weekKey !== -1 && weekKey !== 0) {
                    let startdate = new Date(getUsStatnderdTime(modalData?.data[0]?.startDate, modalData?.getUserTimeZone));
                    startdate?.setDate(startdate.getDate() - weekKey);
                    for (let i = 0; i < weekKey; i++) {
                        let wkObj = { date: startdate.toLocaleString("en-us", { month: "short", day: "numeric", }) }
                        weekWiseData?.push(wkObj);
                        startdate?.setDate(startdate.getDate() + 1);
                    }
                }
                let showModule = 1;
                let count = 1;
                let corIndex = 0;
                dateArr?.map((date, index) => {
                    const holidayKey = modalData?.holidays?.findIndex((myDate) => myDate?.date === date?.currentDate);
                    if (holidayKey !== -1) {
                        date.sittings = "";
                        date.courseName = "";
                        date.duration = "";
                        date.holidayname = modalData?.holidays[holidayKey]?.name;
                        date.moduleName = "";
                    }
                    if (date?.sittings) {
                        date.courseName = modalData?.data[corIndex]?.name;
                        date.sittings = modalData?.data[corIndex]?.sittings;
                        date.duration = modalData?.data[corIndex]?.duration;
                        date.moduleName = modalData?.data[corIndex]?.activities[count - 1]?.name;
                        date.courseKey = corIndex + 1;
                        if (date?.duration == 15) {
                            date.moduleKey = count;
                            if ((showModule === 4)) {
                                count++;
                                showModule = 0;
                            }
                        } else if ((date?.duration == 30 || date?.duration === 45)) {
                            date.moduleKey = count;
                            if ((showModule === 2)) {
                                count++;
                                showModule = 0;
                            }
                        } else if (date?.duration == 60) {
                            console.log("date?.sittings 222 : ", date);
                            date.moduleKey = count;
                            count++;
                            showModule = 0;

                        }
                        if (count === 5) {
                            count = 1;
                            corIndex++;

                        }
                        showModule++;
                    }
                    weekWiseData?.push(date);
                    if (weekWiseData?.length % 7 === 0) {
                        weekArr?.push(weekWiseData);
                        weekWiseData = [];
                        weekCount++;
                    }
                    if (index + 1 === dateArr?.length && weekWiseData?.length % 7 !== 0) {
                        let lasttdate = new Date(dateArr[dateArr?.length - 1]?.currentDate);
                        lasttdate?.setDate(lasttdate.getDate() + 1);
                        for (let i = weekWiseData?.length; i < 7; i++) {
                            let wkObj = { date: lasttdate.toLocaleString("en-us", { month: "short", day: "numeric", }) }
                            weekWiseData?.push(wkObj);
                            lasttdate?.setDate(lasttdate.getDate() + 1);
                        }
                        weekArr[weekCount] = weekWiseData;
                    }
                })
                setCalenderData(weekArr);
            }
        }
    }, [modalData])
    */

    /*    
    useEffect(() => {
        let isCurrent = true;
        if (calenderData?.length > 0) {
            calenderData?.map((weeks, index) => {
                weeks?.map((days, key) => {
                    //  console.log(new Date(days?.currentDate).toDateString(), "FFFFFFFFF : ", new Date().toDateString(), "TTTTTTTTTTTTT :", days?.courseName);
                    if (formatDate(new Date(days?.currentDate)) >= formatDate(new Date()) && days?.sittings && isCurrent) {
                        setCurrentCourse(days);
                        isCurrent = false;
                    }
                    // if (days?.inProress && isCurrent && days?.sittings) {
                    //     setCurrentCourse(days);
                    //     isCurrent = false;
                    // }
                })
            })
        }
    }, [calenderData])
    */

    const dimensionArr = [
        { dimName: "Social", color: "#e8b62f", dimImage: image.courselevel4 },
        { dimName: "Emotional", color: "#dd6363", dimImage: image.CollegeJourneyicon },
        { dimName: "Intellectual", color: "#83b612", dimImage: image.courselevel3 },
    ]

    return (
        <div className="LeftbarPannel p-0">
            <div className='CourseCardWrapper fullHeight100 '>
                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                        <span className="flexone"> <img src={image.viewReport} className='mr-2' alt="" />
                            Dashboard
                        </span>

                        <BurgerTab handleReports={handleReports} />
                    </h2>
                </div>
                <div className="pb-3 pt-3 TeacherDashboard" id="TeacherDashboard">
                    <div className="reminderCourse pb-3  ps-3 pe-3">

                        {getCurrentCourse ? (
                            <div className="reminderCourseContent flex">
                                <div>
                                    <div className="d-flex align-items-center pb-3">
                                        <img src={image.schedule} alt="" className="mr-2" />
                                        <h4 className="">Your Next Session</h4>
                                    </div>
                                    <div className="moduleandcrs">
                                        <div className="d-flex justify-content-between">

                                            <h4>
                                                <span className="pe-2 d-flex">
                                                    <div className="courseCircle me-2">C{getCurrentCourse?.courseKey}</div>
                                                    {getCurrentCourse?.courseName}
                                                </span>
                                            </h4>
                                            {/* <h4>
                                        <span className="pe-2">
                                            <i class="fa fa-calendar-o pe-2" aria-hidden="true"></i>
                                            Next Session:
                                        </span>
                                        {getMonthAndDayByDate(dashboardData?.planNextDate)}
                                        {getCurrentCourse?.date}
                                        
                                    </h4> */}
                                        </div>
                                        <div className="pt-2">
                                            <h4>
                                                <span className="pe-2 d-flex">
                                                    <div className="courseCircle modulecircleColor me-2">M{getCurrentCourse?.moduleKey}</div>
                                                    {getCurrentCourse?.moduleName}
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="calenderviewNextsesinon pointer" onClick={() => showCalenderScreen()}>
                                    <span>{new Date(getCurrentCourse?.currentDate)?.toLocaleString('en-us', { month: 'long' })}</span>
                                    {new Date(getCurrentCourse?.currentDate)?.toLocaleString('en-us', { day: 'numeric' })}
                                </div>
                            </div>
                        ) : (
                            <ShimmerTitle line={2} gap={10} variant="primary" />
                        )}
                    </div>

                    <span className="d-flex align-items-center pt-0 pb-3 ps-3 ms-1">
                        <img src={image.DiemensionProgress} alt="" className="mr-2" />
                        <h4 className="">Dimension Progress</h4>
                    </span>

                    {dashboardData?.dimensions?.length > 0 ? (
                        <div className="d-flex justify-content-between  ps-4 pe-4">
                            <p className='Coursecompprcent position-relative'>
                                <span>
                                    {" "}
                                    <span className='PercentCompleted'>
                                        {showRoundValue(dashboardData?.progress)}
                                        <span className='percenrSign'>%</span>
                                    </span>
                                    <Circle percent={showRoundValue(dashboardData?.progress)} strokeWidth={12} strokeColor='#4169e1' />
                                </span>
                                <span className="d-flex justify-content-center align-items-center pt-3">
                                    <img src={image.overall_progress_icon} className='mr-2' alt="" />
                                    <span className="circleNames">Aggregate</span>
                                </span>
                            </p>

                            {getSequnceSort(dashboardData?.dimensions)?.map((dim, key) => (
                                <p className='Coursecompprcent position-relative pointer' key={key} onClick={() => handleReports("connectedSkillReport", dim)}>
                                    <span>
                                        {" "}
                                        <span className='PercentCompleted'>
                                            {dim?.progress}
                                            <span className='percenrSign'>%</span>
                                        </span>
                                        <Circle percent={dim?.progress} strokeWidth={12} strokeColor={dimensionArr[key]?.color} />
                                    </span>
                                    <span className="d-flex justify-content-center align-items-center pt-3">
                                        <img src={dimensionArr[key]?.dimImage} className='mr-2' alt="" />
                                        <span className="circleNames">{dim?.name}</span>
                                    </span>
                                </p>

                            ))}
                        </div>
                    ) : (
                        <ShimmerSimpleGallery imageType="circular" imageHeight={200} caption />
                    )}

                    <div id="planCalendarView" className="mt-0 pb-4 pt-3">
                        <span className="d-flex align-items-center pb-2">
                            <img src={image.calenderIcon1} alt="" className="mr-2" />
                            <h4 className="">Plan Schedule</h4>
                        </span>
                        {calenderData?.length > 0 ? (
                            <div className="tableResponsive">
                                <table className="border-0 w-100 m-0">
                                    <thead>
                                        <tr>
                                            <th>Week</th>
                                            <th>Mon</th>
                                            <th>Tue</th>
                                            <th>Wed</th>
                                            <th>Thu</th>
                                            <th>Fri</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {calenderData?.map((weeks, index) => {
                                            const currentWeek = weeks?.find((item) => new Date(item?.currentDate) >= new Date())
                                            return (
                                                <>
                                                    {
                                                        currentWeek && (
                                                            <tr key={index}>
                                                                <td class="bg-color1">
                                                                    <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>{weeks[0]?.date} - {weeks[6]?.date}</p>
                                                                    <p className="week">Week {index + 1}</p>
                                                                </td>

                                                                {weeks?.map((days, key) => (
                                                                    (["PARENT", "LEARNER"]?.includes(loggedInUser?.role?.name) || (["TEACHER", "STUDENT"]?.includes(loggedInUser?.role?.name) && key < 5)) && (
                                                                        <td class={days?.holidayname ? "holidays" : (new Date(days?.currentDate).toDateString() === new Date().toDateString() ? "current" : "bg-color1")}>

                                                                            <div className="d-flex align-items-baseline justify-content-between flex-wrap">
                                                                                <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                                    {days?.date}</p>
                                                                                {days?.isModuleProgress && days?.sittings && (
                                                                                    <img src={image.InProgressIcon} className="statusImg" />
                                                                                )}
                                                                                {days?.isModuleCompleted && days?.sittings && (
                                                                                    <img src={image.completedIcon} className="statusImg" />
                                                                                )}
                                                                            </div>
                                                                            {days?.holidayname ? (
                                                                                <div className="d-flex pt-2 align-items-center">
                                                                                    <React.Fragment>
                                                                                        {days?.holidayname?.length > 20 ? (
                                                                                            <ReactTooltip id={days?.holidayname}>
                                                                                                <p>{days?.holidayname}</p>
                                                                                            </ReactTooltip>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                        <div className="courseName" data-for={days?.holidayname} data-event-off="" data-tip>
                                                                                            {textTrim(days?.holidayname, 20)}
                                                                                        </div>
                                                                                    </React.Fragment>
                                                                                </div>
                                                                            ) : (
                                                                                days?.courseName && (
                                                                                    <>
                                                                                        <div className="d-flex pt-2 align-items-center">
                                                                                            <span className="courseCircle me-1">C{days.courseKey}</span>
                                                                                            <React.Fragment>
                                                                                                {days?.courseName?.length > 17 ? (
                                                                                                    <ReactTooltip id={days?.courseName}>
                                                                                                        <p>{days?.courseName}</p>
                                                                                                    </ReactTooltip>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                                <div className="courseName" data-for={days?.courseName} data-event-off="" data-tip>
                                                                                                    {textTrim(days?.courseName, 17)}
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        </div>
                                                                                        <div className="d-flex pt-2 align-items-center">
                                                                                            <span className="moduleCircle me-1">M{days?.moduleKey}</span>
                                                                                            <React.Fragment>
                                                                                                {days?.moduleName?.length > 17 ? (
                                                                                                    <ReactTooltip id={days?.moduleName + "" + key}>
                                                                                                        <p>{days?.moduleName}</p>
                                                                                                    </ReactTooltip>
                                                                                                ) : (
                                                                                                    ""
                                                                                                )}
                                                                                                <div className="moduleName" data-for={days?.moduleName + "" + key} data-event-off="" data-tip>
                                                                                                    {textTrim(days?.moduleName, 17)}
                                                                                                </div>
                                                                                            </React.Fragment>
                                                                                        </div>
                                                                                    </>
                                                                                )
                                                                            )}
                                                                        </td>
                                                                    )
                                                                )
                                                                )}
                                                            </tr>
                                                        )
                                                    }
                                                </>
                                            )
                                        })}
                                    </tbody>
                                    {/* <tbody>
                                    <tr>
                                        <td class="bg-color1">
                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>Aug-1 Aug-8</p>
                                            <p className="week">Week 1</p>
                                        </td>

                                        <td class="holidays">
                                            <div className="d-flex align-items-baseline justify-content-between">
                                                <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                    Aug-1</p>
                                                <p className="upcoming">In progress</p>
                                            </div>
                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="courseCircle me-1">C1</span>
                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                            </div>

                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="moduleCircle me-1">M1</span>
                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                            </div>
                                        </td>

                                        <td class="current">
                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                Aug-1</p>
                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="courseCircle me-1">C1</span>
                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                            </div>

                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="moduleCircle me-1">M1</span>
                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                            </div>
                                        </td>

                                        <td class="bg-color1">
                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                Aug-1</p>
                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="courseCircle me-1">C1</span>
                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                            </div>

                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="moduleCircle me-1">M1</span>
                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                            </div>
                                        </td>

                                        <td class="bg-color1">
                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                Aug-1</p>
                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="courseCircle me-1">C1</span>
                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                            </div>

                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="moduleCircle me-1">M1</span>
                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                            </div>
                                        </td>

                                        <td class="bg-color1">
                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                Aug-1</p>
                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="courseCircle me-1">C1</span>
                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                            </div>

                                            <div className="d-flex pt-2 align-items-center">
                                                <span className="moduleCircle me-1">M1</span>
                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody> */}
                                </table>
                            </div>
                        ) : (
                            <ShimmerTable row={4} col={4} />
                        )}
                    </div>
                </div>
            </div>
        </div >
    );
}
export default TeacherDashboard;