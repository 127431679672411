import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { setClassSchedule } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getClassScheduleData } from "../../redux/actions/APIs";
import { addDayNameInDate, formatDateTimeWithTimezone, getCapitalized, getSequnceSort, getUsStatnderdTime } from "../../utils/helper";

const ClassScheduleDetails = () => {
	const dispatch = useDispatch();

	const { defaultChildData, classSchedule, classScheduleData, loggedInUser, getMyPlanData } = useSelector(
		(state) => state.collections
	);

	const _closeModal = () => {
		console.log("step");
		dispatch(setClassSchedule());
	};


	const [planCourses, setPlanCourses] = useState([]);
	const [getUserTimeZone, setUserTimeZone] = useState(loggedInUser?.timeZone ? loggedInUser?.timeZone : "US/Eastern");
	useEffect(() => {
		if (classScheduleData
			//&& loggedInUser?.role?.name !== "TEACHER"
		) {
			let array = [];
			classScheduleData?.dimensions?.map((dim, index) => {
				dim?.skills?.map((skill, key) => {
					skill?.courses?.map((course, cKey) => {
						array.push(course);
					})
				})
			})
			setPlanCourses(array);
		}
	}, [classScheduleData]);


	return (
		<>
			<div className="halfPagePOpup coursedetailpopuppage">
				<div
					className="modal d-flex classScheduleModel"
					id="schoolactivity170"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-lg" id="ClassSchedule">
						<div className="modal-content w-100 max-width-100">
							<div className="modal-header">
								<div className="heading border-0 p-0">
									<h2 className="flex">
										<span>
											<img src={image.information} alt="..." className="mr-2" />
											{/* {classScheduleData?.planName} */}
											{loggedInUser?.role?.name === "TEACHER" ? "Class: " + getCapitalized(getMyPlanData?.records[0]?.className) : "Plan: " + classScheduleData?.planName}
										</span>
										<div className="d-flex">
											<div className="pe-3">
												<a href="#">
													<img
														src={image.printIcon}
														alt=""
														style={{ maxWidth: "25px" }}
														className="me-2"
													/>
												</a>
												<a href="#">
													<img
														src={image.exportIcon}
														alt=""
														style={{ maxWidth: "23px" }}
													/>
												</a>
											</div>
											<button
												data-dismiss="modal"
												className="btn btn-primary"
												onClick={() => _closeModal()}
											>
												<i className="fa-regular fa-xmark m-0"></i>
											</button>
										</div>
									</h2>
								</div>
							</div>

							<div className="modal-body p-0">

								<div className="container mt-3">
									{
										loggedInUser?.role?.name === "TEACHER" && (
											<>
												<div className="container p-3 mt-2">
													<div className="flex">
														<div className="teachernameDiv">
															<h5 className="">
																Teacher Name:{" "}
																<span>
																	{getMyPlanData &&
																		getCapitalized(getMyPlanData?.records[0]?.teacherName)}
																</span>
															</h5>
														</div>

														<div className="classtimingDiv">
															<h5 className="">
																Class Timing: <span>{formatDateTimeWithTimezone(getMyPlanData?.records[0]?.classTimings)}</span>
															</h5>
														</div>
													</div>
												</div>
												<hr />
											</>
										)
									}
									<div className="table-responsive">
										<table className="table table-bordered">
											<thead>
												<tr>
													<th>
														Course{" "}
														<span>
															<i
																className="fa fa-angle-right fa-sm pe-2 ps-2"
																aria-hidden="true"
															></i>
														</span>{" "}
														Modules
													</th>
													<th className="text-center" >Start Date</th>
													<th className="text-center" >End Date</th>
												</tr>
											</thead>

											<tbody>
												{
													getSequnceSort(planCourses)?.map((val, index) => (
														<>
															<tr className="subheading" key={index}>
																<td className="coursename" colspan="3">
																	{index + 1 + ". "}{val.name}
																</td>
															</tr>
															{val.activities &&
																getSequnceSort(val.activities)?.map((val1, ind1) => (
																	<tr key={ind1}>
																		<td>
																			<span>
																				<i
																					className="fa fa-angle-right fa-sm pe-2 ps-3"
																					aria-hidden="true"
																				></i>
																			</span>
																			{val1.name}
																		</td>
																		<td>{addDayNameInDate(getUsStatnderdTime(val1.startDate, getUserTimeZone))}</td>
																		<td>{addDayNameInDate(getUsStatnderdTime(val1.endDate, getUserTimeZone))}</td>
																	</tr>
																))}
														</>
													))
												}
											</tbody>

											<tbody>
												{classSchedule &&
													getSequnceSort(classSchedule?.records[0]?.courses)?.map((val, ind) => (
														<>
															<tr className="subheading" key={ind}>
																<td className="coursename" colspan="3">
																	{val.name}
																</td>
															</tr>
															{val.activities &&
																getSequnceSort(val.activities)?.map((val1, ind1) => (
																	<tr key={ind1}>
																		<td>
																			<span>
																				<i
																					className="fa fa-angle-right fa-sm pe-2 ps-3"
																					aria-hidden="true"
																				></i>
																			</span>
																			{val1.name}
																		</td>
																		<td>{val1.startDate}</td>
																		<td>{val1.endDate}</td>
																	</tr>
																))}
														</>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="input-group full-Width-group basic_details_form flex m-0">
									<div className="form-group BDsubmitbutton d-flex m-0">
										<button
											type="submit"
											className="btn-blue btn-login d-block mb-5 m-0 ml-auto cancelbutton"
											onClick={() => _closeModal()}
										>
											<i className="fa-solid fa-xmark mr-2"></i>
											Close
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default ClassScheduleDetails;
