import React, { useEffect, useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactPlayer from "react-player";

const Characters = ({ charimage, setSelectedTab, startFrom, selectTabs }) => {
	const playerRef = useRef(null);
	console.log('charimage : ', startFrom);
	useEffect(() => {
		if (startFrom) {
			playerRef.current.seekTo(startFrom, "seconds");
		}
	}, [startFrom]);
	return (
		<div className="lessonDtl">
			<div className="StoryScene">
				{charimage.endsWith(".mp4") ? (
					<ReactPlayer
						ref={playerRef}
						width={"100%"}
						height="100%"
						controls={true}
						playing={true}
						url={charimage}
						config={{
							file: {
								attributes: {
									controlsList: "nodownload",
								},
							},
						}}
					/>
				) : (
					<LazyLoadImage
						key={charimage}
						effect="blur"
						placeholderSrc={charimage}
						src={charimage}
						alt=""
					/>
				)}
			</div>
			<div className='input-group full-Width-group basic_details_form pagebuttonStrip'>
				<div className='form-group BDsubmitbutton d-flex m-0'>
					<div className='buttonDistribotion'>
						<button
							className="btn-blue btn-login d-block float-right w-auto"
							// onClick={() => setSelectedTab("Series")}
							onClick={() => selectTabs("Series")}
						>
							<i className="fa-solid fa-paper-plane mr-2"></i>
							Go to Lessons
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Characters;