import React from "react";
import { useDispatch } from "react-redux";
import { setShowImpGrowthPopup} from "../../../../redux/actions";
import * as image from "../../../../resources/images"
import { PATHS } from "../../../../utils";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";

const ImportantGrowthSurveyPopup = () => {
 
  const {
    selectedDim,
    defaultChildData,
    showImpGrowthPopup
  } = useSelector((state) => state.collections);
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

    const redirectTab = (type) => {
      dispatch(setShowImpGrowthPopup())
      let childId;
      if (defaultChildData?.id) {
        childId = defaultChildData?.id;
      } else {
        childId = params?.id;
      }
      history.push({
        pathname:
          PATHS.SOCIALCOURSE_STR +
          childId +
          "/" +
          showImpGrowthPopup.courseId +
          "/" +
          showImpGrowthPopup.skillDataId +
          "/" +
          showImpGrowthPopup.activityId,
        state: {
          tab: type,
          // dimension: activity?.course?.dimension,
          // isEnrolled: activity?.course?.isEnrolled,
          // course: activity?.course,
        },
      });
    };
    

  return (
    <div className=" areyousurenewpopup welcome_new_poup pickwhwrereuleft" id="GrowthSurvey">
    <div className="modal d-flex" id="schoolactivity179" role="dialog">
      <div className="modal-dialog">
            <div className="modal-content courseInformation schoolcourse">
              <div className="modal-body p-3 mx-3">

                <div className="">
                  <div className="closepopup_icon pointer" onClick={() => dispatch(setShowImpGrowthPopup())}>
                    <i class="fa-sharp fa-light fa-circle-xmark"></i>
                  </div>
                  <div className="pt-3">
                    <h3 className="text-center"> <img src={image.exclamationTrangle} className="mr-2 warningIcon" /> Important</h3>
                    <p className="mb-3 pb-2 pt-2 mt-3 text-left">
                      You will not be able to take the pre-course growth survey past module one of the course.
                    </p>

                    <p className="mb-3 pb-2 pt-2 mt-3 text-left">
                        If you skip this survey you will not be able to earn all the points for this course and
                        it will not show as complete. This might keep you from earning some rewards.
                    </p>

                    <p className="mb-3 pb-2 pt-2 mt-3 text-left">                    
                        Please take the pre-course Growth Survey now.
                    </p>

                    <div className="buttonDistribotion justify-content-between align-items-center mt-5">
                      <p className="pointer skipText" onClick={() => redirectTab("Series")}>
                        Skip the survey and continue with lessons 
                      </p>
                      <button
                        type="button"
                        className="btn-blue btn-login d-block mb-5 w-auto rocket_icon"
                        onClick={() =>
                          redirectTab("growth")
                        }
                      >
                        <i class="fa-solid fa-paper-plane mr-2 warningButtonIcon"></i>Take The Growth Survey
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

    </div>
  </div>
       
  )
}

export default ImportantGrowthSurveyPopup
