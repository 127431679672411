import React from 'react'
import { getAcquistionIcon } from "../../utils/helper";

const AcquisitionResultPopup = ({ skillImage, skillName, skillResult }) => {

	return (
		<div className="newinfo_popup newinfo_popupdimension newEnrollCoursPopup pacechangepopuo">
			<div
				class="modal d-flex downgradepopup sectionscorecard"
				id="schoolactivity184"
				role="dialog"
			>
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header">
							<div class="heading p-0 border-0 w-100">
								<h2 class="flex">
									<span class="flex">
										{/* <img
                    class="mr-2"
                    src={skillImage}
                    alt="Musical"
                  /> */}
										<span className='mr-2'>{getAcquistionIcon(skillImage)}</span>
										{skillName}{" "}
									</span>{" "}
									<button class="btn btn-primary" data-dismiss="modal">
										<i className="fa-regular fa-xmark m-0"></i>
									</button>
								</h2>
							</div>
						</div>
						<div class="modal-body mt-3">
							<div class="intelligenceTyoedetail flex">
								<div class="intelligenceimg">
									{getAcquistionIcon(skillImage)}
									{/* <img src={skillImage} alt="Musical" /> */}
								</div>
								<div class=" pl-3">
									<h4 class="mb-3">{skillName}</h4>
									{skillResult < 71 ?
										<>
											<p>You are at a beginning to intermediate level. You are most likely brand new to this skill or have very limited experience with it.
												It is completely understandable to score in this zone because you may not have needed this skill at this point in your life. The best news is – you are about to advance your knowledge and real-world practice so you will be confident in your ability when you need to use this skill! We offer specific courses to assist with this skill acquisition so you will be better prepared for when you need it!</p>
										</>
										:
										<>
											<p>You are at an advanced intermediate level. You know what this skill is and have experience using it. You would benefit most from practicing with this skill and introducing advanced concepts
												It is completely understandable to score in this zone because you may not have needed to use this skill on a regular basis at this point in your life. The best news is – In addition to our introductory courses we offer additional courses for this skill acquisition and advanced courses on the course journey, as well!
											</p>
										</>
									}
								</div>
							</div>
							<div class="scoreDescription">
								<div class="scoredivision">
									<h4>You might find benefit in the following:</h4>
									{
										skillName === "Self-Awareness" && skillResult < 71 && (
											<ul>
												<li>Chart Strengths and Challenges</li>
												<li>Showcase positive character traits</li>
												<li>Create a regular time to “check in” with yourself</li>
												<li>Work on emotion recognition</li>
											</ul>
										)
									}
									{
										skillName === "Self-Awareness" && skillResult > 70 && (
											<ul>
												<li>Develop and map goals</li>
												<li>Practice the Growth Mindset</li>
											</ul>
										)
									}
									{
										skillName === "Self-Management" && skillResult < 71 && (
											<ul>
												<li>Create a Coping Skills Toolbox</li>
												<li>Practice Mindfulness techniques</li>
												<li>Set Routines</li>
												<li>Set SMART goals</li>
											</ul>
										)
									}
									{
										skillName === "Self-Management" && skillResult > 70 && (
											<ul>
												<li>Practice goal mapping</li>
												<li>Practice journaling</li>
											</ul>
										)
									}
									{
										skillName === "Responsible Decision Making" && skillResult < 71 && (
											<ul>
												<li>Begin brainstorming</li>
												<li>Practice solution-mapping</li>
												<li>Connect emotions and actions</li>
												<li>Imagine hypothetical outcomes</li>
											</ul>
										)
									}
									{
										skillName === "Responsible Decision Making" && skillResult > 70 && (
											<ul>
												<li>Practice comparing and contrasting</li>
												<li>Engage in reflection</li>
											</ul>
										)
									}
									{
										skillName === "Relationship Management" && skillResult < 71 && (
											<ul>
												<li>Practice empathy and sitting with someone in their hurt</li>
												<li>Take responsibility-learn, “I’m sorry. This was my fault. Please forgive me.”</li>
												<li>Focus on keeping promises</li>
												<li>Be intentionally on time</li>
											</ul>
										)
									}
									{
										skillName === "Relationship Management" && skillResult > 70 && (
											<ul>
												<li>Practice compassion- volunteer at a pet shelter!</li>
												<li>Set and maintain boundaries</li>
											</ul>
										)
									}
									{
										skillName === "Social Awareness" && skillResult < 71 && (
											<ul>
												<li>Take time to be thankful for all you have</li>
												<li>Intentionally notice those who have less</li>
												<li>Really consider others’ perspectives</li>
												<li>Consider how you might serve someone else</li>
											</ul>
										)
									}
									{
										skillName === "Social Awareness" && skillResult > 70 && (
											<ul>
												<li>Practice the phrase, “I might be wrong…”</li>
												<li>Spend time with a family from another culture</li>
											</ul>
										)
									}
									{
										skillName === "Critical Thinking" && skillResult < 71 && (
											<ul>
												<li>Read or watch two versions of the same news story</li>
												<li>Research something you find interesting and see what you learn</li>
												<li>Practice asking, “Why…”</li>
												<li>Practice asking, “Does this make sense?”</li>
											</ul>
										)
									}
									{
										skillName === "Critical Thinking" && skillResult > 70 && (
											<ul>
												<li>Find an issue that matters to you. How can this be corrected? Jump in.</li>
												<li>Join a debate club or school improvement team.</li>
											</ul>
										)
									}
									{
										skillName === "Problem Solving" && skillResult < 71 && (
											<ul>
												<li>Research something you find interesting and see what you learn</li>
												<li>Play a strategy based game</li>
												<li>Build something with blocks</li>
												<li>Find an issue that matters to you. How can this be corrected? Jump in</li>
											</ul>
										)
									}
									{
										skillName === "Problem Solving" && skillResult > 70 && (
											<ul>
												<li>Design a robot.</li>
												<li>Join a debate club or school improvement team</li>
											</ul>
										)
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AcquisitionResultPopup
