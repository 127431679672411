import React, { useEffect, useState } from "react";
import Home from "../Home";
import * as image from "../../resources/images";
import RoundProgress from "../controls/RoundProgress";
import { Rating } from "react-simple-star-rating";
import { useDispatch, useSelector } from "react-redux";
import { courseEnrollmentWithSession, getAllUsersDetail, getMyPlan, getRewardData, getSettingData, getSocialActivityDetail } from "../../redux/actions/APIs";
import { ShimmerCategoryList, ShimmerPostItem } from "react-shimmer-effects";
import CourseCard from "../dimensions/course/intellectual/CourseCard";
import RewardPopUp from "../base/RewardPopUp";
import Certificate from "../base/Certificate";
import { activePlanIndexNo, breadcrumb, enrollModal, resetResponse, showModal, showScreen } from "../../redux/actions";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { convertedNumber, getCalculatePlanDate, getCalenderViewData, getCapitalized, getSequnceSort, redirectLeftOfScreen, textTrim } from "../../utils/helper";
import { PATHS } from "../../utils";
import LearnerPlanRightPanel from "./LearnerPlanRightPanel";
import CongratulationCard from "../widget/CongratulationCard";
import LessonCongratulationsPopup from "../dimensions/course/vickycourses/LessonCongratulationsPopup";
import ReactTooltip from "react-tooltip";


const Myplan = () => {

	const params = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { defaultChildData, getMyPlanData, loggedInUser, settingData, response, courseEnrollResponse, screenData, enrollObj
	} = useSelector((state) => state.collections);

	const [openReward, setOpenReward] = useState();
	const [certificate, setCertificate] = useState();
	const [certificateData, setCertificateData] = useState();
	const [certificateskilldata, setcertificateskilldata] = useState();
	const [showPlanData, setShowPlanData] = useState([]);
	const [showPlanCourseData, setShowPlanCourseData] = useState([]);
	const [showCongratulationModel, setShowCongratulationModel] = useState(false);
	const [congratulationPopup, setCongratulationPopup] = useState(false);
	const [courseDetail, setCourseDetail] = useState([]);
	const [courseActivityId, setCourseActivityId] = useState([]);
	const [courseId, setCourseId] = useState(location?.state?.myPlancourseId);
	const [isTemp, setIsTemp] = useState(false);
	const [calenderData, setCalenderData] = useState([]);
	const [isSelectePLan, setIsSelectPlan] = useState(false);

	useEffect(() => {
		dispatch(showScreen());
		setCalenderData([]);
		dispatch(resetResponse());
	}, [])

	useEffect(() => {
		//	dispatch(getMyPlan(params?.id))
		redirectLeftOfScreen(params?.id);
		//	console.log();
	}, [params?.id, location?.state])

	useEffect(() => {
		dispatch(breadcrumb({ title: "My Plans", subTitle: showPlanData?.planName }));
	}, [params?.id, showPlanData])

	useEffect(() => {
		if (getMyPlanData && !isSelectePLan) {
			if (getMyPlanData?.records?.length === 0) {
				/*
				history.push({
					pathname: PATHS.STR_LEARNER_PLAN + defaultChildData?.id,
				});
				if (location?.state?.showPlanPopup) {
					dispatch(showModal({ type: "MyLearnerPlanRedirectPopup" }));
				}
				return;
				*/
			} else {
					if(location.selectedFrom === 'home'){
						dispatch(activePlanIndexNo({index : location.index}))
						setShowPlanData(getMyPlanData?.records[location.index]);
					}else{
						
						setShowPlanData(getMyPlanData?.records[0]);
					}
				// setShowPlanData(getMyPlanData?.records[0]);
				let courses = [];
				// getMyPlanData?.records[0]?.dimensions?.map((dim, index) => {
				getMyPlanData?.records[location.index ? location.index : "0"]?.dimensions?.map((dim, index) => {
					dim?.skills?.map((skill, key) => {
						skill?.courses?.map((course, cKey) => {
							course.skill = skill;
							course.dimension = { key: dim?.id, value: dim?.name }
							courses.push(course);
						})
					})
				})
				setShowPlanCourseData(courses);
			}
		}
	}, [getMyPlanData, enrollObj, showPlanData])

	const viewReward = (data, isCourse, reward) => {
		setOpenReward(data);
		dispatch(getRewardData(defaultChildData?.id, reward?.id, isCourse));
	};

	const viewCertificate = (data, courseData, skills) => {
		setCertificate(data);
		setCertificateData(courseData);
		setcertificateskilldata(skills);
	};

	const getLearnerName = () => {
		return getCapitalized(defaultChildData?.firstName);
	};

	const gotoSelectedPlan = (planIndex) => {
		setShowPlanData(getMyPlanData?.records[planIndex]);
		let courses = [];
		getMyPlanData?.records[planIndex]?.dimensions?.map((dim, index) => {
			dim?.skills?.map((skill, key) => {
				skill?.courses?.map((course, cKey) => {
					course.skill = skill;
					course.dimension = { key: dim?.id, value: dim?.name }
					courses.push(course);
				})
			})
		})
		setShowPlanCourseData(courses);
		setIsSelectPlan(true);
	}

	const handleCongratulationPopup = (vl) => {
		setCongratulationPopup(vl);
	};

	const getDataFromCourseProgress = (result, data, course) => {
		setCourseDetail(course);
		setCourseActivityId(data);
		dispatch(getSocialActivityDetail());
		if (course?.isAcademic) {
			setCongratulationPopup(result);
		} else {
			setShowCongratulationModel(result);
		}
	};

	const editChild = (url) => {
		if (url) {
			history.push({
				pathname: url,
			});
		}
	};

	useEffect(() => {
		if (location?.state?.myPlancourseId && defaultChildData) {
			setCourseId(location?.state?.myPlancourseId);
			let userId = defaultChildData?.id;
			let levelId = defaultChildData?.level?.id;
			dispatch(getSettingData(userId, levelId));
		}
	}, [location?.state?.myPlancourseId])

	useEffect(() => {
		if (settingData?.records?.length > 0 && courseId) {
			let weekArr = [];
			let todayDate = new Date();
			for (let i = 0; i < 2; i++) {
				let weekDay = new Date(todayDate).toLocaleString("en-us", { weekday: 'short' });
				weekArr?.push(weekDay);
				todayDate.setDate(todayDate.getDate() + 1);
			}
			let myCourseArr = settingData?.records?.find((item) => item?.id === courseId)
			handleCalculateStartDateEndDate(
				myCourseArr ? [myCourseArr] : [],
				2,
				30,
				weekArr,
				"recomndedPlan"
			);
			dispatch(getSettingData());
			setCourseId();
		}
	}, [settingData])

	const handleCalculateStartDateEndDate = (data, sitting, duration, weekArr, type) => {
		let newArray = [];
		let getPlanDateArr = getCalculatePlanDate(data, duration, sitting, "", weekArr)
		if (getPlanDateArr?.length > 0) {
			// let signInData = signinresponse?.records[0];
			let array = {
				name: data[0]?.name,
				sittings: 2,
				duration: 30,
				planCount: "",
				timeZone: defaultChildData?.timeZone,
				planType: "",
				daysOfWeek: weekArr,
				userId: defaultChildData?.id,
				iscourseupdate: true,
				courses: getPlanDateArr
			};
			dispatch(courseEnrollmentWithSession(defaultChildData?.id, data[0]?.skillId, data[0]?.id, array)).then(() => {
				if (!defaultChildData?.isUserPlan) {
					dispatch(getAllUsersDetail());
				}
			});
			dispatch(showScreen({
				type: "",
				data: array?.courses,
				getUserTimeZone: array?.timeZone,
				dayOfWeek: array?.daysOfWeek,
				isOneCoursePlan: true,
				holidays: [],
			}));
		}
	};

	useEffect(() => {
		if (screenData?.data?.length > 0 && screenData?.isOneCoursePlan && false) {
			let calenderArr = getCalenderViewData(screenData);
			let calenderSesonsData = calenderArr?.filter((week) => week?.find((item) => item?.courseName))
			setCalenderData(calenderSesonsData);
		}
	}, [screenData])

	const showPlanScreen = () => {
		screenData.data[0].isEditPlan = true;
		screenData.data[0].planId = courseEnrollResponse?.recordId;
		dispatch(enrollModal(screenData?.data[0]));
		dispatch(courseEnrollmentWithSession());

	}

	useEffect(() => {
		if (response?.success) {
			setCalenderData([]);
			dispatch(showScreen());
			//	dispatch(getMyPlan(params?.id))
			setCourseId();
			history.replace({ ...history.location, state: {}, type: "" });
		}
	}, [response]);

	useEffect(() => {
		if (getMyPlanData?.records?.length > 0 && courseEnrollResponse?.success) {
			let plnObj = getMyPlanData?.records?.find((item) => item?.planId === courseEnrollResponse?.recordId);
			setShowPlanData(plnObj);
		}
	}, [getMyPlanData, courseEnrollResponse])

	useEffect(() => {
		if (courseEnrollResponse?.success && !isTemp) {
			dispatch(getMyPlan(params?.id))
			setIsTemp(true);
		}
	}, [courseEnrollResponse])
	
	return (
		<div>
			<Home>
				<div className="d-flex flex-wrap SpecialLeftpanel w-100">
					<div className="d-flex w-100 align-items-start overflow-visible">
						<div className="LeftbarPannel p-0" id="">
							<div className="CourseCardWrapper fullHeight100 ">
								<div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
									<h2
										data-toggle="collapse"
										class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
									>
										<span className="flexone">
											{" "}
											<img src={image.doc_icon} className="mr-2 rocket_img_" alt="" />
											{showPlanData?.planName}
										</span>
										<div class="Selected_paced  flexone m-0">
											<p className="p-0"><span>Sessions per week: {" "}</span> {showPlanData?.sittings ? convertedNumber(showPlanData?.sittings) : 2} ({showPlanData?.sittings}) </p>
											<p className="p-0 duration_Time"><span>Duration per session: </span> {showPlanData?.duration} minutes</p>
										</div>
									</h2>
								</div>
								{
									calenderData?.length > 0 ? (
										<>

											<div className="mb-4 mt-2 flexone align-items-start courseenroll_Confirmation newenrollepopup pace_screen_ parents_Support">
												<img src={image.newcheckicon} />
												<div className="congraulation_enroll">
													<h3 className="mb-2 pl-3 text-left w-100">
														Congratulations on enrolling {getCapitalized(defaultChildData?.firstName)} in {screenData?.data[0]?.name}. We have used the following recommended
														assumptions to build a plan for {getCapitalized(defaultChildData?.firstName)} to maximize learning and complete this course.
														{/* Congratulations! You have successfully created a plan {selectedMinutes}{" "}
										minutes per <br /> sitting for {selectedSitting} sittings a week of {" "}
										{getDayOfWeek?.map((day, key) => (
											<span key={key}>
												{day}
												{(key === getDayOfWeek?.length - 1) ? "." : ", "}
											</span>

										))} */}
													</h3>
													<div className="flexone PaceModeSel paceSteps align-items-baseline mb-0">
														<div class="sessioprweek">
															<h3 class="HomeProgresstilt  border-0 pl-0"> Sessions per Week<span class="mandatoryField">*</span>
															</h3>
															<ul class="panel-collapse collapse show">
																<li>
																	<div class="selectecPaceWeek border-0 signupType flex-wrap newenrollepopup panel-collapse collapse show" id="newenrollepopup">
																		<label class="Selcheckbox m-0 ActiveQQst">
																			<input type="radio" name="skill0" checked="true" disabled />2 <span>Sessions</span>
																			<span class="checkmark"> </span>
																			{/* <p class="recummended">(Recommended)</p > */}
																		</label>
																	</div>
																</li>
															</ul>
														</div>
														<div class="durationprsessn ml-3">
															<h3 class="HomeProgresstilt  border-0 pl-0 pr-0"> Duration per session<span class="mandatoryField">*</span></h3>
															<ul class="panel-collapse collapse show"><li>
																<div class="selectecPaceWeek signupType flex-wrap">
																	<label class="Selcheckbox m-0 ActiveQQst">
																		<input type="radio" name="minutes" checked="true" disabled />30 <span>minutes</span><span class="checkmark"></span>
																		{/* <p class="recummended">(Recommended)</p > */}
																	</label>
																</div>
															</li>
															</ul>
														</div>
														<div class="ScenecerelateddQuiz mb-0 selec_Days_Custom pl-3 p-0 border-0 w-48 selsctdayasinwek">
															<div class="signupType m-0 w-100">
																<div class="PaceModeSel w-100">
																	<h3 class="HomeProgresstilt pl-0 flex border-0" data-toggle="collapse" href="#selectdaysbase" aria-expanded="false"> <span>Select (2) Days you want to work on Bloomster.<span class="mandatoryField">*</span></span></h3>
																	<div class="panel-collapse collapse show" id="">
																		<div class="selectecPaceWeek w-100 mb-2">
																			<label class="Selcheckbox m-0 ActiveQQst iamflexiblelabel border-0 pb-0">
																				<input type="checkbox" name="skill0" defaultChecked={true} disabled={true} /><span class="flexible">I'm flexible</span>
																				<span class="checkmark"> </span>
																			</label>
																			<p class="onlinertxt pb-1" data-for="completion" data-event-off="" data-tip="true">We select consecutive days starting the 'Start Date' and the 'Sessions per Week'.</p>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<p className="edipt_cours_plan">If you wish to make changes to the schedule below, click on the edit icon. <span class="editcalenderIcon active pointer" onClick={() => showPlanScreen()}><i class="fa-light fa-pencil active ml-2"></i></span> </p>
												</div>

											</div>

											<div className="newenrollepopup">
												<div id="planCalendarView" className=" pb-4">
													<span className="d-flex align-items-center pb-3">
														<h3 className="courseschettl flexone"> <img src={image.calenderIcon1} alt="" className="mr-2" />Schedule</h3>
													</span>
													{calenderData?.length > 0 ? (
														<div className="tableResponsive">
															<table className="border-0 w-100 mt-2">
																<thead>
																	<tr>
																		<th>Week</th>
																		<th>Mon</th>
																		<th>Tue</th>
																		<th>Wed</th>
																		<th>Thu</th>
																		<th>Fri</th>
																		<th>Sat</th>
																		<th>Sun</th>
																	</tr>
																</thead>
																<tbody>
																	{calenderData?.map((weeks, index) => {
																		const currentWeek = weeks?.find((item) => item?.courseName)
																		return (
																			<>
																				{
																					currentWeek && (
																						<tr key={index}>
																							<td class="bg-color1">
																								<p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>{weeks[0]?.date} - {weeks[6]?.date}</p>
																								<p className="week">Week {index + 1}</p>
																							</td>
																							{weeks?.map((days, key) => (
																								<td class={new Date(days?.currentDate).toDateString() === new Date().toDateString() ? "current" : "bg-color1"}>
																									<div className="d-flex align-items-baseline justify-content-between flex-wrap">
																										<p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
																											{days?.date}</p>
																										{days?.inProress && days?.sittings && (
																											<p className="upcoming">In Progress</p>
																										)}
																										{days?.isCompleted && days?.sittings && (
																											<p className="completed">Complete</p>
																										)}
																									</div>
																									{
																										days?.courseName && (
																											<>
																												<div className="d-flex pt-2 align-items-center">
																													<span className="courseCircle me-1">C{days.courseKey}</span>
																													<React.Fragment>
																														{days?.courseName?.length > 17 ? (
																															<ReactTooltip id={"course" + days?.date}>
																																<p>{days?.courseName}</p>
																															</ReactTooltip>
																														) : (
																															""
																														)}
																														<div className="courseName" data-for={"course" + days?.date} data-event-off="" data-tip>
																															{textTrim(days?.courseName, 17)}
																														</div>
																													</React.Fragment>
																												</div>
																												<div className="d-flex pt-2 align-items-center">
																													<span className="moduleCircle me-1">M{days?.moduleKey}</span>
																													<React.Fragment>
																														{days?.moduleName?.length > 17 ? (
																															<ReactTooltip id={days?.moduleName + "" + key}>
																																<p>{days?.moduleName}</p>
																															</ReactTooltip>
																														) : (
																															""
																														)}
																														<div className="moduleName" data-for={days?.moduleName + "" + key} data-event-off="" data-tip>
																															{textTrim(days?.moduleName, 17)}
																														</div>
																													</React.Fragment>
																												</div>
																											</>
																										)
																									}
																								</td>
																							)
																							)}
																						</tr>
																					)
																				}
																			</>
																		)
																	})}
																</tbody>
															</table>
														</div>
													) : (
														<ShimmerTable row={4} col={4} />
													)}
												</div>
											</div>
										</>
									) :
										getMyPlanData?.records?.length > 0 ? (
											<div className="learner_plan setup_two ljpopups setup_three">
												<div className="gridSection coursepagelist pt-2">
													{(loggedInUser?.role?.name === "TEACHER" || loggedInUser?.role?.name === "STUDENT") &&
														<div class="learnerplanOrderone">
															<div className="Acquisioton_survy_Plan flex">
																<div className="flex w-100">
																	<h4 className="flexone">
																		<img src={image.acquisitionsurvey} className="mr-2" />
																		Skill Acquisition Survey
																	</h4>
																	<div>
																		{!getMyPlanData?.records[0]?.isSAAttempted ?
																			<button
																				onClick={() => editChild(PATHS.SKILLSACQUISITION_STR + params?.id)}
																				className='btn-blue btn-login d-block  m-auto w-auto'>
																				<i className='fa-solid fa-paper-plane mr-2'></i>Begin Surey
																			</button>
																			:
																			<span className="priceWrap enrolledBtn pt-0 pointer" onClick={() =>
																				editChild(PATHS.SKILLSACQUISITION_STR + params?.id)
																			}>
																				<img src={image.newcheckicon} className="mr-1" />
																				Completed
																			</span>}
																	</div>
																</div>
															</div>

														</div>}
													<div className="Leaner_plan_Courselist mt-3">
														{
															getSequnceSort(showPlanCourseData)?.map((course, cKey) => (
																<React.Fragment key={cKey}>
																	<CourseCard
																		data={course}
																		skills={course?.skill}
																		viewReward={viewReward}
																		viewCertificate={viewCertificate}
																	/>
																</React.Fragment>
															))
														}
													</div>
													{(loggedInUser?.role?.name === "TEACHER" || loggedInUser?.role?.name === "STUDENT") &&
														<div className="Acquisioton_survy_Plan">
															<div className="flex">
																<h4 className="flexone">
																	<img src={image.acquisitionsurvey} className="mr-2" />
																	Skill Acquisition Survey
																</h4>
																<div>
																	{/* <button
															onClick={() => handleQuiz()}
															className='btn-blue btn-login d-block  m-auto w-auto'>
															<i className='fa-solid fa-paper-plane mr-2'></i>Begin Surey
															</button> */}
																	{!getMyPlanData?.records[0]?.isSAAttempted ?
																		<button
																			onClick={() => editChild(PATHS.SKILLSACQUISITION_STR + params?.id)}
																			className='btn-blue btn-login d-block  m-auto w-auto'>
																			<i className='fa-solid fa-paper-plane mr-2'></i>Begin Surey
																		</button>
																		:
																		<span className="priceWrap enrolledBtn pt-0 pointer" onClick={() =>
																			editChild(PATHS.SKILLSACQUISITION_STR + params?.id)
																		}>
																			<img src={image.newcheckicon} className="mr-1" />
																			Completed
																		</span>}
																</div>
															</div>
														</div>}
												</div>
											</div>
										) : (
											<ShimmerCategoryList items={4} categoryStyle="STYLE_SEVEN" />
										)
								}
							</div>
							{/* <div class="input-group full-Width-group basic_details_form pagebuttonStrip position_sticky bottom-0">
								<div class="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0">
									<div class="w-100">
										<div class="priceWrap p-0">
											<button type="submit" class="btn-blue btn-login d-block w-auto mb-5 ml-auto" disabled="">
												<i className='fa-solid fa-paper-plane mr-2'></i>Update</button>
										</div>
									</div>
								</div>
							</div> */}

						</div>

						<div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl myplan_rightpannel">
							<LearnerPlanRightPanel
								screen={1}
								selectedOption={"myplan"}
								coursePlanTree={[]}
								gotoSelectedPlan={gotoSelectedPlan}
								getDataFromCourseProgress={getDataFromCourseProgress}
								showPlanCourseData={showPlanCourseData}
								singlePlancourseId={location?.state?.myPlancourseId}
							/>
						</div>
					</div>
				</div>
				{
					openReward && (
						<RewardPopUp
							closeReward={viewReward}
							rewardDataWithoutAPI={openReward}
						/>
					)
				}

				{
					certificate && (
						<Certificate
							_redirectLesson={viewCertificate}
							certificateData={certificateData}
							certificateskilldata={certificateskilldata}
						/>
					)
				}

				{showCongratulationModel && !courseDetail?.isAcademic && (
					<CongratulationCard
						courseActivityId={courseActivityId}
						handleOpenpopup={getDataFromCourseProgress}
						isContinueButtonClick={false}
					/>
				)}

				{congratulationPopup && courseDetail?.isAcademic && (
					<LessonCongratulationsPopup
						handleCongratulationPopup={handleCongratulationPopup}
						courseActivityId={courseActivityId}
					/>
				)}
			</Home >
		</div >
	);
};

export default Myplan;
