import React, { useState } from "react";
import * as image from "../../../../resources/images";
import { submitGrowthSurveyData } from "../../../../redux/actions/APIs";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { setSurveyAss } from "../../../../redux/actions";

const ConfirmationPopup = ({ setStartPopup, finalArray ,courseId,  assSurveyId }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const handleSubmitData = () => {
    setLoader(true);
    if (finalArray) {      
      dispatch(submitGrowthSurveyData(params.id, courseId, assSurveyId, finalArray));
      dispatch(setSurveyAss(false));
    }
  };
  return (
    <div className='newinfo_popup holisticview'>
      <div
        className='modal d-flex newEnrollCoursPopup'
        id='schoolactivity72'
        role='dialog'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div className='heading border-0 p-0 w-100'>
                <h2 className='flex'>
                  <span>
                    <img src={image.greentickarrow} className='mr-2' />
                    Confirm Submission
                  </span>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      setStartPopup(false);
                    }}>
                    <i className='fa-regular fa-xmark m-0'></i>
                  </button>
                </h2>
              </div>
            </div>

            <div className='modal-body ps-3 pe-2'>
              <div className='infopopup_wrap  align-items-start'>
                <div className='infopopupright align-items-start pb-2 d-flex'>
                  <div className='info_related_text flex-child'>
                    <h4>
                      Submit your survey now or click 'Review' to edit your
                      answers
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <div className='form-group BDsubmitbutton m-0 d-flex'>
                <div className='buttonDistribotion justify-content-end'>
                  <button
                    type='submit'
                    className='btn-blue btn-login d-block w-auto'
                    onClick={() => {
                      setStartPopup(false);
                    }}>
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                    Review
                  </button>
                  {loader ? (
                    <div className='p-0'>
                      <button
                        className='btn-blue btn-login d-block w-auto mb-5 ml-auto'
                        key={Math.random()}
                        disabled>
                        <span className='RounAnimation mr-1'></span>
                        Please wait
                      </button>
                    </div>
                  ) : (
                    <button
                      type='submit'
                      className='btn-blue btn-login d-block w-auto'
                      onClick={() => {
                        handleSubmitData();
                      }}>
                      <i
                        class='fa-solid fa-paper-plane mr-2'
                        aria-hidden='true'></i>
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmationPopup;
