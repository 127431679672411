import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../resources/images";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { convertedNumber, getCapitalized } from "../../utils/helper";
const DeletePlanActivities = ({
	closePlan,
	deletePlan,
	handleRemoveTagDimSkillCourse,
	handleRemoveDimensionSkillCourse,
	handleBack,
	handleMoreOptions,
	modalData,
	handleDeletePlan,
}) => {
	const handleDelete = () => {
		if (deletePlan?.removeValue) {
			if (deletePlan?.dimType === "removeDimension") {
				handleRemoveDimensionSkillCourse(deletePlan?.removeValue, deletePlan?.dimension, deletePlan?.skill, deletePlan?.course, false, deletePlan?.type, deletePlan?.dimTree)
			} else {
				handleRemoveTagDimSkillCourse(deletePlan?.removeValue, deletePlan?.dimension, deletePlan?.skill, deletePlan?.course, false, deletePlan?.type, deletePlan?.dimTree)
			}
		} else if (deletePlan?.isCreatePlan) {
			handleMoreOptions("createNewPlan");
		} else if (deletePlan?.isCourseCatlog) {
			handleMoreOptions("courseCatlog")
		} else if (deletePlan?.planId) {
			handleDeletePlan(deletePlan?.planId);
		} else {
			handleBack(true);
		}
		closePlan();
	}
	return (
		<div className="sharepopup  AreyousurePopup areyousurenewpopup delete_your_pln">
			<div className="modal show d-flex" id="schoolactivity161" role="dialog">
				<div className="modal-dialog modal-lg">
					<div className="modal-content courseInformation schoolcourse ">
						<div className="modal-body p-5">
							<div className="sharewithfrnd ">
								<h3 className="text-center flextwo"><img src={image.exclamationTrangle} className="mr-2" />{deletePlan?.title ? deletePlan?.title : (deletePlan?.planId ? "Delete Plan" : "Are you sure?")}</h3>
								<p className="pb-2 pt-2 mt-3 text-center" >
									{deletePlan?.message}</p>
								{
									deletePlan?.showPlanCourses?.length > 0 && (
										<>
											<p className="mb-3 pb-2 pt-2 text-left" >
												Note : You can continue with course(s) below in your other plan.
												<ul className="mt-2">
													{deletePlan?.showPlanCourses?.map((courseName, key) => (
														<li>
															<p>
																{key + 1}{". " + courseName}
															</p>
														</li>
													))}
												</ul>
											</p>

										</>
									)
								}
								<div className="buttonDistribotion justify-content-around mt-3">
									{deletePlan?.title === undefined ? (
										<>
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 cancelbutton w-auto ml-0"
												onClick={() => closePlan()}
											>
												Cancel
											</button>
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 w-auto"
												onClick={() => handleDelete()}
											>
												Ok
											</button>
										</>
									) : (
										<button
											type="button"
											className="btn-blue btn-login d-block mb-5 w-auto"
											onClick={() => closePlan()}
										>
											Ok
										</button>
									)}
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	);
}
export default DeletePlanActivities;