/** @format */

export const FREE_SIGNUP = "FREE_SIGNUP";
export const SELF_SIGNUP_SUCCESS = "SELF_SIGNUP_SUCCESS";
export const SELF_SIGNUP_FAILURE = "SELF_SIGNUP_SUCCESS_FAILURE";
export const USER_LOGIN = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const LOGOUT_UISTATE = "LOGOUT_UISTATE";
export const LOGOUT_JWT = "LOGOUT_JWT";
export const GET_ALL_USERS_DETAIL_SUCCESS = "GET_ALL_USERS_DETAIL_SUCCESS";
export const CHILD_DETAIL_SUCCESS = "CHILD_DETAIL_SUCCESS";
export const CHILD_DETAIL_FAILURE = "CHILD_DETAIL_FAILURE";

export const GET_DEGREES_SUCCESS = "GET_DEGREES_SUCCESS";
export const GET_FIELDS_SUCCESS = "GET_FIELDS_SUCCESS";

export const GET_SCHOOLS_KEYWORDS_SUCCESS = "GET_SCHOOLS_KEYWORDS_SUCCESS";

export const GET_INDUSTRIES_SUCCESS = "GET_INDUSTRIES_SUCCESS";

export const USER_EDUCATION = "USER_EDUCATION";
export const USER_EXPERIENCE = "USER_EXPERIENCE";
export const ADD_SCHOOL = "ADD_SCHOOL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const SIDEBAR = "SIDEBAR";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const ISLOADING = "ISLOADING";
export const GET_GRADE_LEVEL = "GET_GRADE_LEVEL";

export const STUSCORE = "STUSCORE";
export const UPDATE_INIT_ASS_RESPONSE = "UPDATE_INIT_ASS_RESPONSE";
export const SELECTED_USER = "SELECTED_USER";
export const GET_COMPANY_BYKEYWORD = "GET_COMPANY_BYKEYWORD";
export const RESPONSE = "RESPONSE";
export const RESPONSE_FAILURE = "RESPONSE_FAILURE";
export const GET_EDUCATION = "GET_EDUCATION";
export const GET_COURSE = "GET_COURSE";
export const AUTH_TRUE = "AUTH_TRUE";
export const GET_ENRICH_KEYWORDS_SUCCESS = "GET_ENRICH_KEYWORDS_SUCCESS";
export const LOGGEDIN_USER = "LOGGEDIN_USER";
export const GET_INTEREST_KEYWORDS_SUCCESS = "GET_INTEREST_KEYWORDS_SUCCESS";
export const GET_INTEREST_SUCCESS = "GET_INTEREST_SUCCESS";
export const CHILD_LOGIN = "CHILD_LOGIN";
export const PARENT_LOGIN = "PARENT_LOGIN";
export const GET_INTERESTBY_ID_SUCCESS = "GET_INTERESTBY_ID_SUCCESS";
export const GET_DIMENSIONS_SUCCESS = "GET_DIMENSIONS_SUCCESS";
export const UPLOAD_RESPONSE = "UPLOAD_RESPONSE";
export const ADD_INTEREST_RESPONSE = "ADD_INTEREST_RESPONSE";
export const RESPONSE_OK = "RESPONSE_OK";
export const DIMENTOIN_DATA = "DIMENTOIN_DATA";
export const COURSELIST = "COURSELIST";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const GET_TITLE_BYKEYWORD = "GET_TITLE_BYKEYWORD";
export const GET_INDUSTRY_BYKEYWORD = "GET_INDUSTRY_BYKEYWORD";
export const GET_DIVISION_BYKEYWORD = "GET_DIVISION_BYKEYWORD";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const GET_COURSEDATA = "GET_COURSEDATA";
export const META_RESPONSE = "META_RESPONSE";
export const RATINGS = "RATINGS";
export const RESOURCES_RATINGS = "RESOURCES_RATINGS";
export const ACTIVITY_RATING = "ACTIVITY_RATING";
export const GET_SKILLS = "GET_SKILLS";
// export const GET_SERVICES = "GET_SERVICES";
export const IMAGEDOWNLOAD = "IMAGEDOWNLOAD";
export const GET_TEACHER = "GET_TEACHER";
export const ADD_SKILLS = "ADD_SKILLS";
export const GET_POPULAR_TITLES = "GET_POPULAR_TITLES";
export const ADD_TITLE = "ADD_TITLE";
export const UPLOAD_VIDEO = "UPLOAD_VIDEO";
export const ADD_PROVIDER_DETAIL = "ADD_PROVIDER_DETAIL";
export const USER_SIGNUP = "USER_SIGNUP";
export const GET_ASSDATA = "GET_ASSDATA";
export const GET_PROVIDER = "GET_PROVIDER";
export const TECHERASSESSSUBMIT = "TECHERASSESSSUBMIT";
export const GET_CURRICULAM = "GET_CURRICULAM";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_STATE = "GET_STATE";
export const PRIMARY_SKILLS = "PRIMARY_SKILLS";
export const ENROLL_RESPONSE = "ENROLL_RESPONSE";
export const CHECK_ASSESSMENT = "CHECK_ASSESSMENT";
export const GET_METADATA = "GET_METADATA";
export const GET_RESOURCES = "GET_RESOURCES";
export const GET_USER_ACTIVITY = "GET_USER_ACTIVITY";
export const VERIFY_USER = "VERIFY_USER";
export const RESPONSE_ERROR = "RESPONSE_ERROR";
export const GET_ENROLLED_LEARNERS = "GET_ENROLLED_LEARNERS";
export const GET_ASSESS_OF_LEARNERS = "GET_ASSESS_OF_LEARNERS";
export const GET_PROGRESS_CHART = "GET_PROGRESS_CHART";
export const GET_INTEREST_CATEGORIES = "GET_INTEREST_CATEGORIES";
export const GET_INTEREST_CATEGORY_BY_ID = "GET_INTEREST_CATEGORY_BY_ID";
export const GET_INTEREST_LEVEL_BY_ID = "GET_INTEREST_LEVEL_BY_ID";
export const GET_ALL_INDUSTRIES = "GET_ALL_INDUSTRIES";
export const GET_GRADES = "GET_GRADES";
export const GET_IND_SCORE = "GET_IND_SCORE";
export const GET_ALL_SCORE = "GET_ALL_SCORE";
export const GET_TITLES_BYCHARACTER = "GET_TITLES_BYCHARACTER";
// export const GET_COMPLEX = "GET_COMPLEX";
export const GET_LESSON = "GET_LESSON";
export const TURN_NOTIFICATION = "TURN_NOTIFICATION";
export const RESET_SONG = "RESET_SONG";
export const GET_RESOURCESDATA = "GET_RESOURCESDATA";
export const GET_SOCIAL_ACTIVITY_DATA = "GET_SOCIAL_ACTIVITY_DATA";
export const GET_SERIES_SCENES_DATA = "GET_SERIES_SCENES_DATA";
export const ADD_LEARNER = "ADD_LEARNER";
export const KEYAREA = "KEYAREA";
export const SELECTED_DIMTAB = "SELECTED_DIMTAB";
export const COURSEGUIDE = "COURSEGUIDE";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const DOWNGRADE_PLAN = "DOWNGRADE_PLAN";
export const BILLING_PLAN = "BILLING_PLAN";
export const GET_ALPHABET_DATA = "GET_ALPHABET_DATA";
export const GET_PROVIDER_COURSES = "GET_PROVIDER_COURSES";
export const BREADCRUMB = "BREADCRUMB";
export const DASHBOARD = "DASHBOARD";
export const HELPMODAL = "HELPMODAL";
export const SHOWFORM = "SHOWFORM";
export const GET_RIBBON = "GET_RIBBON";
export const DEFAULT_CHILD = "DEFAULT_CHILD";
export const QUIZ_DATA = "QUIZ_DATA";
export const GET_USER_NAME_RESPONSE = "GET_USER_NAME_RESPONSE";
export const KNOWLADGE_CHECK = "KNOWLADGE_CHECK";
export const NEXT_SCENE_RESPONSE = "NEXT_SCENE_RESPONSE";
export const COURATINGS = "COURATINGS";
export const PROVIDERRATINGS = "PROVIDERRATINGS";
export const SUBSCRIBDATA = "SUBSCRIBDATA";
export const GET_HOLISTIC = "GET_HOLISTIC";
export const RIBBON_OPEN = "RIBBON_OPEN";
export const RESPONSE_ERROR_DATA = "RESPONSE_ERROR_DATA";
export const GET_PATH = "GET_PATH";
export const PLANS = "PLANS";
export const LOGINRESPONSE_FAILURE = "LOGINRESPONSE_FAILURE";
export const COURSE_ENROLLED = "COURSE_ENROLLED";
export const COURSEENROLL = "COURSEENROLL";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_START_ENROLL_COURSES = "GET_START_ENROLL_COURSES";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";
export const PILOT_USER = "PILOT_USER";
export const PILOT_USER_ERROR = "PILOT_USER_ERROR";
export const GET_CURATOR_COURSE = "GET_CURATOR_COURSE";
export const ENROLLCOURSEDETAIL = "ENROLLCOURSEDETAIL";
export const VERIFY_PILOT = "VERIFY_PILOT";
export const MODIFY_TOKEN_RESPONSE = "MODIFY_TOKEN_RESPONSE";
export const QUIZ_RESPONSE = "QUIZ_RESPONSE";
export const VERIFY_PILOT_ERR = "VERIFY_PILOT_ERR";
export const ACCORDIAN_STATUS = "ACCORDIAN_STATUS";
export const LOGINUSERID = "LOGINUSERID";
export const GET_DIMENSIONSREDIRECT = "GET_DIMENSIONSREDIRECT";
export const GET_MULTIQUIZ_DATA = "GET_MULTIQUIZ_DATA";
export const GET_EMPATHYQUIZ_DATA = "GET_EMPATHYQUIZ_DATA";
export const USER_ATTEMPTED_SURVEY = "USER_ATTEMPTED_SURVEY";
export const USER_EMPATHY_QUIZ = "USER_EMPATHY_QUIZ";
export const BEGIN_QUIZ = "BEGIN_QUIZ";
export const RANDOM_DIM = "RANDOM_DIM";
export const KC_STATE_RESPONSES = "KC_STATE_RESPONSES";
export const RANDOM_COURSE = "RANDOM_COURSE";
export const QUOTE_RES = "QUOTE_RES";
export const COLLAPSDATA = "COLLAPSDATA";

export const NF_REDIRECT = "NF_REDIRECT";
export const SET_TIME_OUT = "SET_TIME_OUT";
export const COURSE_JOURNEY = "COURSE_JOURNEY";
export const ENROLL_COURSES_JSON = "ENROLL_COURSES_JSON";

export const SETTING_DATA = "SETTING_DATA";
export const REWARD_DATA = "REWARD_DATA";
export const GET_COROBJ = "GET_COROBJ";
export const GET_RESPONSE = "GET_RESPONSE";
export const SETTING_VERIFY = "SETTING_VERIFY";
export const GET_DIM = "GET_DIM";
export const GET_SERIES_DATA = "GET_SERIES_DATA";
export const LEARNER_ATTEMPT_QUIZ = "LEARNER_ATTEMPT_QUIZ";
export const LASTQUIZ = "LASTQUIZ";
export const VIDEO_RESPONSE = "VIDEO_RESPONSE";
export const QUIZ_STORE = "QUIZ_STORE";
export const QUIZ_OPTION_STORE = " QUIZ_OPTION_STORE";
export const GET_JOURNY_DATA = "GET_JOURNY_DATA";
export const GET_JOURNY_COURSE_DATA = "GET_JOURNY_COURSE_DATA";
export const PRACTICE_STORE = "PRACTICE_STORE";
export const ENROLL_COURSEJOURNY = "ENROLL_COURSEJOURNY";
export const GET_JOURNY_ENROLL_DATA = "GET_JOURNY_ENROLL_DATA";
export const CHILD_NM = "CHILD_NM";
export const LOCKPOPUP_DATA = "LOCKPOPUP_DATA";
export const COURSE_MODAL = "COURSE_MODAL";
export const SUBSCRIBE_MODAL = "SUBSCRIBE_MODAL";
export const PROVIDER_MODAL = "PROVIDER_MODAL";
export const SHOW_MORE = "SHOW_MORE";
export const TAGS_COURSES = " TAGS_COURSES";
export const GET_JOURNY = "GET_JOURNY";
export const GET_JOURNY_COURSES = "GET_JOURNY_COURSES";
export const ENROLL_COURSEWISEJOURNY = "ENROLL_COURSEWISEJOURNY";
export const SET_COURSEJOURNEYID = "SET_COURSEJOURNEYID";
export const DIMVIEW = "DIMVIEW";
export const SIDEBAR_PROGRESS = "SIDEBAR_PROGRESS";
export const POPULAR_COURSES = "POPULAR_COURSES";
export const GROWTH_MODAL = "GROWTH_MODAL";
export const SKILL_MODAL = "SKILL_MODAL";
export const HOMEDIM = "HOMEDIM";
export const DIM_SKILL = "DIM_SKILL";
export const COMING_COURSES = "COMING_COURSES";
export const HOLISTIC_DATA = "HOLISTIC_DATA";
export const SKILL_PROGRESS = "SKILL_PROGRESS";
export const COURSE_DETAILS = "COURSE_DETAILS";
export const DONTSHOW = "DONTSHOW";
export const GROWTH_SKILLPOPUP = "GROWTH_SKILLPOPUP";
export const ENROLL_MODAL = "ENROLL_MODAL";
export const OVERALL_MODAL = "OVERALL_MODAL";
export const USER_GRADE_RESP = "USER_GRADE_RESP";
export const POST_RESPONSE = "POST_RESPONSE";
export const DIM_GUIDANCE = "DIM_GUIDANCE";
export const GET_BILLING_DATA = "GET_BILLING_DATA";
export const DOWNGRADE_MODAL = "DOWNGRADE_MODAL";
export const DOWNGRADE_USER = "DOWNGRADE_USER";
export const SELECTCHILD_MODAL = "SELECTCHILD_MODAL";
export const GET_INTELLIGENCES_DATA = "GET_INTELLIGENCES_DATA";
export const POST_INTELLIGENCES_DATA = "POST_INTELLIGENCES_DATA";
export const PAYMENT_UPDATE = "PAYMENT_UPDATE";
export const SELECTED_OPTION = "SELECTED_OPTION";
export const JOURNEY_COURSE_MODAL = "JOURNEY_COURSE_MODAL";
export const GET_COURSE_SUMMARY_DATA = "GET_COURSE_SUMMARY_DATA";
export const RESETCARD = "RESETCARD";
export const POST_SENDCOLLABEMAIL_DATA = "POST_SENDCOLLABEMAIL_DATA";
export const POPULARCOURSES_DATA = "POPULARCOURSES_DATA";
export const PARENT_TOOLS_DATA = "PARENT_TOOLS_DATA";
export const TAGCOURSES_DATA = "TAGCOURSES_DATA";
export const COURSES_TAG = "COURSES_TAG";
export const DIM_SKILL_OBJ = "DIM_SKILL_OBJ";
export const ENROLLMENT = "ENROLLMENT";
export const COUNTDATA = "COUNTDATA";
export const SHOW_MODAL_OBJ = "SHOW_MODAL_OBJ";
export const COURSEPAGE_KEY = "COURSEPAGE_KEY";
export const HOMEPAGE_KEY = "HOMEPAGE_KEY";
export const DIM_STORE = "DIM_STORE";
export const DIM_MASTERDATA = "DIM_MASTERDATA";
export const USER_DELETE = "USER_DELETE";
export const PATHWAYPAGE_KEY = "PATHWAYPAGE_KEY";
export const DIMPAGE_KEY = "DIMPAGE_KEY";
export const DELETEAGE_KEY = "DELETEAGE_KEY";
export const SUBSCRIPTION_KEY = "SUBSCRIPTION_KEY";
export const DIMDATASTORE = "DIMDATASTORE";
export const HUBSPOT_SIGNUP = "HUBSPOT_SIGNUP";
export const CNAMEPAGE_KEY = "CNAMEPAGE_KEY";
export const APPLY_DISCOUNT = "APPLY_DISCOUNT;";
export const SENSITIVEMODAL = "SENSITIVEMODAL";
export const SENSITIVEVIDEO = "SENSITIVEVIDEO";
export const OPENSETTING = "OPENSETTING";
export const VIEWVDOSENSITIVE = "VIEWVDOSENSITIVE";
export const APPLY_DISCOUNT_ERR = "APPLY_DISCOUNT_ERR";
export const USER_AUTHENTICATE = "USER_AUTHENTICATE";
export const GET_VERIFY_USER_RESPONSE = "GET_VERIFY_USER_RESPONSE";
export const COUREVIEWRATINGS = "COUREVIEWRATINGS";
export const COURSERATINGMOODAL = "COURSERATINGMOODAL";
export const COURSESECTIONOBJ = "COURSESECTIONOBJ";
export const CERT_IMAGE_RESPONSE = "CERT_IMAGE_RESPONSE";
export const LEARNER_PLAN = "LEARNER_PLAN";
export const VIEW_LEARNER_PLAN = "VIEW_LEARNER_PLAN";
export const GET_DIMNSION_PLAN = "GET_DIMNSION_PLAN";
export const CHANGE_PACE_MODAL = "CHANGE_PACE_MODAL";
export const MODULE_DETAIL_MODAL = "MODULE_DETAIL_MODAL";
export const DIMENSION_UPDATE_PLAN = "DIMENSION_UPDATE_PLAN";
export const LEARNER_PLAN_TAGS = "LEARNER_PLAN_TAGS";
export const CREATE_NEW_PLAN = "CREATE_NEW_PLAN";
export const DIM_SKILL_COURSES = "DIM_SKILL_COURSES";

/* Class Schedule 8 may 2024 */
export const CLASS_SCHEDULE = "CLASS_SCHEDULE";
export const CLASS_SCHEDULE_DATA = "CLASS_SCHEDULE_DATA";
export const VERIFY_EMAIL_RESPONSE = "VERIFY_EMAIL_RESPONSE";
/* Growth Survey 16 May 2024 */
export const GROWTH_SURVEY_DATA = "GROWTH_SURVEY_DATA";
export const SELECTED_OPTION_DATA = "SELECTED_OPTION_DATA";
export const GROWTH_ATTEMPTED_SURVEY = "GROWTH_ATTEMPTED_SURVEY";
export const GROWTH_RIGHTPANEL_OPEN = "GROWTH_RIGHTPANEL_OPEN";
export const GET_MY_PLAN = "GET_MY_PLAN";
export const GET_USERS_ENTRYPOPUP = " GET_USERS_ENTRYPOPUP";
export const SELECTED_OPTION_PRE = "SELECTED_OPTION_PRE";
//export const SELECTED_OPTION_DATA_QUES = "SELECTED_OPTION_DATA_QUES";//18-06-2024
export const REVIEW_DATA = "REVIEW_DATA";
export const TEACHER_REPORT = "TEACHER_REPORT";
export const IMPORTANT_GROWTH_SURVEY_POPUP = "IMPORTANT_GROWTH_SURVEY_POPUP";

export const KC_REPORT_DATA = "KC_REPORT_DATA";
export const REPORT_PROGRESS = "REPORT_PROGRESS";
export const GROWTH_SURVER_REPORTS = "GROWTH_SURVER_REPORTS";
export const PLAN_SUMMARY_REPORTS = "PLAN_SUMMARY_REPORTS";
export const SHOW_HOLISTIC_VIEW_SKILL = "SHOW_HOLISTIC_VIEW_SKILL";
export const REPOORT_SPECIFIC = "REPOORT_SPECIFIC";
export const INTELLIGENCE_GROWTHSURVEY = "INTELLIGENCE_GROWTHSURVEY";
export const GET_SKILL_PROGRESS = "GET_SKILL_PROGRESS";
export const DASHBORD_PLAN_SUMMARY = "DASHBORD_PLAN_SUMMARY";
export const SHOW_PANEL = "SHOW_PANEL";
export const MYJOURNAL = "MYJOURNAL";
export const GETDIMENSIONCOURSES = "GETDIMENSIONCOURSES"
export const GET_SOCIAL_ACTIVITY_DATA_FOR_MYJOURNAL = "GET_SOCIAL_ACTIVITY_DATA_FOR_MYJOURNAL";
export const KC_REPORT_DATA_FOR_CHECKBOX = "KC_REPORT_DATA_FOR_CHECKBOX";
export const DIM_COURSE_PLAN = "DIM_COURSE_PLAN";
export const DIMENSION_LEVEL_GROWTHSURVEY = "DIMENSION_LEVEL_GROWTHSURVEY";
export const SMART_QUIZ_DATA = "SMART_QUIZ_DATA";
export const GET_ACQUISITION_MULTIQUIZ_DATA = "GET_ACQUISITION_MULTIQUIZ_DATA";
export const USER_ACQUISITION_ATTEMPTED_SURVEY = "USER_ACQUISITION_ATTEMPTED_SURVEY";
export const SHOW_SCREEN = "SHOW_SCREEN";
export const ACTIVE_PLAN = "ACTIVE_PLAN";
export const RATING_POPUP = "RATING_POPUP";