/** @format */

import React from "react";
import * as image from "../../resources/images";
const Error = ({ error }) => {
  return (
    <div>
      {/* <h2> My title</h2> */}
      <p>{error}</p>
    </div>
  );
};

export default Error;
