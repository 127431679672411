import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as image from "../../resources/images";
import { getUrlSegment } from "../../utils/helper";
import { showPanel } from "../../redux/actions";
import { ShimmerCategoryList } from "react-shimmer-effects";

const GrowthSurveyRightPanel = ({ handleBackReview }) => {

	const { showPanelObj, intelligenceSurveyData } = useSelector(
		(state) => state.collections
	);
	const dispatch = useDispatch();
	const [preData, setPreData] = useState([]);
	const [postData, setPostData] = useState([]);
	const [selectedTab, setSelectedTab] = useState('preview');
	const [storeId, setStoreCid] = useState(showPanelObj?.courseId);

	useEffect( () => {
		if(intelligenceSurveyData && getUrlSegment()[0] === 'reports') {
			setSelectedTab('preview');
			let data = intelligenceSurveyData?.records[0]?.courses?.find(element => element?.courseId === storeId);
			let combinedSurveyQuestions = [];
			let combinedSurveyQuestions2 = [];
			
			combinedSurveyQuestions = showPanelObj?.questionType === 'prebehaviour'
			? [...(data?.preGrowthSurveyBehavior || [])]
			: [...(data?.preGrowthSurveyScenario || []), ...(data?.preGrowthSurveyKnowledge || [])];
			combinedSurveyQuestions2 = showPanelObj?.questionType === 'postbehaviour'
			? [...(data?.postGrowthSurveyBehavior || [])]
			: [...(data?.postGrowthSurveyScenario || []), ...(data?.postGrowthSurveyKnowledge || [])];

			setPreData(combinedSurveyQuestions);
			setPostData(combinedSurveyQuestions2);
			setSelectedTab('preview');
		}
	}, [intelligenceSurveyData]);

	const showCourseProgressPreview = (result, tab) => {
		setSelectedTab(tab);
		const isPre = result === 'pre';
		dispatch(
		  showPanel({
			type: "showGrowthSurveyPanel",
			growthData: isPre ? preData : postData,
			clickProgress: isPre,
			questionType: showPanelObj?.questionType,
		  })
		);
	  };

	const showCourseProgressReview = (result, tab) => {
		setSelectedTab(tab);
	
		const data = intelligenceSurveyData?.records[0]?.courses?.find(
			element => element?.courseId === storeId
		);
		const isPre = result === 'pre';
		const questionType = showPanelObj?.questionType;
	
		const growthSurveyCalc = isPre
			? questionType === 'prebehaviour'
				? data?.preBehaviourCalc
				: questionType === 'prescenerio'
				? data?.preScenarioCalc
				: data?.preKnowledgeCalc
			: questionType === 'postbehaviour'
			? data?.postBehaviourCalc
			: questionType === 'postscenerio'
			? data?.postScenarioCalc
			: data?.postKnowledgeCalc;
	
		dispatch(
			showPanel({
				type: "showGrowthSurveyPanel",
				growthSurveyCalc,
				isPost: !isPre,
				skillName: data?.skillName,
				stage: 'Review',
				clickProgress: isPre,
				questionType,
			})
		);
	};

	return (
		<div className="RightbarPannel p-0 rightpannelSticky">
			<div className="heading p-0 border-0">
			{getUrlSegment()[0] === 'studentprofile' ?
			<div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
				<h2
					data-toggle="collapse"
					class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
				>
					<span className="flexone">
						{" "}
						<img
							src={image.Powericon}
							className="mr-2"
							alt=""
						/>
						Growth Survey {showPanelObj?.stage}
					</span>
				</h2>
			</div>
				:
			<h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
					<div className="tabgrid w-100 m-0">
						<ul>
						<li class={`tabs flexone ${selectedTab === 'preview' ? "active-tabs" : ""} w-50`} onClick={() => showCourseProgressPreview(showPanelObj.clickProgress ? 'pre' : 'post', 'preview')}>
							<img src={image.preview} alt="" />
								Preview
							</li>
							<li class={`tabs flexone ${selectedTab === 'review' ? "active-tabs" : ""} w-50`} onClick={() => showCourseProgressReview(showPanelObj.clickProgress ? 'pre' : 'post', 'review')}>

							<img src={image.review_icon} alt="" />
								Review
							</li>
						</ul>
					</div>
				</h2>}
			</div>
			{((showPanelObj && showPanelObj?.growthData?.length > 0) ||  !showPanelObj?.growthData) ? (
				<>
				<div className="ScenecerelateddQuiz allcourselj growthsurveyPreview p-0">
					<div className="p-3">
						{showPanelObj && showPanelObj?.growthData?.length > 0 && (
							<div className="pb-5">
								{showPanelObj?.growthData?.map(
									(opt, ind) =>
										opt && (
											<div className="pb-3 d-flex flex-column" key={ind}>
												<span
													className="question d-flex"
												// onClick={() =>
												//   handlePreviewData(opt, ind)
												// }
												>
													<i
														className="fa fa-hand-o-right me-1 mt-2"
														aria-hidden="true"
													></i>
													{opt?.question}
												</span>
												{opt?.options.map(
													(val, key) =>
														val?.isUserSelected && (
															<span className="answer d-flex" key={key}>
																<span>
																	<img
																		src={image.answerIcon}
																		className="mr-2"
																	/>
																</span>
																{val?.question}
															</span>
														)
												)}
											</div>
										)
								)}
							</div>
						)}
						{!showPanelObj?.growthData && (
							<div className=" allcourselj">
								<div className="rightPanelIntroScreen">
									<p>
										You are off to a great start! You currently exhibit{" "}
										{showPanelObj?.growthSurveyCalc}% total understanding of {showPanelObj?.skillName}.
									</p>
									<p>Here is what your score means:</p>
									{/* <p></p> */}
									<p>
										Please remember, all growth is good growth so no matter your
										results, you should be proud!
									</p>
									{showPanelObj?.growthSurveyCalc >= 0 &&
										showPanelObj?.growthSurveyCalc < 26 &&
										!showPanelObj?.isPost && (
											<>
												<p>
													Your scorse indicate a beginning comfort level with this
													skill. You are most likely brand new to this skill or
													have very limited experience with it.
												</p>
												<p>
													It is completely understandable to score in this range
													because you may not have needed this skill yet in your
													life. The best news is - you are about to gain knowledge
													and practice so you will be prepared for when you do
													need it!
												</p>
											</>
										)}

									{/* {showReviewData?.growthAttempCalc > 25 &&
									showReviewData?.growthAttempCalc < 51 && ( */}
									{showPanelObj?.growthSurveyCalc > 25 &&
										showPanelObj?.growthSurveyCalc < 51 &&
										!showPanelObj?.isPost && (
											<>
												<p>
													Your scores suggest an advanced beginning comfort level
													with this skill. You might be aware of this skill is but
													have limited experience applying it.
												</p>
												<p>
													It is completely understandable to score in this range
													because you may not have needed to use this skill much
													in your life. The best news is - you are about to gain
													knowledge and practice so you will be prepared for when
													you do need it!
												</p>
											</>
										)}
									{/* 
								{showReviewData?.growthAttempCalc > 50 &&
									showReviewData?.growthAttempCalc < 76 && ( */}
									{showPanelObj?.growthSurveyCalc > 50 &&
										showPanelObj?.growthSurveyCalc < 76 &&
										!showPanelObj?.isPost && (
											<>
												<p>
													Your scores indicate an intermediatecomfort level with
													this skill. You most likely are familiar with this skill
													is and have some experience using it. You would benefit
													most from practicing with this skill and being
													introduced to advanced concepts.
												</p>
												<p>
													It is completely understandable to score in this range
													because you may not have needed to use this skill on a
													regular basis in your life. The best news is - you are
													about to gain knowledge and practice so you will be
													prepared for when you do need it!
												</p>
											</>
										)}
									{/* 
								{showReviewData?.growthAttempCalc > 75 && ( */}
									{showPanelObj?.growthSurveyCalc > 75 &&
										!showPanelObj?.isPost && (
											<>
												<p>
													Your scores suggest an advanced intermediate comfort
													level with this skill. You are familiar and comfortable
													with this skill is and have experience using it. You
													would benefit most from practicing with this skill and
													being introduced to more highly advanced concepts.
												</p>
												<p>
													It is completely understandable to score in this range
													because you may not have needed to use this skill
													repeatedly in your life. The best news is - you are
													about to gain knowledge and practice so you will be
													prepared for when you do need it!
												</p>
											</>
										)}

									{/*Post*/}

									{showPanelObj?.growthSurveyCalc >= 0 &&
										showPanelObj?.growthSurveyCalc < 26 &&
										showPanelObj?.isPost && (
											<>
												<p>
													Rising Star: Your scores are in the beginning learner
													making progress range! You may benefit from reviewing
													the course material again and discussing your knowledge
													check answers with your parents or trusted adult.
													Practice makes perfect, especially if you are newer to
													this skill or have very limited experience with it, so
													keep at it!
												</p>
											</>
										)}

									{showPanelObj?.growthSurveyCalc > 25 &&
										showPanelObj?.growthSurveyCalc < 51 &&
										showPanelObj?.isPost && (
											<>
												<p>
													Breakout Star: Your scores are in the advanced beginning
													learner making good progress range! It's clear you have
													learned some of the concepts taught in the course and
													you may increase your level even more by reviewing the
													course material again and discussing your knowledge
													check answers with your parents or trusted adult.
													Practice makes perfect, especially if you are newer to
													this skill or have limited experience with it, so keep
													at it!
												</p>
											</>
										)}

									{showPanelObj?.growthSurveyCalc > 50 &&
										showPanelObj?.growthSurveyCalc < 76 &&
										showPanelObj?.isPost && (
											<>
												<p>
													Shooting Star: Your scores are in the intermediate
													learner making great progress raange! It's clear you
													have learned most of the concepts taught in the course
													and applied these concepts in the practice. You may
													increase your level even more by reviewing the course
													material again and discussing your knowledge check
													answers with your parents or trusted adult. Practice
													makes perfect, especially if you are newer to this skill
													or have limited experience with it, so keep at it!
												</p>
											</>
										)}

									{showPanelObj?.growthSurveyCalc > 75 &&
										showPanelObj?.isPost && (
											<>
												<p>
													Superstar: Congratulations! Your scores are in the
													advanced learner range. It's clear you have learned most
													or all of the concepts taught in the course and applied
													these concepts in the practice. Continue practicing and
													share your ideas with your parents or trusted adult.
												</p>
											</>
										)}
									{!showPanelObj?.isPost && (
										<p className="pb-5">
											Please click the 'Lessons' tab to get started on the course
											and come back here to take the growth survey again after you
											complete the course to see how much you've grown!
										</p>
									)}

									{showPanelObj?.isPost && (
										<p className="pb-5">
											If you are looking to expand your knowledge and experience
											on this skill even more, you can always review the course
											material again, or explore related courses.
											{/* Please click the 'Lessons' tab to get started on the course and come back here to take the growth survey again after you complete the course to see how much you've grown! */}
										</p>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				</>
			) : (		
				<ShimmerCategoryList items={4} categoryStyle="STYLE_SIX" />
			)}
			<div class="learner_planordr_three m-0 p-0">
				<div class="form-group BDsubmitbutton d-flex m-0">
					<div class="buttonDistribotion justify-content-between  align-items-center">
						<button type="button" class="btn-blue btn-login d-block mb-5 ml-auto back_button" onClick={() => handleBackReview()}>
							<span><i class="fa-solid fa-arrow-left-long" ></i></span>Back</button>
					</div>
				</div>
			</div>
		</div>
	);
};
export default GrowthSurveyRightPanel;
