import React from "react";
import { getDimIcon, getSequnceSortww } from "../../../../utils/helper";
import { holisticViewData } from "../../../../redux/actions";
import { useDispatch } from "react-redux";

const DimTab = ({ toggleTab, toggleState, data }) => {
	const dispatch = useDispatch();
	return (
		<div className={`tabgrid w-100 m-0 ${data?.length === 3 ? "schoolstutabingthree" : data?.length === 4 ? "schoolstutabingFour" : ""} `}>
			<ul>
				{getSequnceSortww(data)?.map((value, key) => (
					<li
						key={key}
						className={
							toggleState?.key === value.id ? "tabs active-tabs" : "tabs"
						}
						// onClick={() => toggleTab(key, value)}
						onClick={() => {
							toggleTab(key, value);
							dispatch(holisticViewData(false));
						  }}
						>
						{getDimIcon(value)}
						{value.name}
					</li>
				))}
			</ul>
		</div>
	);
};

export default DimTab;
