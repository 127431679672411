import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { getProfileName, getSequnceSort, nameSorting } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import RoundProgress from "../controls/RoundProgress";
import { textTrim } from "../../utils/helper";
import ReactTooltip from "react-tooltip";
import { ShimmerTable } from "react-shimmer-effects";
import { Circle } from "rc-progress";

const PlanSummaryReport = ({ planSummaryData, showStudentProfile }) => {

	const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

	const toggleAccordion = (index) => {
		setOpenAccordionIndex(openAccordionIndex === index ? null : index);
	}

	return (
		<>
			{
				planSummaryData?.length > 0 ? (
					<div className="Course_report advancementSummaryReport">
						{getSequnceSort(planSummaryData[0]?.inProgress)?.map((data, index) => (
							<div className="AccordianTabl mb-3 mt-3">
								<h5>
									<div className="PlanTblCourse_Name flexone"> <span>C{data?.sequence}</span>
										<React.Fragment>
											{data?.courseName?.length > 38 ? (
												<ReactTooltip id={data?.courseName}>
													<p>{data?.courseName}</p>
												</ReactTooltip>
											) : (
												""
											)}
											<div data-for={data?.courseName} data-event-off="" data-tip>
												<strong>
													{textTrim(data?.courseName, 38)}
												</strong>
											</div>
										</React.Fragment>
										{/* {data?.courseName} */}
									</div>
									<div className="PlanTblModule_Name ml-3 pl-3 flex"><span className="modulecircleColor">M{data?.activitySequence}</span>
										<React.Fragment>
											{data?.activityName?.length > 36 ? (
												<ReactTooltip id={data?.activityName}>
													<p>{data?.activityName}</p>
												</ReactTooltip>
											) : (
												""
											)}
											<div data-for={data?.activityName} data-event-off="" data-tip>
												<strong>
													{textTrim(data?.activityName, 36)}
												</strong>
											</div>
										</React.Fragment>
										{/* {data?.activityName} */}
									</div>
									{/* <span className="inprogressbatchtbl">In Progress</span> */}
									<span
										className="inprogressbatcharrow pointer"
										// data-toggle="collapse"
										href={"#inprogresstable0" + index}
										// aria-expanded={index > 0 ? "false" : "true"}
										onClick={() => toggleAccordion(index)}
										aria-expanded={openAccordionIndex === index}
									>
										<i class="fa-light fa-chevron-down"></i></span>
								</h5>
								<table className={`panel-collapse collapse ${openAccordionIndex === index ? "show" : ""}`} id={"inprogresstable0" + index}>
									<tr>
										<th>#</th>
										<th>Student </th>
										<th>Lesson</th>
										<th>Knowledge Check</th>
										<th>Excercise</th>
									</tr>
									{
										nameSorting(data?.students, "lastName")?.map((student, key) => (
											<tr>
												<td>{key + 1}</td>
												<td>
													<div className="Student_nameid flexone pointer" onClick={() => showStudentProfile(student?.id, data?.courseId)}>
														<div className="StudentIng">

															{
																student?.imageUrl ?
																	<img src={student?.imageUrl} />
																	: getProfileName(student?.name)
															}

														</div>
														<div className="StudentNameed">
															{/* {student?.name} */}
															<React.Fragment>
																{student?.name?.length > 22 ? (
																	<ReactTooltip id={student?.name}>
																		<p>{student?.name}</p>
																	</ReactTooltip>
																) : (
																	""
																)}
																<div data-for={student?.name} data-event-off="" data-tip>
																	<strong>
																		{textTrim(student?.name, 22)}
																	</strong>
																</div>
															</React.Fragment>
														</div>
													</div>
												</td>
												<td>
													<div className="round_progres_">
														<div className="position-relative Coursecompprcent m-auto">
															<p className="">
																<span>
																	{" "}
																	<span className='PercentCompleted'>
																		{student?.lesson}
																		<span className='percenrSign'>%</span>
																	</span>
																	<Circle percent={student?.lesson} strokeWidth={12} strokeColor='#a6e761' />
																</span>
															</p>
														</div>
													</div>
												</td>
												<td>
													<div className="round_progres_ d-flex">

														<div className="position-relative Coursecompprcent m-auto">
															<p className="">
																<span>
																	{" "}
																	<span className='PercentCompleted'>
																		{student?.kc}
																		<span className='percenrSign'>%</span>
																	</span>
																	<Circle percent={student?.kc} strokeWidth={12} strokeColor='#6B84B9' />
																</span>
															</p>
														</div>
													</div>
												</td>
												<td>
													<div className="round_progres_">
														<div className="position-relative Coursecompprcent m-auto">
															<p className="">
																<span>
																	{" "}
																	<span className='PercentCompleted'>
																		{student?.ex}
																		<span className='percenrSign'>%</span>
																	</span>
																	<Circle percent={student?.ex} strokeWidth={12} strokeColor='#F76728' />
																</span>
															</p>
														</div>
													</div>
												</td>
											</tr>
										))
									}
								</table>
							</div>
						))}
					</div>

				) : (
					<ShimmerTable row={5} col={5} />
				)
			}
		</>
	);

}
export default PlanSummaryReport;
