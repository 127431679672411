import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import {
  getProfileName,
  nameSorting,
  getSequnceSort,
} from "../../utils/helper";
import RoundProgress from "../controls/RoundProgress";
import ReactTooltip from "react-tooltip";
import { textTrim } from "../../utils/helper";
import { ShimmerTable, ShimmerCategoryList } from "react-shimmer-effects";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import { Circle } from "rc-progress";
import { useDispatch, useSelector } from "react-redux";
import AddProfileImage from "./AddProfileImage";
import {
  getDimensionCourses,
  userWithDimensionLevelGrowthSurvey,
} from "../../redux/actions/APIs";
import StudentGrowthSurvey from "./StudentGrowthSurvey";
import { showPanel } from "../../redux/actions";
import StudentAdvancementSummary from "./StudentAdvancementSummary";

const ConnectedSkillsReport = ({
  skillProgressData,
  showStudentProfile,
  usertype,
  dimObj,
  studentId,
  backToHome,
  loggedInUser,
}) => {
  const { get_dimension_courses, userWithDimLevelGsData, defaultChildData } = useSelector(
    (state) => state.collections
  );

  //   console.log("usertype : ", studentId, dimObj?.id );
  //   console.log("usertype : loggedInUser", loggedInUser?.role?.name , usertype);
  const dispatch = useDispatch();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(0);

  const dimensionArr = [
    { dimName: "Social", color: "#e8b62f", dimImage: image.courselevel4 },
    {
      dimName: "Emotional",
      color: "#dd6363",
      dimImage: image.CollegeJourneyicon,
    },
    { dimName: "Intellectual", color: "#83b612", dimImage: image.courselevel3 },
  ];

  useEffect(() => {
    dispatch(getDimensionCourses());
    dispatch(userWithDimensionLevelGrowthSurvey());
  }, [studentId])

  useEffect(() => {
    dispatch(
      getDimensionCourses(
        loggedInUser?.role?.name === "STUDENT" ? loggedInUser.id : studentId,
        dimObj?.id
      )
    );
    let myStudentId = studentId ? studentId : defaultChildData?.id
    if (myStudentId && dimObj?.id) {
      dispatch(userWithDimensionLevelGrowthSurvey(myStudentId, dimObj?.id));
    }
  }, [studentId, dimObj.id]);

  const callDimensionCoursesApi = () => {
    dispatch(
      getDimensionCourses(
        loggedInUser?.role?.name === "STUDENT" ? loggedInUser.id : studentId,
        dimObj?.id
      )
    );
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  const handleBackReview = () => {
    dispatch(showPanel());
  };

  return (
    <>
      <div class="d-flex justify-content-between ps-3 pe-3 pt-3">
        <div class="backpageStrip p-0">
          <a
            href="javascript:void(0)"
            class="flexone"
            // onClick={() => {
            //   usertype === "teacher"
            //     ? backToHome()
            //     : showStudentProfile(studentId);
            // }}
            onClick={() => {
              if (usertype === "teacher" || loggedInUser?.role?.name === "STUDENT") {
                backToHome();
              } else {
                showStudentProfile(studentId);
                handleBackReview();
              }
            }}
          >
            <span class="">
              <i class="fa-solid fa-angle-left mr-1"></i>
            </span>
            Back
          </a>
        </div>
        <div class="disabledevent backpageStrip p-0">
          <a href="#">
            Next
            <span class="">
              <i class="fa-solid fa-angle-right ml-1"></i>
            </span>
          </a>
        </div>
      </div>

      {usertype === "student" && (
        <>
          {get_dimension_courses ? (
            <div>
              <div className="studentClsDetails Coursebanner">
                <div className="CoursebannerWrap flexone align-items-start m-0 shadow-none pb-0  p-0 ">
                  <div className="bannerleft studentProfileDiv">
                    <AddProfileImage
                      student={get_dimension_courses?.records[0]}
                      uploadProfileImage={callDimensionCoursesApi}
                    />
                  </div>
                  <div className="bannerright coursedetailbnr TeacherDashboard">
                    <div className="PageTitle">
                      <div className="reminderCourse m-0">
                        <div className="reminderCourseContent pb-2">
                          <div className="d-flex justify-content-between align-items-baseline">
                            <div className="d-flex align-items-baseline ps-1">
                              <h3 className="mb-2 flex">
                                {get_dimension_courses?.records[0]?.childName}
                                {/* {student?.childName} */}
                              </h3>
                              <p className="mb-3 ps-2 pt-2 emailAddress">
                                ({get_dimension_courses?.records[0]?.childEmail}
                                ){/* student@yopmail.com */}
                              </p>
                            </div>
                            {/* <div>
                                    <h4>
                                      <span className="pe-2">
                                        <i
                                          class="fa fa-calendar-o pe-2"
                                          aria-hidden="true"
                                        ></i>
                                        Next Session:
                                      </span>															
                                    </h4>
                                  </div> */}
                          </div>

                          <div className="d-flex justify-content-between ">
                            <div className="contributionCourselist">
                              <div>
                                <span className="d-flex align-items-center courseHeading">
                                  <h4 className="ps-1"> Courses:</h4>
                                </span>
                              </div>

                              {get_dimension_courses?.records[0].courses
                                ?.sort((a, b) => a.sequence - b.sequence)
                                ?.map((courses, index) => (
                                  <div className="flex justify-content-between ps-1 mb-2">
                                    <h5>
                                      <span className="flex">
                                        <div className="courseCircle me-2">
                                          C{courses?.sequence}
                                        </div>
                                        <React.Fragment>
                                          {courses?.name?.length > 35 ? (
                                            <ReactTooltip id={courses?.name}>
                                              <p>{courses?.name}</p>
                                            </ReactTooltip>
                                          ) : (
                                            ""
                                          )}
                                          <div
                                            className="courseName"
                                            data-for={courses?.name}
                                            data-event-off=""
                                            data-tip
                                          >
                                            {textTrim(courses?.name, 35)}
                                          </div>
                                        </React.Fragment>
                                        {/* {courses?.name} */}
                                        {/* Exploring You: A Journey to Self-Awareness */}
                                      </span>
                                    </h5>
                                    <p
                                      className="Coursecompprcent position-relative pointer"
                                    //  key={key}
                                    //  onClick={() => handleReports("connectedSkillReport", dim)}
                                    >
                                      <span>
                                        {" "}
                                        <span className="PercentCompleted">
                                          {courses.progress}
                                          <span className="percenrSign">%</span>
                                        </span>
                                        <Circle
                                          percent={courses.progress}
                                          strokeWidth={12}
                                          strokeColor="#3ec093"
                                        />
                                      </span>
                                    </p>
                                  </div>
                                ))}

                              {/* <div className="flex justify-content-between ps-1 mb-2">
                                      <h5>
                                        <span className="flex">
                                          <div className="courseCircle me-2">C2</div>
                                          Mastering Impulse Control
                                        </span>
                                      </h5>
                                      <p
                                        className="Coursecompprcent position-relative pointer"
                                      >
                                        <span>
                                          {" "}
                                          <span className="PercentCompleted">
                                            30
                                            <span className="percenrSign">%</span>
                                          </span>
                                          <Circle
                                            percent={get_dimension_courses?.records[0]?.overAllProgress}
                                            strokeWidth={12}
                                            strokeColor="#3ec093"
                                          />
                                        </span>
                                      </p>
                                    </div>
                                    <div className="flex justify-content-between ps-1">
                                      <h5>
                                        <span className="flex">
                                          <div className="courseCircle me-2">C3</div>
                                          Making Responsible Choices
                                        </span>
                                      </h5>
                                      <p
                                        className="Coursecompprcent position-relative pointer"
                                      >
                                        <span>
                                          {" "}
                                          <span className="PercentCompleted">
                                            30
                                            <span className="percenrSign">%</span>
                                          </span>
                                          <Circle
                                            percent={get_dimension_courses?.records[0]?.overAllProgress}
                                            strokeWidth={12}
                                            strokeColor="#3ec093"
                                          />
                                        </span>
                                      </p>
                                    </div> */}
                            </div>
                            <div>
                              <div className="SemicircleProgress text-center SocialcirclwDim">
                                {/* <SemiCircleProgressBar
                                        percentage={student?.overAllProgress}
                                        diameter={300}
                                        strokeWidth={25}
                                        showPercentValue
                                      /> */}
                                <p
                                  className="Coursecompprcent position-relative pointer"
                                //  key={key}
                                //  onClick={() => handleReports("connectedSkillReport", dim)}
                                >
                                  <span>
                                    {" "}
                                    <span className="PercentCompleted">
                                      {/* {dim?.progress} */}
                                      {/* 30 */}

                                      {
                                        get_dimension_courses?.records[0]
                                          .dimProgress
                                      }
                                      <span className="percenrSign">%</span>
                                    </span>
                                    <Circle
                                      percent={
                                        get_dimension_courses?.records[0]
                                          .dimProgress
                                      }
                                      strokeWidth={12}
                                      strokeColor={
                                        dimObj.name === "Social"
                                          ? "#e8b62f"
                                          : dimObj.name === "Emotional"
                                            ? "#dd6363"
                                            : dimObj.name === "Intellectual"
                                              ? "#83b612"
                                              : ""
                                      }
                                    />
                                  </span>
                                </p>
                                {/* <p>{dimObj?.name}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {get_dimension_courses?.records[0]?.skills?.length ? (
                <div className="indivisualprogressivecourse p-3  ">
                  {/* <h4 className="mt-3">
                                    <img src={image.mortarboard} alt="" className="mr-2" />
                                    In Progress Course
                                  </h4> */}
                  <div className="flexone justify-content-baseline">
                    {/* <div className="SemicircleProgress text-center">
                                      <SemiCircleProgressBar percentage={student?.courseProgress} diameter={240} strokeWidth={25} showPercentValue />
                                      <p>Progress</p>
                                    </div> */}
                    <div className="progressstyl justify-content-between w-100">
                      {get_dimension_courses?.records[0]?.skills?.map(
                        (skill) => (
                          <span className="CompltProgress" key={skill?.id}>
                            <div className="position-relative Coursecompprcent m-auto p-0">
                              <p className="">
                                <SemiCircleProgressBar
                                  percentage={skill?.progress}
                                  diameter={200}
                                  strokeWidth={20}
                                  stroke="#329fff"
                                  showPercentValue
                                />
                              </p>
                              <p
                                className="Aboutmedesc w-100"
                                data-for="proficiency"
                                data-event-off=""
                                data-tip
                              >
                                <p>{skill?.name}</p>
                                {/* <div className="Courseporogresspopup">
                                              <h4>Proficiency</h4>
                                              <p>
                                                Comprehension of the subject matter.
                                              </p>
                                            </div> */}
                              </p>
                            </div>
                          </span>
                        )
                      )}
                      <span className="CompltProgress">
                        <div className="position-relative Coursecompprcent m-auto p-0">
                          <p className="">
                            <SemiCircleProgressBar
                              percentage={
                                get_dimension_courses?.records[0]
                                  ?.skillConnectedPro
                              }
                              diameter={200}
                              strokeWidth={20}
                              stroke="#329fff"
                              showPercentValue
                            />
                          </p>
                          <p
                            className="Aboutmedesc w-100"
                            data-for="proficiency"
                            data-event-off=""
                            data-tip
                          >
                            <p>Skills Connection</p>
                            {/* <div className="Courseporogresspopup">
                                              <h4>Proficiency</h4>
                                              <p>
                                                Comprehension of the subject matter.
                                              </p>
                                            </div> */}
                          </p>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="indivisualprogressivecourse p-3  threeskillsconnections">
                  {/* <h4 className="mt-3">
                                    <img src={image.mortarboard} alt="" className="mr-2" />
                                    In Progress Course
                                  </h4> */}
                  <div className="flexone justify-content-baseline">
                    {/* <div className="SemicircleProgress text-center">
                                      <SemiCircleProgressBar percentage={student?.courseProgress} diameter={240} strokeWidth={25} showPercentValue />
                                      <p>Progress</p>
                                    </div> */}
                    <div className="progressstyl justify-content-between w-100">
                      {get_dimension_courses?.records[0]?.skill?.map(
                        (skill) => (
                          <span className="CompltProgress" key={skill?.id}>
                            <div className="position-relative Coursecompprcent m-auto p-0">
                              <p className="">
                                <SemiCircleProgressBar
                                  percentage={skill?.progress}
                                  diameter={250}
                                  strokeWidth={20}
                                  stroke="#329fff"
                                  showPercentValue
                                />
                              </p>
                              <p
                                className="Aboutmedesc w-100"
                                data-for="proficiency"
                                data-event-off=""
                                data-tip
                              >
                                <p> {skill?.name}</p>
                                {/* <div className="Courseporogresspopup">
                                                <h4>Proficiency</h4>
                                                <p>
                                                  Comprehension of the subject matter.
                                                </p>
                                              </div> */}
                              </p>
                            </div>
                          </span>
                        )
                      )}
                      <span className="CompltProgress">
                        <div className="position-relative Coursecompprcent m-auto p-0">
                          <p className="">
                            <SemiCircleProgressBar
                              percentage={
                                get_dimension_courses?.records[0]?.scProgress
                              }
                              diameter={250}
                              strokeWidth={20}
                              stroke="#329fff"
                              showPercentValue
                            />
                          </p>
                          <p
                            className="Aboutmedesc w-100"
                            data-for="proficiency"
                            data-event-off=""
                            data-tip
                          >
                            <p>Skills Connection</p>
                            {/* <div className="Courseporogresspopup">
                                              <h4>Proficiency</h4>
                                              <p>
                                                Comprehension of the subject matter.
                                              </p>
                                            </div> */}
                          </p>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              <div className="studentClsDetails Coursebanner mt-3">
                <StudentGrowthSurvey
                  intelligenceSurveyData={userWithDimLevelGsData}
                  reportType={"connectedSkillReport"}
                  showDimProgress={true}
                />
              </div>

              <div className="StuPlanSummy studentClsDetails mt-3 p-3">
                <StudentAdvancementSummary dashBoardplanSummaryData={get_dimension_courses?.records[0]?.courses}
                  reportType={"connectedSkillReport"}
                  showDimProgress={true}
                />

              </div>
            </div>
          ) : (
            <ShimmerCategoryList title items={6} categoryStyle="STYLE_SEVEN" />
          )}
        </>
      )}
      {usertype === "teacher" && (
        <>
          {skillProgressData ? (
            <div className="Course_report skillsReport ">
              <table className={` panel-collapse collapse show`}>
                <tr>
                  <th>#</th>
                  <th>
                    <span className="">
                      <img src={image.student_ProfileIcons} />
                    </span>
                    Students{" "}
                  </th>
                  <th>
                    <span className="">
                      <img src={image.overall_progress_icon} />
                    </span>
                    Aggregate
                  </th>
                  {skillProgressData[0]?.skill.map((skill, index) => (
                    <th key={skill.id}>
                      <img src={image.skillsIcon} alt={skill.name} />
                      {skill.name}
                    </th>
                  ))}
                  <th>
                    <img src={image.skillsConnectionIcon} />
                    Skills Connection{" "}
                  </th>
                </tr>

                {skillProgressData &&
                  nameSorting(skillProgressData, "studentLastName")?.map(
                    (student, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>
                          <div
                            className="Student_nameid flexone pointer"
                            onClick={() => showStudentProfile(student.id)}
                          >
                            <div className="StudentIng">
                              {student?.studentImageUrl ? (
                                <img src={student?.studentImageUrl} />
                              ) : (
                                getProfileName(student?.studentName)
                              )}
                            </div>
                            <div className="StudentNameed">
                              <React.Fragment>
                                {student?.studentName.length > 16 ? (
                                  <ReactTooltip id={student?.studentName}>
                                    <p>{student?.studentName}</p>
                                  </ReactTooltip>
                                ) : (
                                  ""
                                )}
                                <div
                                  data-for={student?.studentName}
                                  data-event-off=""
                                  data-tip
                                >
                                  <strong>
                                    {textTrim(student?.studentName, 16)}
                                  </strong>
                                </div>
                              </React.Fragment>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="round_progres_">
                            <div className="position-relative Coursecompprcent m-auto">
                              <p className="">
                                <RoundProgress
                                  data={student?.overAllProgress}
                                  type={true}
                                  className="m-1"
                                />
                              </p>
                            </div>
                          </div>
                        </td>
                        {student?.skill.map((skill) => (
                          <td key={skill.id}>
                            <div className="round_progres_">
                              <div className="position-relative Coursecompprcent m-auto">
                                <p className="">
                                  <RoundProgress
                                    data={skill.progress}
                                    type={true}
                                    className="m-1"
                                  />
                                </p>
                              </div>
                            </div>
                          </td>
                        ))}
                        <td>
                          <div className="round_progres_">
                            <div className="position-relative Coursecompprcent m-auto">
                              <p className="">
                                <RoundProgress
                                  data={student?.scProgress}
                                  type={true}
                                  className="m-1"
                                />
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  )}
              </table>
            </div>
          ) : (
            <ShimmerTable row={5} col={5} />
          )}
        </>
      )}
    </>
  );
};

export default ConnectedSkillsReport;
