/** @format */

export const FRIENDLIST = "FRIENDLIST";
export const MESSAGESRESPONSE = "MESSAGESRESPONSE";
export const GETFRIENDS = "GETFRIENDS";
export const SELECTEDFRIENDMSGS = "SELECTEDFRIENDMSGS";
export const GETGROUPS = "GETGROUPS";
export const GROUPMSGRESPONSE = "GROUPMSGRESPONSE";
export const ADDFRIEND = "ADDFRIEND";
export const SELECTEDGRPMSGS = "SELECTEDGRPMSGS";
export const ADDROOMPARTICIPANT = "ADDROOMPARTICIPANT";
