import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { getProfileName, getSequnceSort, nameSorting } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import RoundProgress from "../controls/RoundProgress";
import ReactTooltip from "react-tooltip";
import { textTrim } from "../../utils/helper";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { ShimmerTable } from "react-shimmer-effects";

const OverallProgressReport = ({ studentData, showStudentProfile }) => {
	return (
		<>
			{studentData?.length > 0 ? (
				<div className="Course_report">
					<table className="overallprogress_report_">

						<tr>
							<th>#</th>
							<th><span className=""><img src={image.student_ProfileIcons} /></span>Students </th>
							<th><span className=""><img src={image.overall_progress_icon} /></span>Aggregate</th>
							<th><img src={image.courselevel4} />Social</th>
							<th><img src={image.CollegeJourneyicon} />Emotional</th>
							<th><img src={image.courselevel3} />Intellectual </th>
						</tr>
						{nameSorting(studentData, "studentLastName")?.map((student, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>
									<div className="Student_nameid flexone pointer" onClick={() => showStudentProfile(student?.id)}>
										<div className="StudentIng">
											{
												student?.studentImageUrl ?
													<img src={student?.studentImageUrl} />
													: getProfileName(student?.studentName)
											}
										</div>
										<div className="StudentNameed">
											<React.Fragment>
												{student?.studentName.length > 16 ? (
													<ReactTooltip id={student?.studentName}>
														<p>{student?.studentName}</p>
													</ReactTooltip>
												) : (
													""
												)}
												<div data-for={student?.studentName} data-event-off="" data-tip>
													<strong>
														{textTrim(student?.studentName, 16)}
													</strong>
												</div>
											</React.Fragment>
											{/* {student?.studentName} */}
										</div>
									</div>
								</td>
								<td>
									<div className="round_progres_">
										<div className="position-relative Coursecompprcent m-auto">
											<p className="">
												<RoundProgress data={student?.overAllProgress} type={true} className="m-1" />
											</p>
										</div>
									</div>
								</td>
								{getSequnceSort(student?.dimensions)?.map((dim, key) => key < 3 && (
									<td>
										<div className="round_progres_">
											<div className="position-relative Coursecompprcent m-auto">
												<p className="">
													<RoundProgress data={dim?.progress} type={true} className="m-1" />
												</p>
											</div>
										</div>
									</td>
								))}
							</tr>
						))}

					</table>

				</div>
			) : (
				<ShimmerTable row={5} col={5} />
			)}
		</>
	);

}
export default OverallProgressReport;