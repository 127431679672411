import React, { useEffect, useState } from "react";
import GrowthProgress from "./GrowthProgressBar";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrowthSurveyData,
  submitGrowthSurveyData,
} from "../../../../redux/actions/APIs";
import * as image from "../../../../resources/images";
import {
  selectOptionData,
  setSurveyAss,
  showReviewData,
  // sceanIndex,
} from "../../../../redux/actions";
import { useParams } from "../../../../utils/Packages";
import { getSequnceSort } from "../../../../utils/helper";
import ConfirmationPopup from "./ConfirmationPopup";
import { Circle } from "rc-progress";
import { ShimmerCategoryList } from "react-shimmer-effects";
import SemiCircleProgressBar from "react-progressbar-semicircle";

const Survey = ({ courseData, selectTabs }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const {
    growthSurveyData,
    growthAttemptedResponse,
    surveyAssOpen,
    selectedPreData,
    socialActivityData,
    loggedInUser,
  } = useSelector((state) => state.collections);
  const [quizData, setQuizData] = useState({});
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [totalAttempt, setTotalAttempt] = useState(0);
  const [finalArray, setFinalArray] = useState([]);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [loader, setLoader] = useState(false);
  const [startPopup, setStartPopup] = useState(false);
  const [afterSubmitData, setAfterSubmitData] = useState(false);
  const [completedItems, setCompletedItems] = useState(false);
  const [showShimmer, setShowShimmer] = useState(false);
  const [number, setNumber] = useState([]);
  const [isClick, setIsClick] = useState(true);

  let courseId = courseData?.records && courseData?.records[0]?.courseId;

  const handleQuiz = () => {
    dispatch(setSurveyAss(true));
    setSelectedQuestionIndex(0);
    setTotalAttempt(0);
    setAfterSubmitData(true);
    dispatch(showReviewData(false));
  };

  useEffect(() => {
    setCompletedItems(socialActivityData?.records[0]?.completion === 100);
    dispatch(setSurveyAss(false));
    // setSelectedQuestionIndex(0);
    // setTotalAttempt(0);
    setIsClick(true);
    dispatch(
      getGrowthSurveyData(
        courseId,
        //"512619db-ac66-481b-8ad2-569421f595d5",
        "PREGS",
        params.id
      )
    );
  }, [params.id]);

  useEffect(() => {
    let combinedSurveyQuestions = [];
    if (growthSurveyData && growthSurveyData?.records[0]?.preProgress === 0 && socialActivityData?.records[0]?.courseComp < 28) {
      combinedSurveyQuestions = [
        ...growthSurveyData.records[0].preGrowthSurveyBehavior,
        ...growthSurveyData.records[0].preGrowthSurveyKnowledge,
        ...(growthSurveyData.records[0].preGrowthSurveyScenario || []),
      ];
    } else if (
      growthSurveyData?.records[0]?.postProgress === 0 &&
      (growthSurveyData?.records[0]?.preProgress > 0 || socialActivityData?.records[0]?.courseComp === 100)
    ) {
      combinedSurveyQuestions = [
        ...growthSurveyData.records[0]?.postGrowthSurveyBehavior,
        ...growthSurveyData.records[0]?.postGrowthSurveyKnowledge,
        ...(growthSurveyData.records[0]?.postGrowthSurveyScenario || []),
      ];
    }
    const sortedSurveyQuestions = combinedSurveyQuestions.sort(
      (a, b) => a.surveySequence - b.surveySequence
    );
    setQuizData(sortedSurveyQuestions);
    // dispatch(showReviewData(false));

    if (growthSurveyData?.records[0]?.preBehaviourCalc > 0 && isClick) {
      let obj = {
        flag: false,
        type: "preReviewBeforeFirst",
        isPost: false,
        skillName: courseData?.records[0]?.skillName,
        growthAttempCalc: growthSurveyData?.records[0]?.preBehaviourCalc,
      };
      setShowShimmer(false);
      dispatch(showReviewData(obj));
    }

    if (growthSurveyData?.records[0]?.postBehaviourCalc > 0 && isClick) {
      let obj = {
        flag: false,
        type: "preReviewAfterFirst",
        isPost: true,
        skillName: courseData?.records[0]?.skillName,
        growthAttempCalc: growthSurveyData?.records[0]?.postBehaviourCalc,
      };
      setShowShimmer(false);
      dispatch(showReviewData(obj));
    }
  }, [growthSurveyData, afterSubmitData]);

  const handleContinue = (nextQuestionIndex, type) => {
    let data = [...quizData];
    if (type === "Back") {
      window.scrollTo(0, 0);
      setIsOptionSelected(true);
      setSelectedQuestionIndex(nextQuestionIndex);
    } else {
      window.scrollTo(0, 0);
      setIsOptionSelected(false);
      setSelectedQuestionIndex(nextQuestionIndex);
    }
  };

  const selectOption = (event, currentQuestionIndex, selectedOptIndx, opt) => {
    // dispatch(selectOptionData(opt));
    let isSelected = 5;
    let data = [...quizData];

    if (!number.includes(currentQuestionIndex)) {
      setNumber([...number, currentQuestionIndex]);
    }
    if (currentQuestionIndex === number.length) {
      setTotalAttempt(totalAttempt + isSelected);
    }

    data[currentQuestionIndex].attempted = event.target.checked;

    data[currentQuestionIndex].options[selectedOptIndx].isUserSelected =
      event.target.checked;

    data[currentQuestionIndex].options.forEach((option, index) => {
      if (index !== selectedOptIndx) {
        option.isUserSelected = false;
      }
    });

    setQuizData(data);

    setIsOptionSelected(true);
    let selectedFinalAnswer = [...finalArray];
    let selectedData = {
      surveyId: data[currentQuestionIndex].surveyId,
      quesId: data[currentQuestionIndex].id,
      optId: data[currentQuestionIndex].options[selectedOptIndx].id,
      point: quizData[currentQuestionIndex].options[selectedOptIndx].points,
    };
    selectedFinalAnswer[currentQuestionIndex] = selectedData;
    setFinalArray(selectedFinalAnswer);

    let obj = {
      selectedOption: opt,
      finalArraySubmit: finalArray,
    };
    dispatch(selectOptionData(obj));
  };

  useEffect(() => {
    let opt = true;
    let obj = {
      selectedOption: opt,
      finalArraySubmit: finalArray,
    };
    dispatch(selectOptionData(obj));
  }, [finalArray]);

  useEffect(() => {
    if (growthAttemptedResponse?.success) {
      setShowShimmer(true);
      setLoader(false);
      setStartPopup(false);
      setSelectedQuestionIndex(0);
      setTotalAttempt(0);
      dispatch(
        getGrowthSurveyData(
          courseId, //"512619db-ac66-481b-8ad2-569421f595d5",
          growthSurveyData?.records[0]?.overAllCalculation === 0 ||
            growthSurveyData?.records[0]?.overAllCalculation === undefined
            ? "PREGS"
            : "POSTGS",
          params.id
        )
      );
      setLoader(false);
      setStartPopup(false);
      setIsClick(true);
      const timer = setTimeout(() => {
        setShowShimmer(false);
      }, 3000);
    }
  }, [growthAttemptedResponse]); //totalAttempt, selectedQuestionIndex]);

  const handleSubmit = () => {
    // dispatch(setSurveyAss(false));
    setStartPopup(true);
    setAfterSubmitData(true);
    setNumber([]);
  };

  useEffect(() => {
    if (selectedPreData) {
      setSelectedQuestionIndex(selectedPreData[0].ind);
      setQuizData(selectedPreData);
      setQuizData(quizData);
    }
  }, [selectedPreData]);

  useEffect(() => {
    if (quizData && quizData[selectedQuestionIndex]) {
      quizData[selectedQuestionIndex].attempted = true;
    }
  }, [selectedQuestionIndex, quizData]);

  const showReview = (reviewStr) => {
    setIsClick(false);
    let showReview =
      reviewStr === "preReviewBeforeFirst" ||
        reviewStr === "preReviewBeforeSecond" ||
        reviewStr === "preReviewAfterFirst" ||
        reviewStr === "preReviewAfterSecond"
        ? false
        : true;

    let growthSurveyCalc = 0;
    let isBool = false;
    if (
      reviewStr === "preReviewBeforeFirst" &&
      growthSurveyData?.records[0]?.preBehaviourCalc > 0
    ) {
      growthSurveyCalc = growthSurveyData?.records[0]?.preBehaviourCalc;
    } else if (
      reviewStr === "preReviewBeforeSecond" &&
      growthSurveyData?.records[0]?.preKnowledgeAndScenarioCalc > 0
    ) {
      growthSurveyCalc =
        growthSurveyData?.records[0]?.preKnowledgeAndScenarioCalc;
    } else if (
      reviewStr === "preReviewAfterFirst" &&
      growthSurveyData?.records[0]?.postBehaviourCalc > 0
    ) {
      growthSurveyCalc = growthSurveyData?.records[0]?.postBehaviourCalc;
      isBool = true;
    } else if (
      reviewStr === "preReviewAfterSecond" &&
      growthSurveyData?.records[0]?.postBehaviourCalc > 0
    ) {
      growthSurveyCalc =
        growthSurveyData?.records[0]?.postKnowledgeAndScenarioCalc;
      isBool = true;
    }

    let obj = {
      flag: showReview,
      type: reviewStr,
      skillName: courseData?.records[0]?.skillName,
      isPost: isBool,
      growthAttempCalc: growthSurveyCalc,
    };
    dispatch(showReviewData(obj));
  };

  return !growthSurveyData?.success || showShimmer ? (
    <div>
      <ShimmerCategoryList items={4} categoryStyle="STYLE_SIX" />
    </div>
  ) : (
    <React.Fragment>
      {surveyAssOpen ? (
        <>
          <div className="position-relative h-100 ">
            <div id="GrowthSurvey" className="growthsurveyAttemptQuestionMain">
              <div className="growthsurveyAttemptQuestionMainDiv">
                <div className="growthsurveyAttemptQuestioChildDiv">
                  <GrowthProgress
                    value={totalAttempt}
                    quizData={quizData}
                    handleContinue={handleContinue}
                    selectedQuestionIndex={selectedQuestionIndex}
                  />

                  <div className="pt-4 ps-5 pe-5 smqzQueslist">
                    <div className="topHeading">
                      <span>
                        <img src={image.questionIcon} className="mr-2" alt="" />
                        {quizData && quizData[selectedQuestionIndex]?.question}
                      </span>
                    </div>

                    <div className="pt-3 questionOptions">
                      {quizData &&
                        quizData[selectedQuestionIndex]?.options &&
                        (quizData[selectedQuestionIndex]?.options.some(
                          (opt) => opt.sequence !== null
                        )
                          ? getSequnceSort(
                            quizData[selectedQuestionIndex]?.options
                          )
                          : quizData[selectedQuestionIndex]?.options
                        )?.map((opt, optInd) => (
                          <label class="Selcheckbox" key={optInd}>
                            {opt?.question}
                            <input
                              type="radio"
                              id={opt.id}
                              value={opt?.id}
                              checked={opt?.isUserSelected}
                              disabled={
                                growthSurveyData?.records[0]?.growthSurvey
                                  ?.length > 0
                              }
                              onChange={(event) =>
                                selectOption(
                                  event,
                                  selectedQuestionIndex,
                                  optInd,
                                  quizData[selectedQuestionIndex]
                                )
                              }
                            ></input>
                            <span className="checkmark"></span>
                          </label>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="input-group full-Width-group basic_details_form pagebuttonStrip position-absolute bottom-0">
              <div className="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0">
                <button
                  type="button"
                  disabled={selectedQuestionIndex === 0 ? true : false}
                  className="btn-blue btn-login d-block mb-5  m-0 back_button"
                  onClick={() =>
                    handleContinue(selectedQuestionIndex - 1, "Back")
                  }
                >
                  <i class="fa-solid fa-arrow-left mr-2"></i>Back
                </button>
                {quizData && quizData.length > 0 && (
                  <div className="next_button buttonDistribotion">
                    {selectedQuestionIndex + 1 < quizData?.length && (
                      <div
                        // className={`${
                        //   !quizData[selectedQuestionIndex]?.attempted ||
                        //   !quizData[selectedQuestionIndex].options.some(
                        //     (opt) => opt.isUserSelected
                        //   ) ||
                        //   selectedQuestionIndex + 1 === quizData.length
                        //     ? "anchor-disabled"
                        //     : ""
                        // }`}
                        style={{
                          cursor:
                            !quizData[selectedQuestionIndex]?.attempted ||
                              !quizData[selectedQuestionIndex].options.some(
                                (opt) => opt.isUserSelected
                              ) ||
                              selectedQuestionIndex + 1 === quizData.length
                              ? "not-allowed"
                              : "auto",
                        }}
                      >
                        <span
                          className={`${(!quizData[selectedQuestionIndex].options.some(
                            (opt) => opt.isUserSelected
                          ) ||
                            selectedQuestionIndex + 1 === quizData.length) &&
                            "pe-none disabled"
                            }`}
                          onClick={() => {
                            handleContinue(selectedQuestionIndex + 1, "Next");
                          }}
                        >
                          <a
                            href="#"
                            className={`btn-blue btn-login d-block  w-auto text-white ${(!quizData[selectedQuestionIndex]?.attempted ||
                              selectedQuestionIndex + 1 === quizData?.length) &&
                              "pe-none disabled"
                              }`}
                          >
                            Next
                            <i className="fa-solid fa-arrow-right m-0 ml-2"></i>
                          </a>
                        </span>
                      </div>
                    )}
                    {selectedQuestionIndex + 1 === quizData.length &&
                      !growthSurveyData?.records[0]?.growthSurvey?.length > 0 && loggedInUser?.role?.name !== "PROVIDER"  &&(
                        <div className="p-0">
                          <button
                            type="submit"
                            className="btn-blue btn-login d-block w-auto ml-auto"
                            // disabled={!quizData[selectedQuestionIndex]?.attempted}
                            disabled={
                              !quizData[selectedQuestionIndex]?.options.some(
                                (opt) => opt.isUserSelected
                              )
                            }
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            <i className="fa-solid fa-paper-plane mr-2"></i>
                            Submit
                          </button>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="growthsurveyOutputMain" id="GrowthSurvey">
          <div className="growthsurveyOutputMainDiv">
            {/* <div className="p-5 pt-3 growthsurveyOutputChildDiv"> */}
            <div className={`p-5 pt-3 ${growthSurveyData?.records[0]?.preProgress > 0 && growthSurveyData?.records[0]?.postProgress > 0 ? 'growthsurveyOutputCompleted' : 'growthsurveyOutputChildDiv'}`}>
              <div className="d-flex justify-content-end">
                {/* {growthSurveyData?.records[0]?.postProgress === 0 && (
                  <button
                    className="btn-blue btn-login d-block w-auto"
                    onClick={() => handleQuiz()}
                  >
                    <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                  </button>
                )} */}

                {
                  ((growthSurveyData?.records[0]?.preProgress === 0) &&
                    growthSurveyData?.records[0]?.postProgress === 0 && (socialActivityData?.records[0]?.courseComp < 28
                      || socialActivityData?.records[0]?.courseComp === 100)) || socialActivityData?.records[0].courseIsCompleted
                    && growthSurveyData?.records[0]?.postProgress === 0 ? (
                    <button
                      className="btn-blue btn-login d-block float-right w-auto"
                      onClick={() => handleQuiz()}
                    >
                      <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                    </button>
                  ) : (
                    growthSurveyData?.records[0]?.postProgress !== 100 &&
                    (
                      <button
                        className="btn-blue btn-login d-block float-right w-auto"
                        onClick={() => selectTabs("Series")}
                      >
                        <i className="fa-solid fa-paper-plane mr-2"></i>Go to
                        Lessons
                      </button>
                    )
                  )}
              </div>
              <h2 className="text-center starthereHeading">
                {growthSurveyData?.records[0]?.preBehaviourCalc > 0 || growthSurveyData?.records[0]?.postBehaviourCalc
                  ? ""
                  : "START HERE"}
              </h2>

              {/* className={
                        growthSurveyData?.records[0]?.postBehaviourCalc > 0
                          ? ""
                          : "disabledDivChild "
                      } */}
              <div className="d-flex justify-content-between growthsurveyOutput">
                {/* <div className="pt-2"> */}
                <div className={`${growthSurveyData?.records[0]?.preBehaviourCalc > 0 && growthSurveyData?.records[0]?.postBehaviourCalc > 0 ? "pt-0" : "pt-2"}`}>
                  <h3 className="pb-2 text-center">
                    {growthSurveyData?.records[0]?.preBehaviourCalc > 0 || growthSurveyData?.records[0]?.postBehaviourCalc
                      ? ""
                      : "Before"}
                  </h3>
                  {/* <div className="disabledDiv"> */}
                  <div className={`${growthSurveyData?.records[0]?.preProgress > 0 && growthSurveyData?.records[0]?.postProgress > 0 ? 'CompletedDisabledDiv' : 'disabledDiv'}`}>
                    <div
                      className={
                        growthSurveyData?.records[0]?.preBehaviourCalc > 0
                          ? ""
                          : "disabledDivChild anchor-disabled"
                      }
                    ></div>
                    <p className="Coursecompprcent position-relative">
                      <span>
                        {" "}
                        <span className="PercentCompleted">
                          {growthSurveyData?.records[0]?.preBehaviourCalc > 0
                            ? growthSurveyData?.records[0]?.preBehaviourCalc
                            : "0"}
                          <span className="percenrSign">%</span>
                        </span>
                        <Circle
                          percent={
                            growthSurveyData?.records[0]?.preBehaviourCalc
                          }
                          strokeWidth={12}
                          strokeColor="#329fff"
                        />
                      </span>

                      <hr />
                      <div className="text-center d-flex align-items-center">
                        <a href="#" onClick={() => showReview("preBehaviour")}>
                          Preview
                        </a>
                        <div className="verticalLine"></div>
                        <a
                          href="#"
                          onClick={() => showReview("preReviewBeforeFirst")}
                        >
                          Review
                        </a>
                      </div>
                    </p>


                    <div className="indivisectionprog flex pb-1">
                      {/* <div className="graphbatteryprogress">
                        <span className="secProgTxt">
                          {
                            growthSurveyData?.records[0].preKnowledgeAndScenarioCalc
                          }
                          <span className="secProgTxtSmallText">%</span> */}
                      {/* <span className="secProgTxtSmallText">/80</span> */}
                      {/* </span>
                        <span
                          className="batterycells"
                          style={{
                            // height:
                            //   growthSurveyData?.records[0]
                            //     .preKnowledgeAndScenarioCalc + "%",
                            height: `${(growthSurveyData?.records[0]
                              .preKnowledgeAndScenarioCalc /
                              80) *
                              100
                              }%`,
                          }}
                        ></span>
                      </div> */}
                      {/* alfaiz */}

                      <div className="mt-5  text-center">
                        <SemiCircleProgressBar percentage={growthSurveyData?.records[0].preKnowledgeAndScenarioCalc} strokeWidth={30} showPercentValue diameter={220} stroke="#329fff" />

                        <hr className="secondHrLine" />
                        <div className="ps-4 ms-2 text-center d-flex align-items-center">
                          <a
                            href="#"
                            onClick={() => showReview("preKnowledgeAndScenerio")}
                          >
                            Preview
                          </a>
                          <div className="verticalLine"></div>
                          <a
                            href="#"
                            onClick={() => showReview("preReviewBeforeSecond")}
                          >
                            Review
                          </a>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
                <div className={`d-flex flex-column middleContentMainDiv ${growthSurveyData?.records[0]?.preBehaviourCalc > 0 && growthSurveyData?.records[0]?.postBehaviourCalc ? "middleContentMainDivCompleted" : ""}`}>
                  {/* <div className="text-center content1"> */}
                  <div
                    className={`text-center ${growthSurveyData?.records[0]?.preProgress > 0
                      ? "moveTopContent1"
                      : "content1"
                      }`}
                  >
                    <h3 className="pb-1">How I Changed</h3>
                    <h5 className="pb-3">Results from reflection questions.</h5>
                    <h4 className="pt-5">After taking the course.</h4>
                    <span>
                      <img src={image.growthSurveyArrowIcon} alt="" />
                    </span>
                  </div>

                  {/* <div className="text-center content2"> */}
                  <div
                    className={`text-center ${growthSurveyData?.records[0]?.preProgress > 0
                      ? "moveTopContent2"
                      : "content2"
                      }`}
                  >
                    <h3 className="pb-1">What I Learned</h3>
                    <h5 className="pb-3">Results from scenario questions.</h5>
                    <h4 className="pt-5">After taking the course.</h4>
                    <span>
                      <img src={image.growthSurveyArrowIcon} alt="" />
                    </span>
                  </div>
                </div>

                {/* <div className="pt-2"> */}
                <div className={`${growthSurveyData?.records[0]?.preBehaviourCalc > 0 && growthSurveyData?.records[0]?.postBehaviourCalc ? "pt-0" : "pt-2"}`}>
                  <h3 className="pb-2 text-center">
                    {growthSurveyData?.records[0]?.preBehaviourCalc > 0 || growthSurveyData?.records[0]?.postBehaviourCalc
                      ? ""
                      : "After"}
                  </h3>
                  {/* <div className="disabledDiv"> */}
                  <div className={`${growthSurveyData?.records[0]?.preProgress > 0 && growthSurveyData?.records[0]?.postProgress > 0 ? 'CompletedDisabledDiv' : 'disabledDiv'}`}>
                    <div
                      className={
                        growthSurveyData?.records[0]?.postBehaviourCalc > 0
                          ? ""
                          : "disabledDivChild anchor-disabled"
                      }
                    ></div>
                    <p className="Coursecompprcent position-relative">
                      <span>
                        {" "}
                        <span className="PercentCompleted">
                          {growthSurveyData?.records[0]?.postBehaviourCalc > 0
                            ? growthSurveyData?.records[0]?.postBehaviourCalc
                            : "0"}
                          <span className="percenrSign">%</span>
                        </span>
                        <Circle
                          percent={
                            growthSurveyData?.records[0]?.postBehaviourCalc
                          }
                          strokeWidth={12}
                          strokeColor="#329fff"
                        />
                      </span>

                      <hr />
                      <div className="text-center d-flex align-items-center">
                        <a href="#" onClick={() => showReview("postBehaviour")}>
                          Preview
                        </a>
                        <div className="verticalLine"></div>
                        <a
                          href="#"
                          onClick={() => showReview("preReviewAfterFirst")}
                        >
                          Review
                        </a>
                      </div>
                    </p>


                    <div className="indivisectionprog flex pb-1">
                      {/* <div className="graphbatteryprogress">
                        <span className="secProgTxt">
                          {
                            growthSurveyData?.records[0]
                              .postKnowledgeAndScenarioCalc
                          }
                          <span className="secProgTxtSmallText">%</span> */}
                      {/* <span className="secProgTxtSmallText">/80</span> */}
                      {/* </span>
                        <span
                          className="batterycells"
                          style={{ */}
                      {/* // height:
                            //   growthSurveyData?.records[0]
                            //     .postKnowledgeAndScenarioCalc + "%",
                            height: `${(growthSurveyData?.records[0]
                              .postKnowledgeAndScenarioCalc /
                              80) *
                              100
                              }%`,
                          }}
                        ></span>
                      </div> */}
                      {/* alfaiz */}

                      <div className="mt-5  text-center">
                        <SemiCircleProgressBar percentage={growthSurveyData?.records[0].postKnowledgeAndScenarioCalc} strokeWidth={30} showPercentValue diameter={220}  stroke="#329fff"/>
                        <hr className="secondHrLine" />
                        <div className="ps-4 ms-2 text-center d-flex align-items-center">
                          <a
                            href="#"
                            onClick={() => showReview("postKnowledgeAndScenerio")}
                          >
                            Preview
                          </a>
                          <div className="verticalLine"></div>
                          <a
                            href="#"
                            onClick={() => showReview("preReviewAfterSecond")}
                          >
                            Review
                          </a>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="input-group full-Width-group basic_details_form pagebuttonStrip emptyDivHeigth"> */}
          <div className={`LeftPanelFooter input-group full-Width-group basic_details_form pagebuttonStrip ${growthSurveyData?.records[0]?.postProgress > 0 ? 'emptyDivHeigth' : ''}`}>
            <div className="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0 ">
              <div>
                {/* {growthSurveyData?.records[0]?.preProgress === 0 && (
                  <a
                    href="#"
                    onClick={() => selectTabs("Series")}
                    className="gotoLessonText"
                  >
                    Go to Lessons
                  </a>
                )} */}
              </div>

              <div>
                {
                  // (growthSurveyData?.records[0]?.preProgress === 0 ) &&
                  // growthSurveyData?.records[0]?.postProgress === 0 && 
                  // (socialActivityData?.records[0]?.courseComp < 28 
                  //   || socialActivityData?.records[0]?.courseComp === 100) 
                  ((growthSurveyData?.records[0]?.preProgress === 0) &&
                    growthSurveyData?.records[0]?.postProgress === 0 && (socialActivityData?.records[0]?.courseComp < 28
                      || socialActivityData?.records[0]?.courseComp === 100)) || socialActivityData?.records[0].courseIsCompleted
                    && growthSurveyData?.records[0]?.postProgress === 0
                    ? (
                      <button
                        className="btn-blue btn-login d-block float-right w-auto"
                        onClick={() => handleQuiz()}
                      >
                        <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                      </button>
                    ) : (
                      growthSurveyData?.records[0]?.postProgress !== 100 &&
                      (
                        <button
                          className="btn-blue btn-login d-block float-right w-auto"
                          onClick={() => selectTabs("Series")}
                        >
                          <i className="fa-solid fa-paper-plane mr-2"></i>Go to
                          Lessons
                        </button>
                      )
                    )}
              </div>
            </div>
          </div>
        </div>
      )}

      {startPopup && (
        <ConfirmationPopup
          setStartPopup={setStartPopup}
          courseId={courseId}
          assSurveyId={socialActivityData?.records[0]?.courseIsCompleted ? growthSurveyData?.records[0].postAssementSurveyId : growthSurveyData?.records[0].preAssementSurveyId}
          finalArray={finalArray}
        />
      )}
    </React.Fragment>
  );
};
export default Survey;
