import React, { useState } from "react";
import Home from "../Home";
import * as image from "../../resources/images";
import RoundProgress from "../controls/RoundProgress";
import { Circle } from "rc-progress";
import ReactTooltip from "react-tooltip";
const UiTestingPage = ({ color, value, data }) => {

    return (
        <div>
            <Home>
                <div className="d-flex flex-wrap SpecialLeftpanel w-100">
                    <div className="d-flex w-100 align-items-start overflow-visible">

                        {/* {/ Start Growth Survey 1 Screen /} */}
                        {/* <div className="LeftbarPannel p-0" id="GrowthSurvey">
                            <div className='CourseCardWrapper fullHeight100 '>
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.courselevel4} className='mr-2' alt="" />
                                            Let's create a learning plan for Adam
                                        </span>

                                    </h2>
                                </div>

                                <div className="p-5">
                                    <div className="topHeading d-flex align-items-center">
                                            <span className="pe-2">
                                                <i class="fa fa-lightbulb-o" aria-hidden="true"></i>
                                            </span>
                                            <h3>
                                                Introduction
                                            </h3>
                                    </div>
                                    <div className="introContent pt-4">
                                        <p>
                                            The growth survey is an easy way for you to see how much you gain from each course. You will
                                            take the survey now before you begin lessons to see your starting placement and then again after
                                            the course to see your final placement. Then you will compare them side by side!

                                        </p>

                                        <p>All growth is good growth so no matter where you place on the chart, you should be proud! This is not a test,
                                            there are no wrong answers, answer honestly to how the questions relate to your life.
                                        </p>


                                        <p>The growth survey only takes a few minutes, click the button below to get started.</p>
                                    </div>

                                    <div className="pt-5 mt-5 beginAssessmentbtn">
                                        <button className="btn-blue btn-login d-block  m-auto w-auto">
                                            <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Growth Survey 1 Screen /} */}

                        {/* {/ Start Growth Survey 2 Screen /} */}
                        {/* <div className="LeftbarPannel p-0" id="GrowthSurvey">
                            <div className='CourseCardWrapper fullHeight100 '>
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.courselevel4} className='mr-2' alt="" />
                                            Let's create a learning plan for Adam
                                        </span>

                                    </h2>
                                </div>
                                <div className="quizblok">
                                    <div className="backpageStrip p-0">
                                        <a href="#" className="flexone">
                                            <span className="">
                                                <i class="fa-solid fa-angle-left mr-1"></i>
                                            </span>
                                            Back
                                        </a>
                                    </div>
                                    <span className="Progresslistitem m-0">
                                        <span
                                            className={`diemensionalProgress progressbar1`}
                                            style={{
                                                maxWidth: `${0}%`,
                                                background: color ? color : "#20c997",
                                            }}
                                        ></span>
                                    </span>
                                    <span className='procompt'>
                                        1 of 12
                                    </span>
                                    <div className="backpageStrip p-0">
                                        <a href="#"
                                        >Next
                                            <span class="">
                                                <i class="fa-solid fa-angle-right ml-1"></i>
                                            </span></a>
                                    </div>
                                </div>
                                <div className="pt-4 ps-5 pe-5 smqzQueslist">
                                    <div className="topHeading ps-2">
                                        <span><img src={image.questionIcon} className='mr-2' alt="" />
                                            I am not easily influenced by other people.
                                        </span>
                                    </div>

                                    <div className="pt-3">
                                        <label class="Selcheckbox">
                                            Not at all like me
                                            <input type="radio" id="#" value="0" />
                                            <span class="checkmark"></span>
                                        </label>

                                        <label class="Selcheckbox">
                                            Kinda like me
                                            <input type="radio" id="#" value="0" />
                                            <span class="checkmark"></span>
                                        </label>

                                        <label class="Selcheckbox">
                                            This is like me
                                            <input type="radio" id="#" value="0" />
                                            <span class="checkmark"></span>
                                        </label>

                                        <label class="Selcheckbox">
                                            A lot like me
                                            <input type="radio" id="#" value="0" />
                                            <span class="checkmark"></span>
                                        </label>

                                        <label class="Selcheckbox">
                                            Kinda like me
                                            <input type="radio" id="#" value="0" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="next_button p-10">
                                        <div className="buttonDistribotion ">
                                            <button
                                                type="submit"
                                                data-toggle="modal"
                                                data-target="#schoolactivity75"
                                                className="btn-blue btn-login d-block mb-5 w-auto"
                                            >
                                                Next<i className="fa-solid fa-arrow-right m-0 ml-2"></i>

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Growth Survey 2 Screen /} */}

                        {/* {/ Start Growth Survey 3 Screen /} */}
                        {/* <div className="LeftbarPannel p-0" id="GrowthSurvey">
                            <div className='CourseCardWrapper fullHeight100 '>
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.courselevel4} className='mr-2' alt="" />
                                            Let's create a learning plan for Adam
                                        </span>

                                    </h2>
                                </div>
                                <div className="quizblok">
                                    <div className="backpageStrip p-0">
                                        <a href="#" className="flexone">
                                            <span className="">
                                                <i class="fa-solid fa-angle-left mr-1"></i>
                                            </span>
                                            Back
                                        </a>
                                    </div>
                                    <span className="Progresslistitem m-0">
                                        <span
                                            className={`diemensionalProgress progressbar1`}
                                            style={{
                                                maxWidth: `${0}%`,
                                                background: color ? color : "#20c997",
                                            }}
                                        ></span>
                                        <span>
                                            1 of 13
                                        </span>
                                    </span>
                                    <div className="backpageStrip p-0">
                                        <a href="#"
                                        >Next
                                            <span class="">
                                                <i class="fa-solid fa-angle-right ml-1"></i>
                                            </span></a>
                                    </div>
                                </div>
                                <div className="growthsurveytextAreatypeQuestion pt-4 ps-5 pe-5">
                                    <div className="topHeading">
                                        <span>
                                            <img src={image.questionIcon} className='mr-2' alt="" />
                                            I am not easily influenced by other people.
                                        </span>
                                      
                                    </div>
                                    <div class="textareabox">
                                        <textarea name="" id="" placeholder="Write Your Answer...">
                                        </textarea>
                                    </div>

                                    <div className="next_button p-10">
                                        <div className="buttonDistribotion ">
                                            <button
                                                type="submit"
                                                data-toggle="modal"
                                                data-target="#schoolactivity75"
                                                className="btn-blue btn-login d-block mb-5 w-auto"
                                            >
                                                Next<i className="fa-solid fa-arrow-right m-0 ml-2"></i>

                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Growth Survey 3 Screen /} */}


                        {/* {/ Start Growth Survey 4 Screen for Output Screen example1 /} */}
                        {/* <div className="LeftbarPannel p-0" id="GrowthSurvey">
                            <div className='CourseCardWrapper fullHeight100 '>
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.courselevel4} className='mr-2' alt="" />
                                            Let's create a learning plan for Adam
                                        </span>
                                    </h2>
                                </div>

                                <div className="p-5 pt-3">
                                    <div className="d-flex justify-content-end">
                                        <button className="btn-blue btn-login d-block w-auto">
                                            <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-between growthsurveyOutput">
                                        <div className="pt-5 mt-2">
                                            <h3 className="pb-2 text-center">Before</h3>
                                            <div className="disabledDiv">
                                                <div className='allitemsNH'>
                                                    <div className={`NHPreogressouter`}>
                                                        <div
                                                            className={`NHProgressinner
                                                                ? "flextwo"
                                                                : ""
                                                                }`}
                                                            style={{
                                                                width: `calc(1% + 12.5px)`,
                                                                height: `calc(1% + 12.5px)`,
                                                            }}>
                                                            <p>
                                                                <span>1</span>
                                                                <span className='percentage_sign'>%</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="text-center">
                                                        <a href="#">Review</a>
                                                    </div>

                                                    <div className="indivisectionprog flex pb-1">
                                                        <div className="graphbatteryprogress">
                                                            <span className="secProgTxt">
                                                                10
                                                            </span>
                                                            <span
                                                                className="batterycells"
                                                                style={{
                                                                    height: 50 + "%",
                                                                }}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="text-center">
                                                        <a href="#">Review</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column middleContentMainDiv">  
                                            <div className="text-center content1">
                                                <h3 className="pb-5">How I Changed</h3>
                                                <h4 className="pt-3">After taking the course.</h4>
                                                <span><img src={image.growthSurveyArrowIcon}  alt=""/></span>
                                            </div>

                                            <div className="text-center content2">
                                                <h3 className="pb-5">What I Learned</h3>
                                                <h4 className="pt-3">After taking the course.</h4>
                                                <span><img src={image.growthSurveyArrowIcon}  alt=""/></span>
                                            </div> 
                                            
                                        </div>

                                        <div className="pt-5 mt-2">
                                            <h3 className="pb-2 text-center">After</h3>
                                            <div className="disabledDiv">
                                            <div className='allitemsNH'>
                                                <div className={`NHPreogressouter`}>
                                                    <div
                                                        className={`NHProgressinner
                                                            ? "flextwo"
                                                            : ""
                                                            }`}
                                                        style={{
                                                            width: `calc(30% + 12.5px)`,
                                                            height: `calc(30% + 12.5px)`,
                                                        }}>
                                                        <p>
                                                            <span>30</span>
                                                            <span className='percentage_sign'>%</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="text-center">
                                                    <a href="#">Review</a>
                                                </div>

                                                <div className="indivisectionprog flex pb-1">
                                                    <div className="graphbatteryprogress">
                                                        <span className="secProgTxt">
                                                            70
                                                        </span>
                                                        <span
                                                            className="batterycells"
                                                            style={{
                                                                height: 70 + "%",
                                                            }}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="text-center">
                                                    <a href="#">Review</a>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-5 beginAssessmentbtn">
                                        <button className="btn-blue btn-login d-block  m-auto w-auto">
                                            <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Growth Survey 4 Screen for Output Screen example1 /} */}

                        {/* {/ Start Growth Survey 4 Screen for Output Screen example2 /} */}
                        {/* <div className="LeftbarPannel p-0" id="GrowthSurvey">
                            <div className='CourseCardWrapper fullHeight100 '>
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.courselevel4} className='mr-2' alt="" />
                                            Let's create a learning plan for Adam
                                        </span>
                                    </h2>
                                </div>

                                <div className="p-5 pt-3">
                                    <div className="d-flex justify-content-end">
                                        <button className="btn-blue btn-login d-block w-auto">
                                            <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-between growthsurveyOutput">
                                        <div className="pt-5">
                                            <h3 className="pb-4 text-center">Before</h3>
                                            <div className="disabledDiv">
                                                <div className="disabledDivChild"></div>
                                                <p className='Coursecompprcent position-relative'>
                                                    <span>
                                                        {" "}
                                                        <span className='PercentCompleted'>
                                                            {50}
                                                            <span className='percenrSign'>%</span>
                                                        </span>
                                                        <Circle percent={50} strokeWidth={12} strokeColor='#329fff' />
                                                    </span>
                                                </p>
                                                <hr />
                                                <div className="text-center">
                                                    <a href="#">Review</a>
                                                </div>

                                                <div className="indivisectionprog flex pb-1">
                                                    <div className="graphbatteryprogress">
                                                        <span className="secProgTxt">
                                                            10
                                                        </span>
                                                        <span
                                                            className="batterycells"
                                                            style={{
                                                                height: 50 + "%",
                                                            }}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="text-center">
                                                    <a href="#">Review</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex flex-column middleContentMainDiv">
                                            <div className="text-center content1">
                                                <h3 className="pb-5">How I Changed</h3>
                                                <h4 className="pt-3">After taking the course.</h4>
                                                <span><img src={image.growthSurveyArrowIcon} alt="" /></span>
                                            </div>

                                            <div className="text-center content2">
                                                <h3 className="pb-5">What I Learned</h3>
                                                <h4 className="pt-3">After taking the course.</h4>
                                                <span><img src={image.growthSurveyArrowIcon} alt="" /></span>
                                            </div>
                                        </div>

                                        <div className="pt-5">
                                            <h3 className="pb-4 text-center">After</h3>
                                            <div className="disabledDiv">
                                                <div className="disabledDivChild"></div>
                                                <p className='Coursecompprcent position-relative'>
                                                    <span>
                                                        {" "}
                                                        <span className='PercentCompleted'>
                                                            {70}
                                                            <span className='percenrSign'>%</span>
                                                        </span>
                                                        <Circle percent={70} strokeWidth={12} strokeColor='#329fff' />
                                                    </span>
                                                </p>
                                                <hr />
                                                <div className="text-center">
                                                    <a href="#">Review</a>
                                                </div>

                                                <div className="indivisectionprog flex pb-1">
                                                    <div className="graphbatteryprogress">
                                                        <span className="secProgTxt">
                                                            70
                                                        </span>
                                                        <span
                                                            className="batterycells"
                                                            style={{
                                                                height: 70 + "%",
                                                            }}
                                                        ></span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="text-center">
                                                    <a href="#">Review</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="pt-5 d-flex justify-content-between align-items-center">
                                        <div className="backpageStrip p-0">
                                            <a href="#">
                                                <span className="">
                                                    <i class="fa-solid fa-angle-left mr-1"></i>
                                                </span>
                                                Go to Lessons
                                            </a>
                                        </div>
                                        <div>
                                            <button className="btn-blue btn-login d-block float-right w-auto">
                                                <i className="fa-solid fa-paper-plane mr-2"></i>Begin Survey
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Growth Survey 4 Screen for Output Screen example2 /} */}

                        {/* {/ Start Home Dashboard For Teacher View Screen /} */}
                        <div className="LeftbarPannel p-0">
                            <div className='CourseCardWrapper fullHeight100 '>
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.viewReport} className='mr-2' alt="" />
                                            Dashboard
                                        </span>
                                    </h2>
                                </div>

                                <div className="pb-3 pt-3 TeacherDashboard" id="TeacherDashboard">

                                    <div className="reminderCourse pb-4 mt-3 ps-3 pe-3">
                                        <span className="d-flex align-items-center pb-4">
                                            <img src={image.mortarboard} alt="" className="mr-2" />
                                            <h3 className="">In Progress Course</h3>
                                        </span>

                                        <div className="reminderCourseContent">
                                            <div className="d-flex justify-content-between">
                                                <h4>
                                                    <span className="pe-2 d-flex">
                                                        <div className="courseCircle me-2">C1</div>
                                                        Exploring You: A Journey to Self-Awareness
                                                    </span>
                                                </h4>

                                                <h4>
                                                    <span className="pe-2">
                                                        <i class="fa fa-calendar-o pe-2" aria-hidden="true"></i>
                                                        Date:
                                                    </span>
                                                    Jul-28
                                                </h4>
                                            </div>
                                            <div className="pt-3">
                                                <h4>
                                                    <span className="pe-2 d-flex">
                                                        <div className="courseCircle me-2">M1</div>
                                                        Introduction to Self-Awareness
                                                    </span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <span className="d-flex align-items-center pt-3 pb-3 ps-3 ms-1">
                                        <img src={image.DiemensionProgress} alt="" className="mr-2" />
                                        <h3 className="">Dimensional Progress</h3>
                                    </span>

                                    <div className="d-flex justify-content-between  ps-4 pe-4">
                                        <p className='Coursecompprcent position-relative'>
                                            <span>
                                                {" "}
                                                <span className='PercentCompleted'>
                                                    {30}
                                                    <span className='percenrSign'>%</span>
                                                </span>
                                                <Circle percent={30} strokeWidth={12} strokeColor='#4169e1' />
                                            </span>
                                            <span className="d-flex justify-content-center align-items-center pt-3">
                                                <img src={image.overall_progress_icon} className='mr-2' alt="" />
                                                <span className="circleNames">Aggregate</span>
                                            </span>
                                        </p>

                                        <p className='Coursecompprcent position-relative'>
                                            <span>
                                                {" "}
                                                <span className='PercentCompleted'>
                                                    {50}
                                                    <span className='percenrSign'>%</span>
                                                </span>
                                                <Circle percent={50} strokeWidth={12} strokeColor='#e8b62f' />
                                            </span>
                                            <span className="d-flex justify-content-center align-items-center pt-3">
                                                <img src={image.courselevel4} className='mr-2' alt="" />
                                                <span className="circleNames">Social</span>
                                            </span>
                                        </p>

                                        <p className='Coursecompprcent position-relative'>
                                            <span>
                                                {" "}
                                                <span className='PercentCompleted'>
                                                    {70}
                                                    <span className='percenrSign'>%</span>
                                                </span>
                                                <Circle percent={70} strokeWidth={12} strokeColor='#dd6363' />
                                            </span>
                                            <span className="d-flex justify-content-center align-items-center pt-3">
                                                <img src={image.CollegeJourneyicon} className='mr-2' alt="" />
                                                <span className="circleNames">Emotional</span>
                                            </span>
                                        </p>

                                        <p className='Coursecompprcent position-relative'>
                                            <span>
                                                {" "}
                                                <span className='PercentCompleted'>
                                                    {60}
                                                    <span className='percenrSign'>%</span>
                                                </span>
                                                <Circle percent={60} strokeWidth={12} strokeColor='#83b612' />
                                            </span>
                                            <span className="d-flex justify-content-center align-items-center pt-3">
                                                <img src={image.courselevel3} className='mr-2' alt="" />
                                                <span className="circleNames">Intellectual</span>
                                            </span>
                                        </p>
                                    </div>

                                    

                                    <div id="planCalendarView" className="mt-4 pb-4 pt-4">
                                        <span className="d-flex align-items-center pb-3">
                                            <img src={image.leanerPlan} alt="" className="mr-2" />
                                            <h3 className="">Plan Schedule</h3>
                                        </span>
                                        <div className="tableResponsive">
                                            <table className="border-0 w-100 mt-2">
                                                <thead>
                                                    <tr>
                                                        <th>Week</th>
                                                        <th>Mon</th>
                                                        <th>Tue</th>
                                                        <th>Wed</th>
                                                        <th>Thu</th>
                                                        <th>Fri</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>Aug-1 Aug-8</p>
                                                            <p className="week">Week 1</p>
                                                        </td>

                                                        <td class="holidays" data-for="tooltip1" data-event-off="" data-tip>
                                                            <div className="d-flex align-items-baseline justify-content-between">
                                                                <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                    Aug-1</p>
                                                                <p className="upcoming">In progress</p>
                                                            </div>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                            <ReactTooltip id="tooltip1" place='bottom' className={"tooltip"}>
                                                                <p>Independence Day</p>
                                                            </ReactTooltip>
                                                        </td>

                                                        <td class="current">
                                                            <div className="d-flex align-items-baseline justify-content-between">
                                                                <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                    Aug-1</p>
                                                                <p className="completed">Completed</p>
                                                            </div>
                                                            <div className="d-flex pt-2 align-items-center" data-for="tooltip2" data-event-off="" data-tip>
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">
                                                                    Exploring You: A Journey to Self-Awareness
                                                                    <ReactTooltip id="tooltip2" place='bottom' className={"tooltip"}>
                                                                        <p> Exploring You: A Journey to Self-Awareness</p>
                                                                    </ReactTooltip>
                                                                </p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName" data-for="tooltip3" data-event-off="" data-tip>
                                                                    Introduction to Self-Awareness
                                                                    <ReactTooltip id="tooltip3" place='bottom' className={"tooltip"}>
                                                                        <p>Introduction to Self-Awareness</p>
                                                                    </ReactTooltip>
                                                                </p>

                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>Aug-1 Aug-8</p>
                                                            <p className="week">Week 2</p>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>Aug-1 Aug-8</p>
                                                            <p className="week">Week 3</p>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>
                                                    </tr>


                                                    <tr>
                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>Aug-1 Aug-8</p>
                                                            <p className="week">Week 4</p>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>Aug-1 Aug-8</p>
                                                            <p className="week">Week 5</p>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>Aug-1 Aug-8</p>
                                                            <p className="week">Week 6</p>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>

                                                        <td class="bg-color1">
                                                            <p className="planDates"><i class="fa fa-calendar-o calenderIcon" aria-hidden="true"></i>
                                                                Aug-1</p>
                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="courseCircle me-1">C1</span>
                                                                <p className="courseName">Exploring You: A Journey to Self-Awareness</p>
                                                            </div>

                                                            <div className="d-flex pt-2 align-items-center">
                                                                <span className="moduleCircle me-1">M1</span>
                                                                <p className="moduleName">Introduction to Self-Awareness</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* {/ End Home Dashboard For Teacher View Screen /} */}



                        {/* {/ Start Right Side Panel /} */}
                        {/* <div className="RightbarPannel p-0 rightpannelSticky">
                            <div className="heading p-0 border-0">
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.growthsurveyPreviewIcon} className='mr-2' alt="" />
                                            Growth Survey Preview</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="ScenecerelateddQuiz allcourselj growthsurveyPreview p-0"> */}
                        {/* {/ Collapse1 /} */}
                        {/* <div className="growthSurveyCollapse">
                                    <h3 className="mt-3 flex">
                                        <div className="">
                                            <span className="collapse-title">Pre Growth Survey Behavior</span>
                                        </div>
                                        <span
                                            className="pointer"
                                            data-toggle="collapse"
                                            href={`#GrowthSurveyColl`}
                                            aria-expanded="true"
                                        >
                                            <i className="fa fa-chevron-down icon-show"></i>
                                        </span>
                                    </h3>

                                    <div className="panel-collapse collapse show" id="GrowthSurveyColl">
                                        <div className="p-3">
                                            <div className="pb-3 d-flex flex-column">
                                                <span className="question">
                                                    <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                                    I am not easily influenced by other people.
                                                </span>

                                                <span className="answer">
                                                    <img src={image.greentickarrow} className="mr-2" />
                                                    I am not easily influenced by other people.
                                                </span>
                                            </div> 

                                            <div className="pb-3 d-flex flex-column">
                                                <span className="question">
                                                    <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                                    I am not easily influenced by other people.
                                                </span>

                                                <span className="answer">
                                                    <img src={image.greentickarrow} className="mr-2" />
                                                    I am not easily influenced by other people.
                                                </span>
                                            </div> 

                                            <div className="pb-3  d-flex flex-column">
                                                <span className="question">
                                                    <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                                    I am not easily influenced by other people.
                                                </span>

                                                <span className="answer">
                                                    <img src={image.greentickarrow} className="mr-2" />
                                                    I am not easily influenced by other people.
                                                </span>
                                            </div> 
                                        </div>
                                    </div>
                                </div> */}
                        {/* {/ Collapse2 /} */}
                        {/* <div className="growthSurveyCollapse">
                                    <h3 className="mt-3 flex">
                                        <div className="">
                                            <span className="collapse-title">Pre Growth Survey Knowledge</span>
                                        </div>
                                        <span
                                            className="pointer"
                                            data-toggle="collapse"
                                            href={`#GrowthSurveyColl1`}
                                            aria-expanded="true"
                                        >
                                            <i className="fa fa-chevron-down icon-show"></i>
                                        </span>
                                    </h3>

                                    <div className="panel-collapse collapse show" id="GrowthSurveyColl1">
                                       
                                    <div className="p-3">
                                            <div className="pb-3 d-flex flex-column">
                                                <span className="question">
                                                    <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                                    I am not easily influenced by other people.
                                                </span>

                                                <span className="answer">
                                                    <img src={image.greentickarrow} className="mr-2" />
                                                    I am not easily influenced by other people.
                                                </span>
                                            </div> 

                                            <div className="pb-3 d-flex flex-column">
                                                <span className="question">
                                                    <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                                    I am not easily influenced by other people.
                                                </span>

                                                <span className="answer">
                                                    <img src={image.greentickarrow} className="mr-2" />
                                                    I am not easily influenced by other people.
                                                </span>
                                            </div> 

                                            <div className="pb-3  d-flex flex-column">
                                                <span className="question">
                                                    <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                                    I am not easily influenced by other people.
                                                </span>

                                                <span className="answer">
                                                    <img src={image.greentickarrow} className="mr-2" />
                                                    I am not easily influenced by other people.
                                                </span>
                                            </div> 
                                        </div>  
                                    </div>
                                </div> 
                        </div>
                        </div> */}
                        {/* {/ End Right Side Panel /} */}


                        {/* {/ Start Right Side Panel for Growth Survey without Collapse /} */}
                        {/* <div className="RightbarPannel p-0 rightpannelSticky">
                            <div className="heading p-0 border-0">
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.growthsurveyPreviewIcon} className='mr-2' alt="" />
                                            Growth Survey Preview</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="ScenecerelateddQuiz allcourselj growthsurveyPreview p-0">
                                <div className="p-3">
                                    <div className="pb-3 d-flex flex-column">
                                        <span className="question">
                                            <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                            I am not easily influenced by other people.
                                        </span>

                                        <span className="answer">
                                            <img src={image.greentickarrow} className="mr-2" />
                                            I am not easily influenced by other people.
                                        </span>
                                    </div>

                                    <div className="pb-3 d-flex flex-column">
                                        <span className="question">
                                            <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                            I am not easily influenced by other people.
                                        </span>

                                        <span className="answer">
                                            <img src={image.greentickarrow} className="mr-2" />
                                            I am not easily influenced by other people.
                                        </span>
                                    </div>

                                    <div className="pb-3  d-flex flex-column">
                                        <span className="question">
                                            <i class="fa fa-question-circle-o pr-2" aria-hidden="true"></i>
                                            I am not easily influenced by other people.
                                        </span>

                                        <span className="answer">
                                            <img src={image.greentickarrow} className="mr-2" />
                                            I am not easily influenced by other people.
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Right Side Panel for Growth Survey without Collapse /} */}


                        {/* {/ Start Right Side Panel for Growth Survey Gradient Introdation Screen/} */}
                        {/* <div className="RightbarPannel p-0 rightpannelSticky">
                            <div className="heading p-0 border-0">
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.growthSurveyIcon} className='mr-2' alt="" />
                                            Growth Survey Data</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="ScenecerelateddQuiz allcourselj growthsurveyData p-0">
                                <div class="p-3">
                                    <div className="d-flex pt-">
                                        <div className="pe-4 levelNames">
                                            <p>Pro</p>
                                            <p>Advanced</p>
                                            <p>Beginner</p>
                                        </div>

                                        <div className="gradientBox">
                                            <div className="gradientStar">
                                                <img src={image.starIcon} className='mr-2' alt=""/>
                                            </div>
                                            <div className="gradientCircle">
                                                <i className="fa fa-circle fa-lg pe-2" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-around pt-4 align-item-center">
                                        <div className="placementname">
                                            <i className="fa fa-circle fa-lg pe-2" aria-hidden="true"></i>
                                            <span>Current Placement</span>
                                        </div>
                                        <div className="placementname">
                                            <span>
                                                <img src={image.starIcon} className='mr-2' alt="" style={{width:'28px'}} />
                                            </span>
                                            <span>Projected Placement</span>
                                        </div>
                                    </div>      
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Right Side Panel  for Growth Survey Gradient Introdation Screen /} */}

                        {/* {/ Start Right Side Panel for Growth Survey Introdation Screen/} */}
                        {/* <div className="RightbarPannel p-0 rightpannelSticky">
                            <div className="heading p-0 border-0">
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.Powericon} className='mr-2' alt="" />
                                            Growth Survey Preview</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="ScenecerelateddQuiz allcourselj ">
                                <div class="p-2 rightPanelIntroScreen">
                                    <p>
                                        The growth survey is an easy way for you to see how much you gain from each course. You will
                                        take the survey now before you begin lessons to see your starting placement and then again after
                                        the course to see your final placement. Then you will compare them side by side!

                                    </p>

                                    <p>All growth is good growth so no matter where you place on the chart, you should be proud! This is not a test,
                                        there are no wrong answers, answer honestly to how the questions relate to your life.
                                    </p>


                                    <p>The growth survey only takes a few minutes, click the button below to get started.</p>
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Right Side Panel  for Growth Survey Introdation Screen /} */}

                        {/* {/ Start Right Side Panel for Home Dashboard Screen/} */}
                        {/* <div className="RightbarPannel p-0 rightpannelSticky">
                            <div className="heading p-0 border-0">
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.myJournal} className='mr-2' alt="" />
                                        My Journal</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="ScenecerelateddQuiz allcourselj ">
                                <div class="p-2 rightPanelIntroScreen">
                                    {/* <p>
                                        hello
                                    </p> }
                                </div>
                            </div>
                        </div> */}
                        {/* {/ End Right Side Panel  for Home Dashboard  Screen /} */}

                        {/* Start Right Side Panel for Home Dashboard Screen */}
                        <div className="RightbarPannel p-0 rightpannelSticky">
                            <div className="heading p-0 border-0">
                                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading summery_plan">
                                    <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                                        <span> <img src={image.myJournal} className='mr-2' alt="" />
                                            My Journal</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="ScenecerelateddQuiz allcourselj">
                                <div class="p-2 textAreatypeQuestion">
                                    <div className="pl-0">
                                        <textarea
                                            name=""
                                            id=""
                                            placeholder="What did you like or dislike about this course? What did you learn? What more or different did you expect from this course?"
                                            className="form-control myJournalTextArea"
                                        ></textarea>
                                    </div>
                                </div>

                                <div class="input-group full-Width-group pt-0 continuebtn">
                                    <div class="form-group BDsubmitbutton m-0">
                                        <button
                                            type="submit"
                                            class="btn-blue btn-login d-block mb-5 ml-auto float-end"
                                        >
                                            Back
                                            <i class="fa-solid fa-arrow-right ml-2 m-0"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Right Side Panel  for Home Dashboard  Screen */}
                    </div>
                </div>
            </Home>
        </div>

    );
}
export default UiTestingPage;