  import React, { useState } from "react";
import Home from "../Home";
import * as image from "../../resources/images";
import { SelectPicker, Rating } from "../../utils/Packages";
import RoundProgress from "../controls/RoundProgress";
import Link from "antd/es/typography/Link";
import CourseRatings from "../controls/CourseRatings";
const CourseStaticPage = () => {
  return (
    <div>
      {/* <Home> */}
        <div className="d-flex flex-wrap SpecialLeftpanel w-100">
          <div className="d-flex w-100 align-items-start overflow-visible">
          <div className="container">
            <div className="Course_tagList mt-5">

            <h4 className="flex">
            <span>
            <img src={image.mortarboard} />
             Tags 
             </span> 
             <img src={image.filter_icon} />
            </h4>

            <div class="flexone dimension_relatedtags">
                   <div class="">
                  <p class="mb-0">Dimensions: </p>
                  </div>
                <ul class="p-0 mb-0 Icon_with_tag">
                <li data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                <div class="flexone  filtetags  ">
                <label class="taglabel">
                 <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/socialDim.png" />
                  Social
                 <input type="checkbox" />
                 <span class="checkmark"></span>
              
                 </label>  
                </div> 
                <div  class= "SkillDropdwn collapse" id="collapseExample">
                <ul>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                    Conflict Resolution
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Effective Communication
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Responsible Decision Making
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                    Leadership
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Teamwork & Collaboration
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Relationship Management
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                    Empathy
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Social Awareness
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                  </ul>
                </div>
                </li>
                <li>
                <div class="flexone  filtetags  ">
                <label class="taglabel">
                <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/emotionalDim.png" alt="" />  
                 Emotional
                 <input type="checkbox" />
                 <span class="checkmark"></span>
                 </label>
                </div> 
                <div  class= "SkillDropdwn collapse" id="collapseExample">
                <ul>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Self-Confidence
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Fear Management
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Self-Discipline
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Self-Management
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Self-Awareness
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Resilience
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
               
                  </ul>
                </div>
                </li>
                <li>
                <div class="flexone  filtetags  ">
                <label class="taglabel">
                <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/intellectualDim.png" alt="" />
                Intellectual
                 <input type="checkbox" />
                 <span class="checkmark"></span>
                 </label>
                </div> 
                <div  class= "SkillDropdwn collapse" id="collapseExample">
                <ul>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                     Critical Thinking
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Problem Solving
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                 Learning & Adapting
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Creativity & Innovation


                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Teamwork & Collaboration
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Finance
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
              
                  </ul>
                </div>
                </li>
                <li>
                <div class="flexone  filtetags  ">
                <label class="taglabel">
                 <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/spiritualDim.png" alt="" />
                  Mindfullness
                 <input type="checkbox"  />
                 <span class="checkmark"></span>
                 </label>
                </div> 
                <div  class= "SkillDropdwn collapse" id="collapseExample">
                <ul>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Mindfulness
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Meditation
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                 Gratitude
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Forgiveness
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                 Compassion
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                
                  </ul>
                </div>
                </li>
                <li>
                <div class="flexone  filtetags  ">
                <label class="taglabel">
                 <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/physicalDim.png" alt="" />
                 Physical
                 <input type="checkbox" />
                 <span class="checkmark"></span>
                 </label>
                </div> 
                <div  class= "SkillDropdwn collapse" id="collapseExample">
                <ul>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Yoga
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Health & Nutrition
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                     Physical Fitness
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                <li>
                <div class="flexone  filtetags  ">
                  <label class="taglabel">
                  Gross & Fine Motor Skills
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                   </label>
                </div> 
                </li>
                  </ul>
                </div>
                </li>
            
           

                 </ul>
                </div>
            </div>
            {/* <div class="coursecard_list">
                <div class="lib_course_card" id="dimension_name_01">
                 
                  <div class="lib_coursecarddtl">
                    <div class="lib_courseimg position-relative">
                      <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                    
                    </div>
                    <div class="lib_coursul">
                    <div class="lib_Course_cardTitle flex">
                    <h4 class="w-100 mb-0">Emotional You: Managing My Emotions</h4>
                    <div class="flex justify-content-end w-100">
                        <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                        | 
                        <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                    </div>
                  </div>
                  <div class="flex">
                  <div class="w-80 course_objective">
                  <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                      <ul>
                        <li>
                         <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                         Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                          </li>
                        <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                        </li>
                        <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                        Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                        </li>
                        </ul>
                      
                      <div class="lib_course_items">
                    </div>
                      </div>
                      <div class="explorebtn w-20">
                        <a href="#">Enroll Now</a>
                        <a href="#">Learn More </a>   
                      </div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="lib_course_card" id="dimension_name_02">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Exploring You: A Journey to Self-Awareness</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_03">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Confident You: Believe in Yourself</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_04">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Resilient You: What is Resiliency?</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_05">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Defining and Understanding Trauma</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_06">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Goal Setting: Gaining Tools for Success</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_07">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Mastering Impulse Control</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_08">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">The Art of Self-Discipline</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_09">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">FearLESS: Building Brave Young Hearts</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_10">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">MindCraft: Developing Analytical Skills</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_11">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Developing SuperHuman Problem-Solving Skills</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_12">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Financial Literacy I</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_13">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Financial Literacy II</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_14">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Don't Wait: Create!</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_15">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Learning to Love Learning!</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_16">
                 
                 <div class="lib_coursecarddtl">
                   <div class="lib_courseimg position-relative">
                     <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                   
                   </div>
                   <div class="lib_coursul">
                   <div class="lib_Course_cardTitle flex">
                   <h4 class="w-100 mb-0">Conflict Resolution Foundations</h4>
                   <div class="flex justify-content-end w-100">
                       <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                       | 
                       <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                   </div>
                 </div>
                 <div class="flex">
                 <div class="w-80 course_objective">
                 <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                     <ul>
                       <li>
                        <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                        Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                         </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                       </li>
                       <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                       Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                       </li>
                       </ul>
                     
                     <div class="lib_course_items">
                   </div>
                     </div>
                     <div class="explorebtn w-20">
                       <a href="#">Enroll Now</a>
                       <a href="#">Learn More </a>   
                     </div>
                     </div>
                   </div>
                 </div>
             </div>
             <div class="lib_course_card" id="dimension_name_17">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Making Responsible Choices</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_18">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Relationship Building Basics</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_19">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Human Dynamics</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_20">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Building Leadership Skills</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_21">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Identifying and Dealing With Bullies</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_22">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Communication Foundations</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_23">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Understanding Why Teamwork Matters</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_24">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Cultivating Empathy</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_25">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Online Safety: Building Knowledgeable Digital Citizens</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_26">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Breathwork to Awaken Your Super Energy</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_27">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Connecting Body & Mind Through Yoga</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_28">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Starting a Mindful Meditation Practice</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_29">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">What is Gratitude?</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_30">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Power of Forgiveness</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="lib_course_card" id="dimension_name_31">
                
                <div class="lib_coursecarddtl">
                  <div class="lib_courseimg position-relative">
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png"/>
                  
                  </div>
                  <div class="lib_coursul">
                  <div class="lib_Course_cardTitle flex">
                  <h4 class="w-100 mb-0">Meditation Foundation: Breath & Self Awareness</h4>
                  <div class="flex justify-content-end w-100">
                      <p class="mr-1 mb-0"><strong>Module: 4</strong></p>
                      | 
                      <p class="ml-1 mb-0"><strong>Lessons: 40</strong></p>
                  </div>
                </div>
                <div class="flex">
                <div class="w-80 course_objective">
                <p class="p-0 mb-0"><strong>Course Objective:</strong></p>
                    <ul>
                      <li>
                       <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                       Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                        </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1"/>
                      Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                      </li>
                      <li><img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                      Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                      </li>
                      </ul>
                    
                    <div class="lib_course_items">
                  </div>
                    </div>
                    <div class="explorebtn w-20">
                      <a href="#">Enroll Now</a>
                      <a href="#">Learn More </a>   
                    </div>
                    </div>
                  </div>
                </div>
            </div>
             
             </div> */}


             <div class="coursecard_list">
       <div class="lib_course_card social" id="course_skill_1">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Conflict Resolution Foundations</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop strategies to approach conflict constructively, using effective communication and empathy to resolve disputes peacefully.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Learn to manage emotions during conflict, with techniques like mindfulness and positive self-talk to stay calm and focused.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    BBuild active listening and perspective-taking skills, fostering understanding and preventing misunderstandings in tough situations.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card social" id="course_skill_2">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Communication Foundations</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop effective communication skills across various mediums, including verbal, nonverbal, and digital communication.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Enhance active listening abilities and empathetic communication to build stronger relationships and foster understanding.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Learn to navigate common communication challenges such as language differences and communication overload.

                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="lib_course_card social" id="dimension_name_3">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Online Safety: Building Knowledgeable Digital Citizens</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card social" id="course_skill_4">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Making Responsible Choices</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Understand the significance of making responsible decisions by considering both short-term and long-term consequences and how those decisions impact your life and others.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn to resist peer pressure and societal expectations, developing personal strategies to handle conflicting pressures and make independent, value-based decisions.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop a growth mindset by viewing mistakes as learning opportunities, reflecting on past decisions, and improving future decision-making.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card social" id="course_skill_5">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Building Leadership Skills</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop key leadership qualities such as dedication, flexibility, and communication to enhance personal growth and teamwork.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn to set effective goals and cultivate a solutions-oriented mindset to navigate challenges and achieve success.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Build strong communication and collaboration skills to foster trust, inclusivity, and cooperation within teams.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card social" id="course_skill_6">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Understanding Why Teamwork Matters</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="lib_course_card social" id="course_skill_7">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Relationship Building Basics</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop the ability to build and maintain trust in relationships, recognizing its importance in fostering strong, supportive connections.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Understand the emotional and psychological benefits of healthy relationships, including how they reduce stress, promote well-being, and contribute to personal growth.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn and practice effective communication skills, including active listening, empathy, and using non-verbal cues, to strengthen interactions with peers and family.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card social" id="dimension_name_8">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Cultivating Empathy</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card social" id="course_skill_9">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Human Dynamics</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop empathy by learning to understand and respect the perspectives of others, fostering inclusivity in social interactions.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Recognize and challenge biases and stereotypes to promote fairness and equality in diverse communities.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn effective communication and listening skills, including the power of words, to build respectful and meaningful relationships.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
     
     
    
    
    
      <div class="lib_course_card emotional" id="dimension_name_10">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Confident You: Believe in Yourself</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card emotional" id="dimension_name_11">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">FearLESS: Building Brave Young Hearts</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Understand the protective function of fear and how it prepares the body for action, helping children recognize when fear is helpful and when it becomes an obstacle in life.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Explore coping strategies for common fears, such as social anxiety and fear of failure, while learning to open up about personal fears and build self-confidence in the face of social challenges.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop and practice effective techniques such as deep breathing, mindfulness, and physical exercise to manage stress and reduce fear responses in everyday situations.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card emotional" id="dimension_name_12">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">The Art of Self-Discipline</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop self-discipline skills to make better decisions, resist distractions, and achieve personal goals.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Understand and manage emotions through self-discipline, improving emotional regulation, and reducing impulsive behavior.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Build healthy habits and routines that promote long-term success, such as time management, focus, and personal responsibility.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card emotional" id="dimension_name_13">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Defining and Understanding Trauma</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card emotional" id="dimension_name_14">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Emotional You: Managing My Emotions</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Understand and manage emotions by recognizing both positive and negative emotions and how they impact thoughts, behaviors, and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop coping strategies for managing stress and negative emotions, including deep breathing, distraction tools and expressive techniques.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Build an emotional management toolbox that includes skills like emotional regulation, stress reduction, and problem-solving to handle everyday emotional challenges.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card emotional" id="dimension_name_15">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Goal Setting: Gaining Tools for Success</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Increase knowledge of goal setting and supporting skills.

                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Help establish realistic expectations for goal setting by identifying both the possible benefits and expected challenges.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Promote development of personal goals that are considered effective and consistent with personal values. 
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="lib_course_card emotional" id="dimension_name_16">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Mastering Impulse Control</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Recognize and manage the emotional and physical triggers of impulsive behavior, helping develop greater self-awareness and control in everyday situations.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Practice mindfulness, meditation, and movement techniques to effectively regulate emotions and impulses, promoting healthier decision-making.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop the ability to delay gratification, improving patience and focus, which are key skills for academic success and personal growth.

                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card emotional" id="dimension_name_17">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Identifying and Dealing With Bullies</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Recognize the different forms of bullying, including physical, verbal, social, and cyberbullying, and understand their harmful effects on both the target and the bully.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn how to identify signs that someone is being bullied, including emotional, behavioral, and physical changes, and how to support them effectively.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Explore strategies for self-advocacy and standing up for oneself or others in bullying situations, fostering confidence and resilience.

                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card emotional" id="dimension_name_18">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Exploring You: A Journey to Self-Awareness</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Recognize and manage emotions, thoughts, and behaviors to foster emotional intelligence and mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop self-awareness by understanding personal values, beliefs, and how they influence decision-making.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn to set healthy boundaries and practice assertive communication to maintain emotional and social well-being.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div class="lib_course_card emotional" id="dimension_name_19">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Resilient You: What is Resiliency?</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Understand and apply strategies for building resilience through goal-setting, problem-solving, and learning from failure.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop emotional awareness and regulation skills to manage stress and improve mental health and well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Cultivate self-care habits, including rest, relaxation, and exercise, to support physical and emotional resilience.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    
    
      
  
      <div class="lib_course_card intellectual" id="dimension_name_20">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">MindCraft: Developing Analytical Skills</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop critical thinking skills to analyze complex problems, evaluate information from multiple sources, and make well-reasoned decisions in academic and everyday situations.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn to identify and mitigate personal biases and groupthink, fostering open-mindedness and the ability to consider diverse perspectives.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Master effective communication techniques to clearly express ideas, actively listen to others, and provide constructive feedback in group settings.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card intellectual" id="dimension_name_21">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Developing SuperHuman Problem-Solving Skills</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Recognize essential problem-solving skills, breaking down complex issues into manageable steps.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Foster critical thinking and decision-making abilities to analyze problems from multiple perspectives and find effective solutions.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop teamwork and collaboration skills.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card intellectual" id="dimension_name_22">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Learning to Love Learning!</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop a growth mindset by learning how to embrace challenges, persist through obstacles, and view failures as opportunities for growth.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Cultivate self-advocacy skills by understanding how to express needs and feelings clearly, build confidence, and seek support in both academic and personal settings.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Enhance adaptability and resilience by learning to adjust to new environments, overcome setbacks, and maintain a positive outlook during challenging situations.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card intellectual" id="dimension_name_23">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Don't Wait: Create!</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Apply creative problem-solving techniques to identify real-world issues and develop innovative solutions that positively impact communities.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop resilience and a growth mindset to overcome challenges, learn from failures, and persistently iterate on ideas and projects.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Collaborate effectively with peers, providing constructive feedback and support while working on innovative projects and social initiatives.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card intellectual" id="dimension_name_24">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Financial Literacy I</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Understand the importance of managing money by setting realistic financial goals and creating a budget to achieve them.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Learn to differentiate between needs and wants, and make informed spending decisions to avoid impulse purchases.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Develop strong saving habits by paying yourself first and utilizing savings accounts to plan for both short-term and long-term goals.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card intellectual" id="dimension_name_25">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Financial Literacy II</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" />
                    Understand the importance of saving and investing by learning how to use banks, stocks, and other investment options to make money work and grow over time.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Explore entrepreneurship as a way to increase income, including how to start and manage a business while balancing the risks and rewards of self-employment.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> 
                    Develop responsible borrowing habits by understanding the risks associated with loans, credit, and debt, and how to maintain a good credit score.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div class="lib_course_card mindfullness" id="dimension_name_26">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Meditation Foundation: Breath & Self Awareness</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card mindfullness" id="dimension_name_27">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Starting a Mindful Meditation Practice</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card mindfullness" id="dimension_name_28">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">What is Gratitude?</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card mindfullness" id="dimension_name_29">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Power of Forgiveness</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card mindfullness" id="dimension_name_30">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Cultivating Compassion</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lib_course_card physical" id="dimension_name_31">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Connecting Body & Mind Through Yoga</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lib_course_card physical" id="dimension_name_32">
        <div class="lib_coursecarddtl">
          <div class="lib_courseimg position-relative">
            <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/social.png" />
          </div>
          <div class="lib_coursul">
            <div class="lib_Course_cardTitle flex">
              <h4 class="w-100 mb-0">Breathwork to Awaken Your Super Energy</h4>
              <div class="flex justify-content-end w-100">
                <p class="mr-1 mb-0">
                  <strong>Module: 4</strong>
                </p> | <p class="ml-1 mb-0">
                  <strong>Lessons: 40</strong>
                </p>
              </div>
            </div>
            <div class="flex">
              <div class="w-80 course_objective">
                <p class="p-0 mb-0">
                  <strong>Course Objective:</strong>
                </p>
                <ul>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Build self-confidence and self-esteem by understanding their importance and how they impact personal growth and relationships.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Develop positive self-talk skills to manage emotions, reduce stress, and enhance overall mental well-being.
                  </li>
                  <li>
                    <img src="https://44742474.fs1.hubspotusercontent-na1.net/hubfs/44742474/Dimensions/greentick.png" class="mr-1" /> Identify personal strengths, talents, and gifts, and learn how to apply them to build confidence and succeed in various areas of life.
                  </li>
                </ul>
                <div class="lib_course_items"></div>
              </div>
              <div class="explorebtn w-20">
                <a href="#">Enroll Now</a>
                <a href="#">Learn More </a>
              </div>
            </div>
          </div>
        </div>
      </div>
     
     
              </div>
          </div>
               {/* <div className="LeftbarPannel p-0" id="">
         
              <div className='CourseCardWrapper fullHeight100 skillAquisationSurver'>
              
                <div className="Course_height">
               <div class="smartquizbanner text-center">
                 <img src={image.acquisitionbnr} alt="" className="m-auto" />
                </div>
                <div class="pt-3 mt-3 mb-3 beginAssessmentbtn">
                <button class="btn-blue btn-login d-block  m-auto w-auto">
                <i class="fa-solid fa-paper-plane mr-2"></i>Begin Assessment
                </button>
                </div> 
             
                 </div>
         
                 </div> 
              
                 </div>  */}
                 {/* <div className="LeftbarPannel p-0" id="">
         
         <div className='CourseCardWrapper fullHeight100 skillAquisationSurver'>
         
           <div className="Course_height">
        
       
            <div class=" skill_aquization_survery ">
             
         <div class="quizblok skill_aquization_survery">
          <div class="backpageStrip p-0 false">
           <a href="#" class="flexone false"><span class=""><i class="fa-solid fa-angle-left mr-1"></i></span>Back</a>
           </div>
           <span class="Progresslistitem w-50 m-0">
           <span class="diemensionalProgress progressbar1" style={{"max-width": "0%" ," background":" rgb(32, 201, 151)"}}></span>
           </span>
           <span class="procompt">  0%</span>
           <div class="backpageStrip p-0 false">
           <span class=" false">
           <a href="#" class="false">Next<span class=""><i class="fa-solid fa-angle-right ml-1"></i></span></a>
           </span>
           </div>
          
           
            </div>
            <div className=" smqzQueslist pt-4 ps-5 pe-5 smqzQueslist">
           
           <div class="signupType m-0 mb-3">
           <div>
           <h3 class="mb-3 flexone">
           <span>
           <img src={image.questionIcon} alt="" />
           </span>
            I can picture scenes in my head when I learn.</h3>
            <div className="pt-3 questionOptions">
            <label class="Selcheckbox ">Not at all like me<input type="radio" id="cedb8ac2-bf1e-41ae-ad54-1402adc0fd0a" value="cedb8ac2-bf1e-41ae-ad54-1402adc0fd0a"/>
            <span class="checkmark"></span></label><label class="Selcheckbox ">Kinda like me<input type="radio" id="7b4d4c75-a7ff-4677-8f59-dbce9792126f" value="7b4d4c75-a7ff-4677-8f59-dbce9792126f" />
            <span class="checkmark"></span></label><label class="Selcheckbox ">This is like me<input type="radio" id="24197888-da05-4684-b8b2-d0eabf8fa34e" value="24197888-da05-4684-b8b2-d0eabf8fa34e" />
            <span class="checkmark"></span></label><label class="Selcheckbox ">A lot like me<input type="radio" id="a7c5ff79-8d9c-4fa3-8246-34fd29754bdb" value="a7c5ff79-8d9c-4fa3-8246-34fd29754bdb" />
            <span class="checkmark"></span></label><label class="Selcheckbox ">Totally me<input type="radio" id="d24e6809-5041-4e22-9109-9c4dd0f0de6f" value="d24e6809-5041-4e22-9109-9c4dd0f0de6f" />
            <span class="checkmark"></span></label></div>
            
         
            </div>
            </div>
            </div>
            </div>
            </div>
            <div class="input-group full-Width-group basic_details_form pagebuttonStrip bottom-0">
            <div class="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0">
            
            <button type="button" disabled="" class="btn-blue btn-login d-block mb-5  m-0 back_button"> 
            <i class="fa-solid fa-arrow-left mr-2"></i>Back</button><div class="next_button buttonDistribotion">
            <div><span class="false"><a href="#" class="btn-blue btn-login d-block  w-auto text-white false">Next<i class="fa-solid fa-arrow-right m-0 ml-2"></i></a></span></div></div></div>
            </div>
        
            </div> 
         
                </div>  */}
                {/* <div className="LeftbarPannel p-0" id="">
         
         <div className='CourseCardWrapper fullHeight100 skillAquisationSurver'>
         
           <div className="Course_height">
         
        
            </div>
          
          <div className="quizblok">
            <div class="smartquizwrapper">
             <h3 class="mb-3 quizrestitle">Your Surey Result</h3>
              <div class="smqzQueslist inequeresultwrap">
              <div class="flex intequResult pointer">
                 <div class="intellegencename">Self-Awareness<img src={image.MIimage1} alt="" />
             </div>
             <span class="Progresslistitem w-50 m-0">
              <span class="diemensionalProgress progressbar1" style={{"max-width": "70%" ,"background": "rgb(32, 201, 151)"}}></span>
             </span>
            <div class="procompt">100%</div>
              </div>
              <div class="flex intequResult pointer">
                 <div class="intellegencename">Self-Management<img src={image.MIimage1} alt="" />
             </div>
             <span class="Progresslistitem w-50 m-0">
              <span class="diemensionalProgress progressbar1" style={{"max-width": "70%" ,"background": "rgb(32, 201, 151)"}}></span>
             </span>
            <div class="procompt">100%</div>
              </div>
              <div class="flex intequResult pointer">
                 <div class="intellegencename">Responsible Decision Making<img src={image.MIimage1} alt="" />
             </div>
             <span class="Progresslistitem w-50 m-0">
              <span class="diemensionalProgress progressbar1" style={{"max-width": "70%" ,"background": "rgb(32, 201, 151)"}}></span>
             </span>
            <div class="procompt">100%</div>
              </div>
              <div class="flex intequResult pointer">
                 <div class="intellegencename">Relationship Management<img src={image.MIimage1} alt="" />
             </div>
             <span class="Progresslistitem w-50 m-0">
              <span class="diemensionalProgress progressbar1" style={{"max-width": "70%" ,"background": "rgb(32, 201, 151)"}}></span>
             </span>
            <div class="procompt">100%</div>
              </div>
              <div class="flex intequResult pointer">
                 <div class="intellegencename">Social Awareness<img src={image.MIimage1} alt="" />
             </div>
             <span class="Progresslistitem w-50 m-0">
              <span class="diemensionalProgress progressbar1" style={{"max-width": "70%" ,"background": "rgb(32, 201, 151)"}}></span>
             </span>
            <div class="procompt">100%</div>
              </div>
              <div class="flex intequResult pointer">
                 <div class="intellegencename">Critical Thinking<img src={image.MIimage1} alt="" />
             </div>
             <span class="Progresslistitem w-50 m-0">
              <span class="diemensionalProgress progressbar1" style={{"max-width": "70%" ,"background": "rgb(32, 201, 151)"}}></span>
             </span>
            <div class="procompt">100%</div>
              </div>
              <div class="flex intequResult pointer">
                 <div class="intellegencename">Problem Solving<img src={image.MIimage1} alt="" />
             </div>
             <span class="Progresslistitem w-50 m-0">
              <span class="diemensionalProgress progressbar1" style={{"max-width": "70%" ,"background": "rgb(32, 201, 151)"}}></span>
             </span>
            <div class="procompt">100%</div>
              </div>
   </div>
</div>
            </div>
            </div> 
         
                </div>   */}
             {/* <div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl">
              <div className="heading p-0 border-0">
                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                  <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                    <span> <img src={image.acquisitionsurvey} className='mr-2' alt="" />
                       Skill Acquisition Survey
                      </span>
                  </h2>
                </div>

              </div>
               <div class="LessionDtlOverview">
              <div class="d-flex align-items-start flex-wrap">
              <div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar"><p><div>
                <p>
              Tests can be uncomfortable for people because they might judge themselves on their score. 
               This test is different. It does not assess as right or wrong. It simply helps people learn
                where they have natural strengths, which some refer to as “Intelligences.” 
      </p>
      <p>&nbsp;</p>
      <p>
      Somebody can be “intelligent” by being good at playing an instrument, but have real difficulties
       listening to a lecture.  Or maybe they are great at math and problem-solving but can have challenges 
       cooking a meal.  This assessment can help give you an idea of areas in which you naturally excel.
      </p>
      <p>&nbsp;</p>
      <p>
      There are 40 questions. Please go with your first instinct and don’t overthink your answers. 
      </p>
      <p>&nbsp;</p>
      <p>
      Please click “Begin Assessment” when you are ready. Have fun and let’s see what you make of this!
      </p>
    </div>
    </p> <span class="AudioIcon "><img src={image.Audioicon} alt="icon" class="pointer" /></span></div></div>
            </div> 
        
            
            </div>  */}
            {/* <div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl">
              <div className="heading p-0 border-0">
                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                  <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                    <span> <img src={image.acquisitionsurvey} className='mr-2' alt="" />
                       Skill Acquisition Survey
                      </span>
                  </h2>
                </div>

              </div>
          
             <div class="lSidebarcontentwrap">
            <div class="growthsurveyPreview p-0">
            <div class="p-2 mt-2 mb-2">
            <div class="pb-3 d-flex flex-column pl-2">
            <span class="question pointer d-flex">
            <i class="fa fa-hand-o-right me-1 pt-2" aria-hidden="true"></i>
            I can picture scenes in my head when I learn.</span>
             <span class="answer"><span>
             </span> 
             Kinda like me</span>
             </div>
             <div class="pb-3 d-flex flex-column pl-2">
            <span class="question pointer d-flex">
            <i class="fa fa-hand-o-right me-1 pt-2" aria-hidden="true"></i>
            I can picture scenes in my head when I learn.</span>
             <span class="answer"><span>
             </span> 
             Kinda like me</span>
             </div>
             <div class="pb-3 d-flex flex-column pl-2">
            <span class="question pointer d-flex">
            <i class="fa fa-hand-o-right me-1 pt-2" aria-hidden="true"></i>
            I can picture scenes in my head when I learn.</span>
             <span class="answer"><span>
             </span> 
             Kinda like me</span>
             </div>
             </div>
             </div>
            </div> 
        
            
            </div>  */}
            {/* <div className="RightbarPannel p-0 rightpannelSticky newcoursecardpanel setuptwo_rPnl">
              <div className="heading p-0 border-0">
                <div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
                  <h2 data-toggle="collapse" class="m-0 pt-0 pb-1 w-100 flex justify-content-between ">
                    <span> <img src={image.acquisitionsurvey} className='mr-2' alt="" />
                       Skill Acquisition Survey Result
                      </span>
                  </h2>
                </div>

              </div>
               <div class="LessionDtlOverview">
              <div class="d-flex align-items-start flex-wrap">
              <div class="flex w-100 mb-3 align-items-start flex-wrap text-justify textcontentSidebar"><p><div>
                <p>
              Tests can be uncomfortable for people because they might judge themselves on their score. 
               This test is different. It does not assess as right or wrong. It simply helps people learn
                where they have natural strengths, which some refer to as “Intelligences.” 
      </p>
      <p>&nbsp;</p>
      <p>
      Somebody can be “intelligent” by being good at playing an instrument, but have real difficulties
       listening to a lecture.  Or maybe they are great at math and problem-solving but can have challenges 
       cooking a meal.  This assessment can help give you an idea of areas in which you naturally excel.
      </p>
      <p>&nbsp;</p>
      <p>
      There are 40 questions. Please go with your first instinct and don’t overthink your answers. 
      </p>
      <p>&nbsp;</p>
      <p>
      Please click “Begin Assessment” when you are ready. Have fun and let’s see what you make of this!
      </p>
    </div>
    </p> <span class="AudioIcon "><img src={image.Audioicon} alt="icon" class="pointer" /></span></div></div>
            </div> 
        
            
            </div>    */}
</div>
        </div>

      {/* </Home> */}
    </div>
  );
};

export default CourseStaticPage;