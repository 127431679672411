import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "../../utils/Packages";
import {
    showModal,
    resetEmailResponse,
    resetLoginResponse,
    setCourseJourneyId,
    resetMultiquizResponse,
    enrollModal,
} from "../../redux/actions";

import * as image from "../../resources/images";
import { PATHS } from "../../utils";
import { getCapitalized, getSequnceSort } from "../../utils/helper";
import { SITENAME } from "../../utils/Messages";
import { courseEnrollmentWithSession, getUsersEntryPopup } from "../../redux/actions/APIs";

const SignUpPlanPopup = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();

    const {
        addLearner,
        loggedInUser,
        alluserdetails,
        getdimension,
        getSelectedCourseJourneyId,
        getMyPlanData,
        defaultChildData,
        modalData,
        screenData,
        courseEnrollResponse
    } = useSelector((state) => state.collections);


    const hideShowBox = () => {
        dispatch(showModal());
        dispatch(resetLoginResponse());
        dispatch(resetEmailResponse());
    };

    const handleSelectedPlanCourses = (myPlanData) => {
        let myPlanCourses = [...myPlanData];
        let coursesArr = [];
        let myPlanTree = [];
        myPlanCourses?.map((dim, index) => {
            let dimObj = { ...dim, skills: [] }
            dim?.skills?.map((skill, sKey) => {
                let skilObj = { ...skill, courses: [] }
                dimObj?.skills?.push(skilObj);
                skill?.courses?.map((course, cKey) => {
                    dimObj?.skills[sKey]?.courses?.push(course);
                    course.isSelected = true;
                    coursesArr?.push({ ...course });
                })
            })
            myPlanTree?.push(dimObj);
        })
        return getSequnceSort(coursesArr);
    }

    const showEditPlanScreen = () => {
        if (screenData?.data?.length > 0) {
            screenData.data[0].isEditPlan = true;
            screenData.data[0].planId = courseEnrollResponse?.recordId;
            dispatch(courseEnrollmentWithSession());
            dispatch(enrollModal(screenData?.data[0]));

        } else {
            let coursesArr = handleSelectedPlanCourses(getMyPlanData?.records[0]?.dimensions);
            coursesArr[0].isEditPlan = true;
            coursesArr[0].planId = getMyPlanData?.records[0]?.planId;
            console.log("coursesArr#### : ", coursesArr);
            dispatch(courseEnrollmentWithSession());
            dispatch(enrollModal(coursesArr[0]));
        }

        hideShowBox();


    }
    return (
        <>
            <div className="sharepopup  AreyousurePopup areyousurenewpopup welcome_new_poup welcome_new_poup__new">
                <div className="modal  show d-flex" id="schoolactivity161" role="dialog">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content courseInformation schoolcourse ">
                            <div className="modal-body p-5 pb-3 mx-3">
                                <div className="sharewithfrnd ">
                                    <div className="closepopup_icon pointer" onClick={() => hideShowBox()}>
                                        <i class="fa-sharp fa-light fa-circle-xmark"></i>
                                    </div>
                                    <h3 className="text-center">Welcome to Bloomster!</h3>
                                    <p className="pt-2 mt-3 text-left" >Hi, {getCapitalized(loggedInUser?.firstName)}</p>
                                    <p className="mb-3 pb-2 pt-2 mt-3 text-left" >
                                        Congratulations on enrolling {getCapitalized(defaultChildData?.firstName)} in {getMyPlanData?.records[0]?.planName}. We have used some default assumptions to build a plan for an optimal learning experience. You can edit the plan schedule by clicking the Edit Plan button below.
                                    </p>
                                </div>
                                {loggedInUser?.role?.name === "PARENT" && (
                                    <div className="welcomeAgain_Poup skipnow p-5 pt-2 pb-2">
                                        <ul className="flex pointer">
                                            <li onClick={() => hideShowBox()}>
                                                <p className="pointer">Close</p>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                                <div className="buttonDistribotion justify-content-center flex-wrap mt-3 skipnow">

                                    <button
                                        type="button"
                                        className="btn-blue btn-login d-block mb-5 w-auto"
                                        onClick={() => showEditPlanScreen()}
                                    >
                                        <span>
                                            <i class="fa-light fa-pencil active ml-2"></i>
                                        </span>
                                        Edit Plan

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignUpPlanPopup;