import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import {
	getCalenderViewData,
	getInProgressCourse,
	getSequnceSort,
	handleClenderVaiew,
} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import RoundProgress from "../controls/RoundProgress";
import { textTrim } from "../../utils/helper";
import {
	useHistory,
	useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { Circle } from "rc-progress";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import {
	getDashBoarPlanSummaryData,
	intelligenceGrowthSurvey,
	reportSpecific,
	getDimensionCourses,
} from "../../redux/actions/APIs";
import {
	ShimmerCategoryList,
	ShimmerSimpleGallery,
	ShimmerTable,
} from "react-shimmer-effects";
import { breadcrumb, showModal, showPanel } from "../../redux/actions"; //
import GrowthSurveyRightPanel from "../learnerplan/GrowthSurveyRightPanel";

import { PATHS } from "../../utils";
import ReactTooltip from "react-tooltip";
import AddProfileImage from "./AddProfileImage";
import StudentGrowthSurvey from "./StudentGrowthSurvey";
import StudentAdvancementSummary from "./StudentAdvancementSummary";
import BurgerTab from "./BurgerTab";

const StudentDashBord = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const {
		defaultChildData,
		loggedInUser,
		specfReportData,
		intelligenceSurveyData,
		dashBoardplanSummaryData,
		showPanelObj,
		response_ok,
		getMyPlanData,
		modalData,
	} = useSelector((state) => state.collections);

	const reportType = location?.state?.type;
	const progressType = reportType === "OverallProgress" ? "Student Progress" :
		(reportType === "GrowthSurvey" ? "Growth Survey" :
			(reportType === "PlanSummary" ? "Advancement Summary" : "Dimensional Progress")
		)

	useEffect(() => {
		if (loggedInUser?.role?.name === "TEACHER") {
			dispatch(
				breadcrumb({
					title: "Report",
					subTitle: progressType,
					profileName: "Student Profile",
				})
			)
		} else {
			dispatch(
				breadcrumb({
					title: "Home",
					subTitle: "",
				})
			)
		}
		;
	}, [location?.state, loggedInUser]);

	useEffect(() => {
		let studentId = location?.state?.studentId
			? location?.state?.studentId
			: loggedInUser?.id;
		let courseId =
			loggedInUser?.role.name === "TEACHER" ? location?.state?.courseId : "";
		dispatch(reportSpecific(studentId, courseId));
		dispatch(intelligenceGrowthSurvey(studentId, courseId, reportType));
		dispatch(getDashBoarPlanSummaryData(studentId, courseId, reportType));
	}, [location?.state, loggedInUser]);

	// useEffect(() => {
	//     dispatch(reportSpecific(location?.state?.studentId));
	//     dispatch(intelligenceGrowthSurvey(defaultChildData?.id));
	//   }, []);

	const dimensionArr = [
		{ dimName: "Social", color: "#e8b62f", dimImage: image.courselevel4 },
		{
			dimName: "Emotional",
			color: "#dd6363",
			dimImage: image.CollegeJourneyicon,
		},
		{ dimName: "Intellectual", color: "#83b612", dimImage: image.courselevel3 },
	];

	const handleReports = (value, dimName, studentId, uservalue) => {
		history.push({
			pathname: PATHS.STUDENT_REPORTS,
			state: {
				type: value,
				dimObj: dimName ? dimName : "",
				studentId: studentId ? studentId : "",
				usertype: uservalue ? uservalue : "teacher",
				profileType: uservalue === "student" ? reportType : "",
				reportDim: location?.state?.dimObj,
			},
		});
	};

	const handleBackReview = () => {
		dispatch(showPanel());
	};

	useEffect(() => {
		if (getMyPlanData?.records?.length > 0) {
			const modalObj = handleClenderVaiew(getMyPlanData?.records[0]);
			dispatch(showModal(modalObj));
		}
	}, [getMyPlanData]);

	const [calenderData, setCalenderData] = useState([]);
	const [getCurrentCourse, setCurrentCourse] = useState();
	useEffect(() => {
		if (modalData?.data?.length > 0) {
			setCalenderData(getCalenderViewData(modalData));
		}
	}, [modalData]);

	useEffect(() => {
		if (calenderData?.length > 0) {
			setCurrentCourse(getInProgressCourse(calenderData));
		}
	}, [calenderData]);

	const callDimensionCoursesApi = () => {
		dispatch(
			getDimensionCourses(
				loggedInUser?.role?.name === "STUDENT"
					? loggedInUser.id
					: location?.state?.studentId
			)
		);
	};

	const [courseIndex, setCourseIndex] = useState(-1);
	const [activitiesData, setActivitiesData] = useState();
	const [specfCourseArr, setSpecfCourseArr] = useState([]);

	useEffect(() => {
		if (specfReportData?.records?.length > 0 && loggedInUser?.role.name === "STUDENT") {
			let courseObj = specfReportData?.records[0];
			setCourseIndex(courseObj?.courseSequence - 1);

			setActivitiesData(getSequnceSort(getSequnceSort(specfReportData?.records[0]?.courses)[courseObj?.courseSequence - 1]?.activities)[courseObj?.activitySequence - 1]);
		}
		if (specfReportData?.records?.length > 0) {
			setSpecfCourseArr(getSequnceSort(specfReportData?.records[0]?.courses));
		}
	}, [specfReportData])
	const [isDropDown, setIsDropDown] = useState(false);
	useEffect(() => {
		if (getCurrentCourse?.courseKey && ["OverallProgress", "connectedSkillReport"]?.includes(reportType)) {
			setCourseIndex(getCurrentCourse?.courseKey - 1);
			setActivitiesData(specfCourseArr[getCurrentCourse?.courseKey - 1]?.activities[0]);
			setIsDropDown(true);
			// let corKey = specfCourseArr?.findIndex((item)=>item?.name === getCurrentCourse?.courseName)
			// setCourseIndex(corKey);
			// setActivitiesData(specfCourseArr[corKey]?.activities[0]);
		}
	}, [getCurrentCourse, specfCourseArr])

	return (
		<>
			<div className="LeftbarPannel p-0" id="">
				<div className="CourseCardWrapper fullHeight100 ">
					<div class="form-title mt-0 mb-0 Enrollcoursetitle heading">
						<h2
							data-toggle="collapse"
							class="m-0 pt-0 pb-1 w-100 flex justify-content-between "
						>
							<span className="flexone">
								{" "}
								<img src={image.student_ProfileIcons} className="mr-2" alt="" />
								Student Profile
							</span>
							{loggedInUser?.role?.name === "TEACHER" && (
								<BurgerTab handleReports={handleReports} />
							)}
						</h2>
					</div>
					<div className="studentClsDetails Coursebanner">
						{specfReportData ? (
							specfReportData?.records?.map((student, index) => (
								<>
									{loggedInUser?.role?.name === "TEACHER" && (
										<div className="d-flex justify-content-between ps-3 pe-3 pt-3">
											<div className="backpageStrip p-0">
												<a
													href="javascript:void(0)"
													className="flexone"
													onClick={() =>
														handleReports(
															location?.state?.type,
															location?.state?.dimObj
														)
													}
												>
													<span className="">
														<i class="fa-solid fa-angle-left mr-1"></i>
													</span>
													Back
												</a>
											</div>

											<div className="disabledevent backpageStrip p-0">
												<a href="#">
													Next
													<span class="">
														<i class="fa-solid fa-angle-right ml-1"></i>
													</span>
												</a>
											</div>
										</div>
									)}
									<div className="CoursebannerWrap flexone align-items-start m-0 shadow-none pb-0">
										<div className="bannerleft studentProfileDiv">
											<AddProfileImage
												student={student}
												uploadProfileImage={callDimensionCoursesApi}
											/>
										</div>
										<div className="bannerright coursedetailbnr TeacherDashboard">
											<div className="PageTitle">
												<div className="reminderCourse m-0">
													<div className="reminderCourseContent pb-2">
														<div className="d-flex justify-content-between align-items-baseline">
															<div className="d-flex align-items-baseline ps-1">
																<h3 className="mb-2 flex">
																	{student?.childName}
																</h3>
																<p className="mb-3 ps-2 pt-2 emailAddress">
																	({student?.childEmail})
																</p>
															</div>
															<div>
																<h4>
																	<span className="pe-2">
																		<i
																			class="fa fa-calendar-o pe-2"
																			aria-hidden="true"
																		></i>
																		Next Session:
																	</span>
																	{getCurrentCourse?.date}
																</h4>
															</div>
														</div>

														<div className="d-flex justify-content-between">
															<div className="student_dash_brd_dtl">
																<div>
																	<span className="d-flex align-items-center courseHeading">
																		<h4 className="ps-1 ">
																			{reportType === "OverallProgress" &&
																				"Course in Progress"}
																		</h4>
																	</span>
																</div>
																{!["OverallProgress", "connectedSkillReport"]?.includes(reportType) ? (
																	<div className="d-flex justify-content-between ps-1">
																		<h4>
																			<span className="d-flex">
																				<div className="courseCircle me-2">
																					C{student.courseSequence}
																				</div>
																				{student.courseName}
																			</span>
																		</h4>
																	</div>
																) : (
																	<div className="d-flex justify-content-between ps-1 coursemodelDropdwn jumptopagescene dropdownlistmodl">
																		<h4 className="flex w-100 position-relative">
																			<span className="flexone pe-2">
																				<div className="courseCircle me-2">
																					C{specfCourseArr[courseIndex]?.sequence}
																				</div>
																				<React.Fragment>
																					{specfCourseArr[courseIndex]?.name.length > 35 ? (
																						<ReactTooltip id={specfCourseArr[courseIndex]?.name}>
																							<p>{specfCourseArr[courseIndex]?.name}</p>
																						</ReactTooltip>
																					) : (
																						""
																					)}
																					<div data-for={specfCourseArr[courseIndex]?.name} data-event-off="" data-tip>
																						<strong>
																							{textTrim(specfCourseArr[courseIndex]?.name, 35)}
																						</strong>
																					</div>
																				</React.Fragment>
																				{/* {modalData?.data[courseIndex]?.name} */}
																			</span>
																			<span
																				className="Activitymenubar pointer"
																				aria-haspopup="true"
																				aria-expanded="false"
																				id={`Coursesubdropdown`}
																				data-toggle="dropdown"
																			><i class="fa-regular fa-chevron-down"></i>
																			</span>

																			<ul className="dropdown-menu dropdown-menu-end coursedrpdwn" aria-labelledby={`Coursesubdropdown`}>
																				{
																					specfCourseArr?.map((course, index) => (
																						<React.Fragment key={index}>
																							<li className={`pointer d-flex align-items-center`} key={index} onClick={() => { setCourseIndex(index), setActivitiesData() }}>
																								<p className="text-left d-flex align-items-center w-100 p-0">
																									<span className="mr-1 jumponscene">C{index + 1}</span>
																									<span className="juweeknametext">
																										{/* {course?.name} */}
																										<React.Fragment>
																											{course?.name.length > 35 ? (
																												<ReactTooltip id={course?.name}>
																													<p>{course?.name}</p>
																												</ReactTooltip>
																											) : (
																												""
																											)}
																											<div data-for={course?.name} data-event-off="" data-tip>
																												<strong>
																													{textTrim(course?.name, 35)}
																												</strong>
																											</div>
																										</React.Fragment>

																									</span>
																								</p>
																							</li>
																						</React.Fragment>
																					))
																				}

																			</ul>


																		</h4>
																	</div>
																)}
																{!["OverallProgress", "connectedSkillReport"]?.includes(reportType) ? (
																	<div className="pt-3 ps-1 pb-4">
																		<h4>
																			<span className="pe-2 d-flex">
																				<div className="courseCircle modulecircleColor me-2">
																					M{student.activitySequence}
																				</div>
																				<React.Fragment>
																					{student.activityName.length > 35 ? (
																						<ReactTooltip id={student.activityName}>
																							<p>{student.activityName}</p>
																						</ReactTooltip>
																					) : (
																						""
																					)}
																					<div data-for={student.activityName} data-event-off="" data-tip>
																						<strong>
																							{textTrim(student.activityName, 35)}
																						</strong>
																					</div>
																				</React.Fragment>


																				{/* {student.activityName} */}
																			</span>
																		</h4>
																	</div>
																) : (
																	<div className="pt-3 ps-1 pb-4 coursemodelDropdwn jumptopagescene dropdownlistmodl">
																		<h4 className="flex w-100 position-relative">
																			{activitiesData && courseIndex >= 0 ? (
																				<span className="pe-2 flexone">
																					<div className="courseCircle modulecircleColor me-2">
																						M{activitiesData?.sequence}
																					</div>
																					<React.Fragment>
																						{activitiesData?.name.length > 35 ? (
																							<ReactTooltip id={activitiesData?.name}>
																								<p>{activitiesData?.name}</p>
																							</ReactTooltip>
																						) : (
																							""
																						)}
																						<div data-for={activitiesData?.name} data-event-off="" data-tip>
																							<strong>
																								{textTrim(activitiesData?.name, 35)}
																							</strong>
																						</div>
																					</React.Fragment>
																					{/* {activitiesData?.name} */}
																				</span>
																			) : (
																				<span>
																					--- Select Module ---
																				</span>
																			)}

																			<span
																				className="Activitymenubar pointer"
																				aria-haspopup="true"
																				aria-expanded="false"
																				id={`Modulesubdropdown`}
																				data-toggle="dropdown"
																			><i class="fa-regular fa-chevron-down"></i>
																			</span>
																			<ul className="dropdown-menu dropdown-menu-end" aria-labelledby={`Modulesubdropdown`}>
																				<li class="pointer d-flex align-items-center" onClick={() => setActivitiesData()}>--- Select Module ---</li>
																				{
																					getSequnceSort(specfCourseArr[courseIndex]?.activities)?.map((modal, key) => (
																						<React.Fragment key={key}>
																							<li className={`pointer d-flex align-items-center`} onClick={() => setActivitiesData(modal)}>
																								<p className="text-left d-flex align-items-center w-100 p-0">
																									<span className="mr-1 jumponscene">M{key + 1}</span>
																									<span className="juweeknametext">
																										<React.Fragment>
																											{modal?.name.length > 35 ? (
																												<ReactTooltip id={modal?.name}>
																													<p>{modal?.name}</p>
																												</ReactTooltip>
																											) : (
																												""
																											)}
																											<div data-for={modal?.name} data-event-off="" data-tip>
																												<strong>
																													{textTrim(modal?.name, 35)}
																												</strong>
																											</div>
																										</React.Fragment>

																										{/* {modal?.name} */}

																									</span>
																								</p>

																							</li>
																						</React.Fragment>
																					))
																				}
																			</ul>
																		</h4>
																	</div>
																)}
															</div>
															<div>
																<div className="SemicircleProgress text-center">
																	<SemiCircleProgressBar
																		percentage={isDropDown ? specfCourseArr[courseIndex]?.courseProgress : student?.courseProgress}
																		diameter={240}
																		strokeWidth={25}
																		showPercentValue
																	/>
																	<p>Progress</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{/* <div className="bannerright coursedetailbnr TeacherDashboard">
											<div className="PageTitle">
												<div className="reminderCourse m-0">
													<div className="reminderCourseContent pb-2">
														<div className="d-flex justify-content-between align-items-baseline">
															<div className="d-flex align-items-baseline ps-1 ">
																<h3 className="mb-2 flex">{student?.childName}</h3>
																<p className="mb-3 ps-2 pt-2 emailAddress">({student?.childEmail})</p>
															</div>

															<div>
																<h4>
																	<span className="pe-2">
																		<i class="fa fa-calendar-o pe-2" aria-hidden="true"></i>
																		Date:
																	</span>
																	{getMonthAndDayByDate(student?.courseDate)}
																</h4>
															</div>
														</div>
														
														<div className="d-flex justify-content-between">
															<div>
																<div>
																	<span className="d-flex align-items-center courseHeading">
																		<h4 className="ps-1">In Progress Course</h4>
																	</span>
																</div>
																<div className="d-flex justify-content-between ps-1">
																	<h4>
																		<span className="d-flex">
																			<div className="courseCircle me-2">C{student?.courseSequence}</div>
																			{student?.courseName}
																		</span>
																	</h4>
																</div>
																<div className="pt-3 ps-1 pb-4">
																	<h4>
																		<span className="pe-2 d-flex">
																			<div className="courseCircle me-2">M{student?.activitySequence}</div>
																			{student?.activityName}
																		</span>
																	</h4>
																</div>
															</div>
															<div>
																<div className="SemicircleProgress text-center">
																	<SemiCircleProgressBar percentage={student?.courseProgress} diameter={240} strokeWidth={25} showPercentValue />
																	<p>Progress</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div> */}
									</div>
									<div className="indivisualprogressivecourse p-3  ">
										{/* <h4 className="mt-3">
											<img src={image.mortarboard} alt="" className="mr-2" />
											In Progress Course
										</h4> */}
										<div className="flexone justify-content-baseline">
											{/* <div className="SemicircleProgress text-center">
												<SemiCircleProgressBar percentage={student?.courseProgress} diameter={240} strokeWidth={25} showPercentValue />
												<p>Progress</p>
											</div> */}
											<div className="progressstyl justify-content-between w-100">
												<span className="proficencyprog">
													<div className="position-relative Coursecompprcent m-auto p-0">
														<p className="">
															<SemiCircleProgressBar
																percentage={isDropDown ? specfCourseArr[courseIndex]?.courseCompletion : student?.courseCompletion}
																diameter={200}
																strokeWidth={20}
																stroke="#3ec093"
																showPercentValue
															/>
														</p>
														<p
															className="aboutProgdesc text-center"
															data-for="completion"
															data-event-off=""
															data-tip
														>
															Completion
															{/* <div className="Courseporogresspopup">
															<h4>Completion</h4>
															<p>
																Content accessed from the total available content.
															</p>
														</div> */}
															<ReactTooltip
																id="completion"
																place="top"
																className={"tooltip text-center"}
															>
																<h4>Completion</h4>
																<p>
																	Content accessed from the total available
																	content.
																</p>
															</ReactTooltip>
														</p>
													</div>
												</span>
												<span className="CompltProgress">
													<div className="position-relative Coursecompprcent m-auto p-0">
														<p className="">
															<SemiCircleProgressBar
																percentage={isDropDown ? specfCourseArr[courseIndex]?.courseProficiency : student?.courseProficiency}
																diameter={200}
																strokeWidth={20}
																stroke="#329fff"
																showPercentValue
															/>
														</p>
														<p
															className="aboutProgdesc"
															data-for="proficiency"
															data-event-off=""
															data-tip
														>
															Proficiency
															{/* <div className="Courseporogresspopup">
																<h4>Proficiency</h4>
																<p>
																	Comprehension of the subject matter.
																</p>
															</div> */}
															<ReactTooltip
																id="proficiency"
																place="top"
																className={"tooltip text-center"}
															>
																<h4>Proficiency</h4>
																<p>Comprehension of the subject matter.</p>
															</ReactTooltip>
														</p>
													</div>
												</span>

												<span className="TimProgress PaceProgress">
													<div className="position-relative Coursecompprcent m-auto p-0">
														<p className="">
															<SemiCircleProgressBar
																percentage={isDropDown ? specfCourseArr[courseIndex]?.coursePace : student?.coursePace}
																diameter={200}
																strokeWidth={20}
																stroke="#ffb300"
																showPercentValue
															/>
														</p>
														<p
															className="aboutProgdesc"
															data-for="pace"
															data-event-off=""
															data-tip
														>
															Pace
															{/* <div className="Courseporogresspopup">
																<h4>Pace</h4>
																<p>
																	Learning pace compared to the optimal pace.
																</p>
															</div> */}
															<ReactTooltip
																id="pace"
																place="top"
																className={"tooltip text-center"}
															>
																<h4>Pace</h4>
																<p>
																	Learning pace compared to the optimal pace.
																</p>
															</ReactTooltip>
														</p>
													</div>
												</span>

												{true && (
													<span className="TimProgress growth_Survey">
														<div className="position-relative Coursecompprcent m-auto p-0">
															<p className="">
																<SemiCircleProgressBar
																	percentage={isDropDown ? specfCourseArr[courseIndex]?.courseGrowthSurvey : student?.courseGrowthSurvey}
																	diameter={200}
																	strokeWidth={20}
																	stroke="#e46666"
																	showPercentValue
																/>
															</p>
															<p
																className="aboutProgdesc"
																data-for="growthSurvey"
																data-event-off=""
																data-tip
															>
																Growth Survey
																{/* <div className="Courseporogresspopup">
																<h4>Growth Survey</h4>
																<p>
																	Growth Survey Score.
																</p>
															</div> */}
																<ReactTooltip
																	id="growthSurvey"
																	place="top"
																	className={"tooltip text-center"}
																>
																	<h4>Growth Survey</h4>
																	<p>Growth Survey Score.</p>
																</ReactTooltip>
															</p>
														</div>
													</span>
												)}
											</div>
										</div>
									</div>

									{(loggedInUser?.role.name === "STUDENT" || reportType === "connectedSkillReport"
										// !["GrowthSurvey", "PlanSummary"]?.includes(reportType)
									) && (
											<div className="stuOvrallprg p-3 mt-3 flex-wrap TeacherDashboard">
												<h4 className=" w-100 text-left">
													<img
														src={image.DiemensionProgress}
														alt=""
														className="mr-2"
													/>
													Dimensional Progress
												</h4>

												<div className="d-flex justify-content-between">
													<p className="Coursecompprcent position-relative">
														<span>
															{" "}
															<span className="PercentCompleted">
																{student?.overAllProgress}
																<span className="percenrSign">%</span>
															</span>
															<Circle
																percent={student?.overAllProgress}
																strokeWidth={12}
																strokeColor="#4169e1"
															/>
														</span>
														<span className="d-flex justify-content-center align-items-center pt-3">
															<img
																src={image.overall_progress_icon}
																className="mr-2"
																alt=""
															/>
															<span className="circleNames">Aggregate</span>
														</span>
													</p>

													{getSequnceSort(student?.dimensions)?.map(
														(dim, key) =>
															key < 3 && (
																<p
																	className="Coursecompprcent position-relative pointer"
																	onClick={() =>
																		handleReports(
																			"connectedSkillReport",
																			dim,
																			location?.state?.studentId,
																			"student"
																		)
																	}
																>
																	<span>
																		{" "}
																		<span className="PercentCompleted">
																			{dim?.progress}
																			<span className="percenrSign">%</span>
																		</span>
																		<Circle
																			percent={dim?.progress}
																			strokeWidth={12}
																			strokeColor={dimensionArr[key]?.color}
																		/>
																	</span>
																	<span className="d-flex justify-content-center align-items-center pt-3">
																		<img
																			src={dimensionArr[key]?.dimImage}
																			className="mr-2"
																			alt=""
																		/>
																		<span className="circleNames">
																			{dim?.name}
																		</span>
																	</span>
																</p>
															)
													)}
												</div>
											</div>
										)
									}
								</>
							))
						) : (
							<ShimmerCategoryList
								title
								items={6}
								categoryStyle="STYLE_SEVEN"
							/>
						)}

						{(loggedInUser?.role.name === "STUDENT" ||
							reportType === "GrowthSurvey" ||
							(["OverallProgress", "connectedSkillReport"]?.includes(reportType) && specfCourseArr[courseIndex]?.isGrowthSurvey)) && (
								<StudentGrowthSurvey
									intelligenceSurveyData={intelligenceSurveyData}
									courseObj={courseIndex > -1 && specfCourseArr[courseIndex]}
									reportType={reportType}
								/>
							)}

						{
							(loggedInUser?.role.name === "STUDENT" ||
								reportType === "PlanSummary" ||
								(["OverallProgress", "connectedSkillReport"]?.includes(reportType) && dashBoardplanSummaryData?.records?.find((item) => item?.id === specfCourseArr[courseIndex]?.id)))
							&& (
								<>
									{dashBoardplanSummaryData ? (
										<div className="StuPlanSummy mt-3 p-3">
											<StudentAdvancementSummary dashBoardplanSummaryData={dashBoardplanSummaryData?.records}
												activitiesData={activitiesData}
												reportType={reportType}
												courseObj={courseIndex > -1 && specfCourseArr[courseIndex]}
											/>
										</div>
									) : (
										<ShimmerTable row={4} col={4} />
									)}
								</>
							)}
					</div>
				</div>
			</div>
			{showPanelObj?.type === "showGrowthSurveyPanel" && (
				<GrowthSurveyRightPanel handleBackReview={handleBackReview} />
			)}
		</>
	);
};
export default StudentDashBord;
