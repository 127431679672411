import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { useDispatch, useSelector } from "react-redux";
import { showTeacherReport } from "../../redux/actions";
import {
  getKcReportData,
  getKcReportDataForCheckbox,
} from "../../redux/actions/APIs";
import { ShimmerCategoryList } from "react-shimmer-effects";

const TeacherReport = ({ parentId }) => {
  const [questionData, setQuestionData] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const {
    kcReportsData,
    showTeacherReportData,
    kcReportsDataForCheckbox,
  } = useSelector((state) => state.collections);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (showTeacherReportData.quesId.type === "CHECKBOXQ") {
        await dispatch(
          getKcReportDataForCheckbox(showTeacherReportData.quesId.id, parentId)
        );
      } else {
        await dispatch(
          getKcReportData(showTeacherReportData.quesId.id, parentId)
        );
      }
      setLoading(false);
    };

    if (showTeacherReportData.quesId.id) {
      fetchData();
    }
  }, [dispatch, showTeacherReportData.quesId.id, parentId]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     await dispatch(getKcReportData(showTeacherReportData.quesId, parentId));
  //     setLoading(false);
  //   };

  //   if (showTeacherReportData.quesId) {
  //     fetchData();
  //   }
  // }, [dispatch, showTeacherReportData.quesId, parentId]);

  const addOptionSequenceToReportData = (data) => {
    if (!data) return;

    ["firstAttemptKC", "secondAttemptKC", "thirdAttemptKC"].forEach(attempt => {
      if (data[attempt]) {
        data[attempt].rightBar.optionsequence = 1;
        data[attempt].wrongBar.optionsequence = 2;
      }
    });
  };

  useEffect(() => {
    if (kcReportsData) {
      setQuestionData(kcReportsData.records[0] || {});
    } else if (kcReportsDataForCheckbox) {
      // setQuestionData(kcReportsDataForCheckbox.records[0] || {});
      const reportData = kcReportsDataForCheckbox.records[0] || {};
      addOptionSequenceToReportData(reportData);
      setQuestionData(reportData);
    }
  }, [kcReportsData, kcReportsDataForCheckbox]);

  const calculateHeight = (attemptedCount, totalStudents) => {
    if (totalStudents === 0) return "0%";
    const maxHeight = 250;
    const percentage = (attemptedCount / totalStudents) * 100;
    const height = (percentage / 100) * maxHeight;
    return `${height}px`;
  };

  const renderTooltip = (students) => {
    return students?.map((student) => (
      <div key={student.id} className="tooltip-contents">
        <i className="fas fa-user-alt pe-1"></i>
        <span>{student.name}</span>
      </div>
    ));
  };

  const getAttemptData = (attempt) => {
    if (!questionData[attempt]) return [];
    return Object.keys(questionData[attempt])
      ?.filter((optionKey) => optionKey !== "name")
      ?.map((optionKey) => ({
        option: optionKey,
        attemptedCount: questionData[attempt][optionKey]?.attemptedCount || 0,
        students: questionData[attempt][optionKey]?.students || [],
        optionsequence: questionData[attempt][optionKey]?.optionsequence,
      }));
  };

  // const isCorrectOption = (optionSequence) => {
  //   return ["firstAttemptKC", "secondAttemptKC", "thirdAttemptKC"].some(
  //     (attempt) => {
  //       const attemptData = questionData[attempt];
  //       return (
  //         attemptData &&
  //         Object.values(attemptData).some(
  //           (option) =>
  //             option.optionsequence === optionSequence && option.isCorrect
  //         )
  //       );
  //     }
  //   );
  // };

  const isCorrectOption = (sequence) => {
    // const options = kcReportsData?.records[0]?.options;
    const option = kcReportsData.records[0].options.find((opt) => opt.sequence === sequence);
    return option?.isCorrect || false;
  };

  const handleClose = () => {
    if (showTeacherReportData.quesId.type === "CHECKBOXQ") {
      dispatch(getKcReportDataForCheckbox());
    } else if (showTeacherReportData.quesId.type === "RADIOQ") {
      dispatch(getKcReportData());
    }
    dispatch(showTeacherReport());
  }

  return (
    <div className="newinfo_popup holisticview">
      <div
        className="modal d-flex newEnrollCoursPopup"
        id="schoolactivity73"
        role="dialog"
      >
        <div className="modal-dialog kc-report-modal">
          <div className="modal-content">
            <div className="modal-header">
              <div className="heading border-0 p-0 w-100">
                <h2 className="flex">
                  <span>
                    <img src={image.graphimg} className="mr-2" alt="graph" />
                    Class Question 1 Attempts
                  </span>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleClose()}
                  >
                    <i className="fa-regular fa-xmark m-0"></i>
                  </button>
                </h2>
              </div>
            </div>

            <div className="modal-body p-0">
              <div className="kcReportParentDiv">
                {loading ? (
                  <ShimmerCategoryList items={3} categoryStyle="STYLE_TWO" />
                ) : (
                  <div className="kc-report">
                    <div>
                      <div className="line-div" />
                      <hr className="line1" />
                      <hr className="line2" />
                      <hr className="line3" />
                      <hr className="line4" />
                    </div>

                    <div className="chart">
                      <div className="number-count">
                        <p>100</p>
                        <p>75</p>
                        <p>50</p>
                        <p>25</p>
                      </div>

                      {[
                        "firstAttemptKC",
                        "secondAttemptKC",
                        "thirdAttemptKC",
                      ]?.map((attempt, index) => (
                        <div className={`attempt ${showTeacherReportData.quesId.type === "CHECKBOXQ" ? "attemptCheckboxTwoProgess" : ""}`} key={index}>
                          {getAttemptData(attempt)?.map(({ option, attemptedCount, students, optionsequence, }, idx
                          ) => (
                            <div
                              className={`bar option option${optionsequence} ${showTeacherReportData.quesId.type === "CHECKBOXQ" && optionsequence === 2 ? "red-color" : ""}`}
                              style={{
                                height: calculateHeight(
                                  attemptedCount,
                                  questionData.totalStudents
                                ),
                              }}
                              key={idx}
                            >
                              <div className="tooltip-main-div mt-3">
                                <div className="tooltip-child-div">
                                  {renderTooltip(students)}
                                </div>
                              </div>
                            </div>
                          )
                          )}
                        </div>
                      ))}
                    </div>

                    <div className="attempt-div">
                      <div className="label">1st Attempt</div>
                      <div className="label">2nd Attempt</div>
                      <div className="label">3rd Attempt</div>
                    </div>
                    <div className="legend">

                      <div className="d-flex align-items-center">
                        <span className="legend-color option1"></span>
                        Option 1<b>{isCorrectOption(1) && "*"}</b>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="legend-color option2"></span>
                        Option 2<b>{isCorrectOption(2) && "*"}</b>
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="legend-color option3"></span>
                        Option 3<b>{isCorrectOption(3) && "*"}</b>
                      </div>

                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="form-group BDsubmitbutton m-0 d-flex">
                <div className="buttonDistribotion">
                  <button
                    type="button"
                    className="btn-blue btn-login d-block mb-5 cancelbutton"
                    onClick={() => handleClose()}
                  >
                    <i className="fa-solid fa-xmark"></i> Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TeacherReport;
