import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	datesorting,
	getCapitalized,
	getChildrenSort,
	getName,
	getUrlSegment,
	getUserIcon,
	nameSorting,
} from "../../utils/helper";
import { useParams, useHistory } from "react-router-dom";
import { PATHS } from "../../utils";
import { defaultChild, getChildName, showModal } from "../../redux/actions";
import { deleteDimension, getDashBoarPlanSummaryData, getJournyData, getMyPlan, intelligenceGrowthSurvey, reportSpecific } from "../../redux/actions/APIs";
import {
	dimView,
	getDashboard,
	sideBarProgress,
} from "../../redux/actions/Home";
import { textTrim } from "../../utils/helper";
import ReactTooltip from "react-tooltip";
import { noop } from "jquery";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const BreadcrumbUser = ({ data, dimredirect }) => {
	const { loggedInUser, selectedDim, dimension, dashboardData, breadcrumbData } = useSelector(
		(state) => state.collections
	);
	const history = useHistory();
	const dispatch = useDispatch();
	const param = useParams();
	const [getUser, setUser] = useState(false);
	const location = useLocation();

	const userChange = (obj, data) => {
		dispatch(getDashboard());
		dispatch(sideBarProgress());
		dispatch(getMyPlan(obj?.id));
		if (data === "home") {
			//dispatch(getDim)
			dispatch(dimView());
			dispatch(defaultChild(obj));
			dispatch(getDashboard(obj?.id));

			history.push(PATHS.HOME + "?learnerId=" + obj?.id);
		} else if (getUrlSegment()[1] === "parent-courses") {
			history.push(PATHS.PARENT_COURSE_STR + obj?.id);
		} else if (data === "courses") {
			history.push(PATHS.COURSEPAGE_STR + obj?.id);
		} else if (data === "pathway") {
			dispatch(getChildName());
			history.push(PATHS.LEARNER_PATHWAY_STR + obj?.id);
			/*
				if (obj && obj?.isJourney) {
				  history.push(PATHS.LEARNER_JOURNEY_STR + obj?.id);
				} else {
				  dispatch(
					showModal({
					  type: "journeySkills",
					  childId: obj?.id,
					})
				  );
				  dispatch(getChildName(obj?.firstName));
				}
				*/
		} else if (data === "learnerplan") {
			if (obj?.id !== param?.id) {
				history.push(PATHS.HOME);
				dispatch(defaultChild(obj));
				dispatch(getDashboard(obj?.id));
			}
			else {
				history.push(PATHS.STR_LEARNER_PLAN + obj?.id)
			}
		} else if (data === "myplan") {
			dispatch(getMyPlan())
			if (obj?.isUserPlan) {
				history.push(PATHS.STR_MYPLAN + obj?.id);
			} else {
				dispatch(defaultChild(obj));
				dispatch(showModal({ type: "MyLearnerPlanRedirectPopup" }));
				history.push(PATHS.HOME);
			}
		} else if (data === "reports") {
			if (location?.state?.usertype === "student") {
				history.push({
					pathname: PATHS.STUDENT_REPORTS,
					state: {
						type: location?.state?.type,
						dimObj: location?.state?.dimObj,
						studentId: obj?.id,
						usertype: location?.state?.usertype,
					},
				});
			} else {
				history.push(PATHS.HOME);
			}
			dispatch(defaultChild(obj));
		}
		else if (data === "studentprofile") {
			dispatch(defaultChild(obj))
			history.push({
				pathname: PATHS.STUDENT_PROFILE,
				state: {
					studentId: obj?.id,
					courseId: location?.state?.courseId,
					type: location?.state?.type,
					studentType: location?.state?.studentType,
					dimObj: location?.state?.dimObj,
				},
			});
			dispatch(getMyPlan())
			dispatch(reportSpecific());
			dispatch(intelligenceGrowthSurvey());
			dispatch(getDashBoarPlanSummaryData());
		}
		else {
			dimredirect(obj.id);
		}
	};
	// useEffect(() => {
	// 	if (dashboardData?.userPlans?.length > 0 && getUser) {
	// 		console.log("getUser12 : ", getUser);
	// 		setUser(false);
	// 		history.push(PATHS.HOME);
	// 	}
	// }, [dashboardData])

	const [userNameBySeqUence, setUserNameBySeqUence] = useState([]);

	useEffect(() => {
		let userArr = [];
		let myUserArr = [];
		if (loggedInUser?.children.length > 1) {
			datesorting(loggedInUser?.children)?.map((user, index) => {
				userArr?.push(user);
			})
			setUserNameBySeqUence(userArr);
			if (loggedInUser?.role?.name === "TEACHER") {
				myUserArr?.push(userArr[0]);
				userArr?.splice(0, 1);
				nameSorting(userArr)?.map((user, key) => {
					myUserArr?.push(user);
				})
				setUserNameBySeqUence(myUserArr);
			}

		}
	}, [loggedInUser?.role?.name])

	return (
		<div className='VKprofile' key={data?.id}>
			<div className='vkprofilename'>
				<span className='insceptionFilter  ml-auto '>
					<div
						id='navbarDropdown'
						className='text-dark'
						role='button'
						data-toggle='dropdown'
						aria-haspopup='true'
						aria-expanded='false'>
						{getUserIcon(data, loggedInUser)}
						<span>
							{data?.id === loggedInUser?.children[0]?.id && loggedInUser?.role?.name === "TEACHER" ? (
								data?.firstName + " " + data?.lastName
							) : (
								textTrim(getCapitalized(getName(data), 20))
							)}

						</span>
						{loggedInUser?.children?.length > 1 &&
							(getUrlSegment()[0] === "home" ||
								getUrlSegment()[0] === "dimensions" ||
								getUrlSegment()[0] === "courses" ||
								getUrlSegment()[0] === "pathway" ||
								getUrlSegment()[0] === "learnerplan" ||
								getUrlSegment()[0] === "myplan" ||
								getUrlSegment()[0] === "reports" ||
								getUrlSegment()[0] === "studentprofile" ||
								getUrlSegment()[1] === "parent-courses") ? (
							<span>
								<i className='fa-regular fa-chevron-down' />
							</span>
						) : (
							""
						)}
					</div>
					{loggedInUser?.children.length > 1 &&
						(getUrlSegment()[0] === "home" ||
							getUrlSegment()[0] === "dimensions" ||
							getUrlSegment()[0] === "courses" ||
							getUrlSegment()[0] === "pathway" ||
							getUrlSegment()[0] === "learnerplan" ||
							getUrlSegment()[0] === "myplan" ||
							getUrlSegment()[0] === "reports" ||
							getUrlSegment()[0] === "studentprofile" ||
							getUrlSegment()[1] === "parent-courses") ? (
						<li className='Brumprofile'>
							<div
								className='Prifg dropdown-menu '
								aria-labelledby='navbarDropdown'>
								<ul className='w-100'>
									{getChildrenSort(loggedInUser)?.map((vl, ky) => (
										<li
											key={ky}
											onClick={() => userChange(vl, getUrlSegment()[0])}>
											<span data-for={getName(vl)} data-event-off='' data-tip>
												<i className='fa-regular fa-user mr-2'></i>

												{ky == 0 && loggedInUser?.role?.name === "TEACHER" ? (
													loggedInUser?.children[0]?.firstName + " " + loggedInUser?.children[0]?.lastName
												) : (
													textTrim(getCapitalized(getName(vl), 18))
												)}

											</span>
										</li>
									))}
								</ul>
							</div>
						</li>
					) : (
						""
					)}
				</span>
			</div>
		</div>
	);
};

export default BreadcrumbUser;
