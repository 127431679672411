import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { getProfileName, getSequnceSort, nameSorting } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import RoundProgress from "../controls/RoundProgress";
import { textTrim } from "../../utils/helper";
import { ShimmerTable } from "react-shimmer-effects";
import ReactTooltip from "react-tooltip";
import { intelligenceGrowthSurvey } from "../../redux/actions/APIs";
import { showPanel } from "../../redux/actions";

const GrowthSurveyReport = ({ growthData, showStudentProfile }) => {

	const {
		intelligenceSurveyData
	  } = useSelector((state) => state.collections);

	const dispatch = useDispatch();  
	const [openAccordionIndex, setOpenAccordionIndex] = useState(0);
	const [clickProgress,  setClickProgress] = useState();
	const [cId, setCId] = useState();
	const [questionType,setQuestionType] = useState();
	let growthArr = [0, 1];

	const toggleAccordion = (index) => {
		dispatch(showPanel());
		setOpenAccordionIndex(openAccordionIndex === index ? null : index);
	}

	useEffect( () => {
		if(intelligenceSurveyData) {
			let combinedSurveyQuestions = [];
			let data = intelligenceSurveyData?.records[0]?.courses?.find(element => element?.courseId === cId);

			if(clickProgress === 'pre') {
				combinedSurveyQuestions = questionType === 'prebehaviour'
				? [...(data?.preGrowthSurveyBehavior || [])]
				: [...(data?.preGrowthSurveyScenario || []), ...(data?.preGrowthSurveyKnowledge || [])];
			}else if(clickProgress === 'post') {
				combinedSurveyQuestions = questionType === 'postbehaviour'
				? [...(data?.postGrowthSurveyBehavior || [])]
				: [...data?.postGrowthSurveyScenario || [], ...(data?.postGrowthSurveyKnowledge || [])];
			}

			if(combinedSurveyQuestions && combinedSurveyQuestions != undefined) {
				dispatch(
					showPanel({
					  type: "showGrowthSurveyPanel",
					  growthData: combinedSurveyQuestions,
					  stage: 'Preview',
					  clickProgress: clickProgress === 'pre' ? true : false,
					  courseId: cId,
					  questionType : questionType,	
					})
				  );
			}
		}
	}, [intelligenceSurveyData,questionType]);

	const handleShow = (studentId,courseId,progressName,questionType) => {
		setClickProgress(progressName);
		setCId(courseId);
		setQuestionType(questionType);
		dispatch(intelligenceGrowthSurvey(studentId, courseId, "Growth Survey"));
	}

	return (
		<>
			{
				growthData?.length > 0 ? (
					<div className="Course_report">
						{growthData?.sort((a, b) => a.courserSequence - b.courserSequence)?.map((course, index) => (
							<div className="AccordianTabl mb-3 mt-3 growthServy_tbl">
								<h5>
									<div className="PlanTblCourse_Name"> <span>C{course?.courserSequence}</span>{course?.courseName}</div>
									{/* <div className="PlanTblModule_Name"><span>M1</span>Introduction to Self-Awareness</div>
									<span className="inprogressbatchtbl">In-Progress</span> */}
									<span
										className="GrowthSurvytbl pointer"
										// data-toggle="collapse"
										href={"#growthservytbl0" + index}
										// aria-expanded="false"
										aria-expanded={openAccordionIndex === index}
										onClick={() => toggleAccordion(index)}
									>
										<i class="fa-light fa-chevron-down"></i>
									</span>
								</h5>
								<table
									className={`GrowthSurvytbl panel-collapse collapse ${openAccordionIndex === index ? "show" : ""}`}
									id={"growthservytbl0" + index}
								>
									<tr>
										<td className="blank_td"></td>
										<td className="blank_td"></td>
										<td colSpan="4" className="PgrowSer">
											Pre Growth Survey{" "}
										</td>
										<td colSpan="4" className="PgrowSer">
											Post Growth Survey{" "}
										</td>
									</tr>
									<tr>
										<th>#</th>
										<th>Student</th>
										<th>Behavior</th>
										<th>Scenario </th>
										<th>Knowledge</th>
										<th>Score</th>
										<th>Behavior</th>
										<th>Scenario </th>
										<th>Knowledge</th>
										<th>Score</th>
									</tr>
									{
										growthArr?.map((data, myKey) => {
											let progress = myKey > 0 ? "inProgress" : "outstanding";
											return (
												nameSorting(course[progress], "studentLastName")?.map((student, key) => (
													<tr key={key}>
														<td>{key + (myKey === 0 ? 1 : 1 * (course["outstanding"]?.length > 0 ? course["outstanding"]?.length + 1 : 1))}</td>
														<td>
															<div className="Student_nameid flexone pointer" onClick={() => {showStudentProfile(student?.id, course?.courseId); dispatch(showPanel());}}>
																<div className="StudentIng">
																	{
																		student?.studentImageUrl ?
																			<img src={student?.studentImageUrl} />
																			: getProfileName(student?.studentName)
																	}
																</div>
																<div className="StudentNameed">
																	<React.Fragment>
																		{student?.studentName?.length > 22 ? (
																			<ReactTooltip id={student?.studentName}>
																				<p>{student?.studentName}</p>
																			</ReactTooltip>
																		) : (
																			""
																		)}
																		<div data-for={student?.studentName} data-event-off="" data-tip>
																			<strong>
																				{textTrim(student?.studentName, 22)}
																			</strong>
																		</div>
																	</React.Fragment>

																</div>
															</div>
														</td>
														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto ${student?.preTotal > 0 ? "pointer" : ""}`} onClick={() => {student?.preTotal > 0 && handleShow(student?.id,course?.courseId, 'pre',  'prebehaviour')}}>

																	<p className="">
																		<RoundProgress data={student?.preBehavior} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>
														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto ${student?.preTotal > 0 ? "pointer" : ""}`} onClick={() => {student?.preTotal > 0 && handleShow(student?.id,course?.courseId, 'pre', 'prescenerio')}}>
																	<p className="">
																		<RoundProgress data={student?.preScenario} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>

														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto ${student?.preTotal > 0 ? "pointer" : ""}`} onClick={() => {student?.preTotal > 0 && handleShow(student?.id,course?.courseId, 'pre', 'preknowledge')}}>
																	<p className="">
																		<RoundProgress data={student?.preKnowledge} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>
														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto`} >
																	<p className="">
																		<RoundProgress data={student?.preTotal} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>
														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto ${student?.postTotal > 0 ? "pointer" : ""}`} onClick={() => {student?.postTotal > 0  && handleShow(student?.id,course?.courseId, 'post', 'postbehaviour')}}>
																	<p className="">
																		<RoundProgress data={student?.postBehavior} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>
														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto ${student?.postTotal > 0 ? "pointer" : ""}`} onClick={() => {student?.postTotal > 0 && handleShow(student?.id,course?.courseId, 'post', 'postscenerio')}}>
																	<p className="">
																		<RoundProgress data={student?.postScenario} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>

														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto ${student?.postTotal > 0 ? "pointer" : ""}`} onClick={() => {student?.postTotal > 0 && handleShow(student?.id,course?.courseId, 'post', 'postknowledge')}}>
																	<p className="">
																		<RoundProgress data={student?.postKnowledge} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>
														<td>
															<div className="round_progres_">
																<div className={`position-relative Coursecompprcent m-auto`} >
																	<p className="">
																		<RoundProgress data={student?.postTotal} type={true} className="m-1" />
																	</p>
																</div>
															</div>

														</td>
													</tr>
												))
											);
										})
									}

								</table>
							</div>
						))}
					</div>
				) : (
					<ShimmerTable row={5} col={5} />
				)
			}
		</>
	);
};
export default GrowthSurveyReport;
