import React, { useEffect, useState } from 'react'
import { getProfileName } from '../../utils/helper'
import * as image from "../../resources/images";
import Cropper from "react-easy-crop";
import getCroppedImg from "../profile/CropImage";
import {
	updateUserProfilePicture,
	deleteAvatar,
} from "../../redux/actions/APIs";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, useForm } from "../../utils/Packages";

const AddProfileImage = ({ student, zoomInit, rotationInit, cropInit, aspectInit, uploadProfileImage }) => {

    const aspectRatios = [
		{ value: 4 / 3, text: "4/3" },
		{ value: 16 / 9, text: "16/9" },
		{ value: 1 / 2, text: "1/2" },
	];

    const {
		defaultChildData,
		loggedInUser,
		specfReportData,
		response_ok,
	} = useSelector((state) => state.collections);

    // console.log('@@@ student' , student)

    if (zoomInit == null) {
		zoomInit = 1;
	}
	if (cropInit == null) {
		cropInit = { x: 0, y: 0 };
	}
	if (rotationInit == null) {
		rotationInit = 1;
	}
	if (aspectInit == null) {
		aspectInit = aspectRatios[0];
	}

    const dispatch = useDispatch();
	const [zoom, setZoom] = useState(zoomInit);
	const [crop, setCrop] = useState(cropInit);
	const [quote, setQuote] = useState("");
	const [aspect, setAspect] = useState(aspectInit);
	const [imgSrc, setImgSrc] = useState(undefined);
	const [imageLoading, setImageLoading] = useState(false);
	const [croppedArea, setCroppedArea] = useState(null);
	const [rotation, setRotation] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [editState, setEditState] = useState(true);
	const [fileError, setFileError] = useState("");

    useEffect(() => {
		if (imgSrc) {
			// console.log('#### step 1 imgSrc' , imgSrc)
			setEditState(true);
		}
	}, [imgSrc]);

    const showUploadModal = () => {
        // console.log('#### step 1')
		setIsOpen(true);
		setZoom(zoomInit);
		setCrop(cropInit);
		setRotation(0);
		if (student?.childImageUrl) {
			setEditState(true);
		} else {
			setEditState(false);
		}
		// setZoom();
		// setRotation();
	};

    useEffect(() => {
		if (student?.childId === null) {
			zoomInit = 1;
			rotationInit = 1;
			setZoom(zoomInit);
			setRotation(rotationInit);
		}
	}, [
		student?.childImageUrl,
		// loggedInUser?.imageUrl === null,
	]);

	const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
		// console.log('croppedAreaPixels onCropComplete' , croppedAreaPixels)
		setCroppedArea(croppedAreaPixels);
	};

	const onRotationChange = (rotation) => {
		// console.log('croppedAreaPixels onRotationChange' , rotation)
		setRotation(rotation);
	};

	useEffect(() => {
		if (response_ok?.success) {
			setIsOpen(false);
		}
	}, [response_ok]);

	const inputRef = React.useRef();

	const triggerFileSelectPopup = () => {
		inputRef.current.click();
		// setImgSrc("")
		//setEditState(true);
	};

	console.log('@@@ student student?.childId' , student)

	function onSelectFile(e) {
        // console.log('#### step 2' , e.target.files)
		const input = document.getElementById("fileimg");
		if (e.target.files && e.target.files.length > 0) {
			if (e.target.files[0]?.size > 5242880) {
				input.value = "";
				setFileError("Choose file size less than 5 MB");
				return;
			} else {
				setFileError("");
			}
			const reader = new FileReader();
			reader.readAsDataURL(e.target.files[0]);
			reader.addEventListener("load", () => {
				setImgSrc(reader.result.toString() || "");
			});
		}
	}

	function dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, { type: mime });
	}

	const onUpload = () => {
		getCroppedImg(imgSrc, croppedArea, rotation).then((res) => {
            // console.log('@@@ student student?.childId' , student?.childId)
			// console.log('croppedAreaPixels onUpload croppedArea' , croppedArea)
			// console.log('croppedAreaPixels onUpload rotation' , rotation)

			const reader = new FileReader();
			reader.onloadend = () => {
				const base64data = reader.result;
				var file = dataURLtoFile(base64data, "image.jpg");
				// dispatch(updateUserProfilePicture(file, loggedInUser.role.name === "STUDENT" ? loggedInUser.id : student.childId));
                dispatch(updateUserProfilePicture(file, student?.childId));
			};

			(async () => {
				const response = await fetch(res);
				const imageBlob = await response.blob();
				reader.readAsDataURL(imageBlob);
			})();
		});

		setIsOpen(false);
		setEditState(false);

		setTimeout(() => {
			uploadProfileImage();
			setImgSrc(undefined);
		}, 3000);
	};

	const removeAvatar = async (e) => {
		var url = student?.childImageUrl;
		var pathname = new URL(url).pathname.split("/")[2];
		
		// dispatch(deleteAvatar(loggedInUser.role.name === "STUDENT" ? loggedInUser.id : student.childId, pathname));
        dispatch(deleteAvatar(student?.childId, pathname));
		setEditState(false);
		setIsOpen(false);
		setTimeout(() => {
			uploadProfileImage();
			setImgSrc(null);
		}, 3000);
	};

	const onCropChange = (crop) => {
		// console.log('croppedAreaPixels onCropChange' , crop)
		setCrop(crop);
	};

	const onZoomChange = (zoom) => {
		// console.log('croppedAreaPixels onZoomChange' , zoom)
		setZoom(zoom);
	};

	// console.log('@@@ student student?.childId remove' , student)

  return (
    <>
    <div>
      <div className="coursedtlImage flextwo studtlinitials">
        <p>
            {student?.childImageUrl
                ? <img src={student?.childImageUrl} className='mr-2' alt="" />
                : getProfileName(student?.childName)}
        </p>
        </div>
        <div className="uploadStuProf flextwo pointer" onClick={() => showUploadModal()}>
            <p><img src={image.camera} className="mr-2" /> Upload</p>
        </div>
    </div>
    <div className="BDsubmitbutton Addchieldbtn  addEnrichbtn  pr-10">
					<Modal
						show={isOpen}
						className="AddinterestWrapper AddChildPopup UploadProimagepopup"
					>
						<Modal.Header>
							<Modal.Title>
								{student?.childImageUrl === null ? (
									<p>
										<i class="fa fa-camera mr-1 addphoto"></i> Add Photo
									</p>
								) : (
									<p>Edit Photo</p>
								)}
							</Modal.Title>

							{editState === false ? (
								<Button
									data-dismiss="modal"
									onClick={() => {
										setIsOpen(false);
										setImgSrc(undefined);
										setEditState(true);
									}}
								>
									<span>
										{" "}
										<i className="fa-regular fa-xmark m-0"></i>
									</span>
								</Button>
							) : (
								<Button
									onClick={() => {
										setIsOpen(false);
										setImgSrc(undefined);
										setEditState(true);
									}}
								>
									{" "}
									<span>
										{" "}
										<i className="fa-regular fa-xmark m-0"></i>
									</span>
								</Button>
							)}
						</Modal.Header>

						<Modal.Body>
							<div className="wrapper mx-auto">
								<form id="imgFrm" className="content imgFrm">
									{editState === false && (
										<div className="uploadedPhoto center_img">
											{student?.childImageUrl ? (
												<img
													src={student?.childImageUrl}
													alt=""
												/>
											) : (
												<span> {getProfileName(student?.childName)}</span>
											)}
										</div>
									)}

									<div className="UploadPhoto">
										{editState === true ? (
											imageLoading && (!imgSrc || !crop) ? (
												<div
													data-testid="container"
													className="reactEasyCrop_Container"
												>
													<FadeLoader color={lodercolor}></FadeLoader>
												</div>
											) : (
												<React.Fragment>
                                                    {/* {console.log('#### step 4' , imgSrc)} */}
													<Cropper
														image={
															imgSrc
																? imgSrc
																: student?.childImageUrl
														}
														// image={imgSrc}
														crop={crop}
														rotation={rotation}
														zoom={zoom}
														cropShape="round"
														showGrid={false}
														aspect={3 / 3}
														// aspect={aspect.value}

														onCropChange={onCropChange}
														onZoomChange={onZoomChange}
														onCropComplete={onCropComplete}
														onRotationChange={onRotationChange}
													/>
												</React.Fragment>
											)
										) : (
											""
										)}

										<div className="photoUploded">
											<div className="input-group d-flex">
												<div className="form-group ">
													<input
														type="file"
														accept="image/*"
														id="fileimg"
														ref={inputRef}
														style={{ display: "none" }}
														onChange={onSelectFile}
													/>

													{fileError && (
														<p className="text-danger">{fileError}</p>
													)}
												</div>
											</div>

											<div className="BDsubmitbutton ">
												<div className="Editbuttons d-flex justify-content-between flex-wrap">
													{editState === false ? (
														student?.childImageUrl ===
															null || editState === false ? (
															<>
																<button
																	className="add_btn"
																	onClick={triggerFileSelectPopup}
																	type="button"
																>
																	{" "}
																	<i className="fa fa-camera"></i> <br /> Add
																	Photo
																</button>
															</>
														) : (
															<button
																className="edit_btn"
																onClick={() => {
																	setEditState(true);
																}}
																type="button"
															>
																{" "}
																<i className="fa fa-pencil"></i> <br /> Edit
															</button>
														)
													) : (
														<>
															{imgSrc && (
																<div className="slider custom-labels w-100 flex">
																	<div className="Editprofilepic">
																		<label className="w-100">Zoom</label>

																		<input
																			type="range"
																			min={1}
																			max={3}
																			step={0.1}
																			value={zoom}
																			onInput={(e) => {
																				onZoomChange(e.target.value);
																			}}
																		/>
																	</div>

																	<div className="Editprofilepic">
																		<label className="w-100">Rotate</label>
																		<input
																			type="range"
																			min={0}
																			max={360}
																			value={rotation}
																			onInput={(e) => {
																				onRotationChange(e.target.value);
																			}}
																		/>{" "}
																	</div>
																</div>
															)}
														</>
													)}

													{editState ? (
														<>
															<button
																className="add_btn"
																onClick={triggerFileSelectPopup}
																type="button"
															>
																{" "}
																<i className="fa fa-camera"></i> <br /> Add
																Photo
															</button>

															{imgSrc && (
																<button
																	onClick={() => onUpload()}
																	type="button"
																	className="save_btn"
																>
																	<i className="fa-solid fa-paper-plane"></i>
																	<br /> Save
																</button>
															)}
														</>
													) : (
														""
													)}

													{student?.childImageUrl && (
														<button
															onClick={() => removeAvatar()}
															type="button"
															className="remove_btn"
														>
															<i className="fa fa-trash"></i>
															<br /> Delete
														</button>
													)}
												</div>
											</div>

											<div className="RemovePhoto"></div>
										</div>
									</div>
								</form>
							</div>
						</Modal.Body>
					</Modal>
				</div>
</>
    
  )
}

export default AddProfileImage
