import React, { useEffect, useState } from "react";
import * as image from "../../resources/images";
import { addDayNameInDate, formatDate, getDatesBetween, getMonthAndDayByDate, getProfileName, getSequnceSort, getUsStatnderdTime, nameSorting, showRoundValue } from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { MSG } from "../../utils/Messages";
import RoundProgress from "../controls/RoundProgress";

const BurgerTab = ({ handleReports }) => {
    return (
        <div className="learner_pLan_dropdown">
            <span aria-haspopup="true"
                className="pointer"
                aria-expanded="false"

                id="leanerplndrpdwn"
                data-toggle="dropdown">
                <i class="fa-sharp fa-solid fa-bars"></i>
            </span>
            <ul className="dropdown-menu dropdown-menu-end lPDropdwnList dropdwn_leaner_" aria-labelledby="leanerplndrpdwn">
                <li className="select_reports">
                    <p className="flexone">
                    <img src={image.reports_docs} className="mr-2 report_Iocns" />Select Report
                    </p>
                </li>
                <li onClick={() => handleReports("OverallProgress")}>
                    <p className="flexone">
                        <img src={image.student_ProfileIcons} className="mr-2 report_Iocns" />Student Progress
                    </p>
                </li>
                <li onClick={() => handleReports("GrowthSurvey")}>
                    <p className="flexone">
                        <img src={image.growthSurveyIcon1} className="mr-2  report_Iocns" />Growth Survey
                    </p>
                </li>
                <li onClick={() => handleReports("PlanSummary")}>
                    <p className="flexone">
                        <img src={image.PlanSummaryIcon} className="mr-2  report_Iocns" />Advancement Summary
                    </p>
                </li>
            </ul>
        </div>
    );
}
export default BurgerTab;