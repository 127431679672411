import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as image from "../../resources/images";
import { useDispatch, useSelector } from "react-redux";
import {
	breadcrumb,
	resetMultiquizResponse,
	smartQuizData,
} from "../../redux/actions";
import {
	getAcquisitionMultiQuiz,
	getMyPlan,
	attemptedAcquisitionMultiSurvey,
} from "../../redux/actions/APIs";
import ProgressBar from "../../components/controls/ProgressBar";
import IntelligenceAcquisitionResult from "./IntelligenceAcquisitionResult";
import { ShimmerCategoryList } from "react-shimmer-effects";
import {
	getDimShuffle,
	getSequnceSort,
	nameSorting,
} from "../../utils/helper";
import { beginMultiQuiz } from "../../redux/actions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { PATHS } from "../../utils";

const AcquisitionMultiQuiz = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const history = useHistory();

	const {
		breadcrumbData,
		beginQuiz,
		acquisitionMultiQuizData,
		getMyPlanData,
		userAcquisitionAttemptedResponse,
		defaultChildData
	} = useSelector((state) => state.collections);

	const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
	const [totalAttempt, setTotalAttempt] = useState(0);
	const [quizData, setQuizData] = useState([]);
	const [finalArray, setFinalArray] = useState([]);
	const [isOptionSelected, setIsOptionSelected] = useState(false);
	const [loader, setLoader] = useState(false);
	const [loadRespone, setLoadResponse] = useState(false);
	const [scSurvey, setScSurvey] = useState(false);
	const [scSurveyId, setScSurveyId] = useState();

	useEffect(() => {
		dispatch(getMyPlan(params.id));
		dispatch(beginMultiQuiz(false));
	}, [params.id]);

	useEffect(() => {
		dispatch(attemptedAcquisitionMultiSurvey());
		dispatch(getMyPlan(params.id));
		dispatch(resetMultiquizResponse());
		setLoadResponse(true);
		dispatch(getAcquisitionMultiQuiz());
		dispatch(beginMultiQuiz(false));
		dispatch(
			getAcquisitionMultiQuiz(params.id, getMyPlanData?.records[0]?.planId)
		);
	}, [params.id, getMyPlanData?.records[0]?.planId]);

	useEffect(() => {
		if (userAcquisitionAttemptedResponse?.success) {
			setLoader(false);
			setTotalAttempt(0);
			setScSurvey(true);
			dispatch(
				getAcquisitionMultiQuiz(params.id, getMyPlanData?.records[0]?.planId)
			);
		}
	}, [userAcquisitionAttemptedResponse, loader, totalAttempt]);

	useEffect(() => {
		if (!breadcrumbData) {
			dispatch(
				breadcrumb({
					title: "My Plans",
					subTitle: "CASEL Plus Plan",
					skillAcqisition: "Skill Acquisition Survey",
					//	icon: image.acquisitionsurvey,
				})
			);
		}
	}, [breadcrumbData]);

	useEffect(() => {
		if (acquisitionMultiQuizData) {
			window.scrollTo(0, 0);
			//	setQuizData(getDimShuffle(acquisitionMultiQuizData?.records[0]?.questions));
			let dataSorting = nameSorting(acquisitionMultiQuizData?.records[0]?.questions, "surveySequence");
			//	let result = [];
			let singleArray = [];
			for (let i = 0; i < dataSorting?.length; i += 5) {
				const chunk = getSequnceSort(dataSorting.slice(i, i + 5));
				//	result.push(chunk);
				singleArray = singleArray.concat(chunk);
			}
			setQuizData(singleArray);
			let scSurvey = acquisitionMultiQuizData?.records[0]?.questions;
			setScSurveyId(scSurvey[0]?.scSurveyId);
			setScSurvey(scSurvey[0]?.isSkillAcquisitionAttempted);
			setLoadResponse(false);
		}
	}, [acquisitionMultiQuizData]);

	const selectOption = (event, currentQuestionIndex, selectedOptIndx, opt) => {
		let isSelected = 100 / acquisitionMultiQuizData?.recordsCount;
		let data = [...quizData];

		const isAlreadyAttempted = data[currentQuestionIndex]?.options?.some(
			(option) => option.isUserSelected
		);
		if (!isAlreadyAttempted) {
			//	let progressBar = 20 * (((selectedQuestionIndex + 1) % 5) == 0 ? 5 : (selectedQuestionIndex + 1) % 5);
			let progressBar = 20 * data[currentQuestionIndex]?.sequence;
			setTotalAttempt(progressBar);
			//	setTotalAttempt(totalAttempt + isSelected);
		}

		data[currentQuestionIndex].options[selectedOptIndx].isUserSelected =
			event.target.checked;

		data[currentQuestionIndex].options.forEach((option, index) => {
			if (index !== selectedOptIndx) {
				option.isUserSelected = false;
			}
		});

		dispatch(
			smartQuizData({
				attemptedQuestions: quizData.filter((q) => q.attempted),
				onQuestionClick: handleQuestionClick,
			})
		);

		setQuizData(data);
		setIsOptionSelected(true);
		let selectedFinalAnswer = [...finalArray];
		let selectedData = {
			surveyId: data[currentQuestionIndex].surveyId,
			quesId: data[currentQuestionIndex].id,
			optId: data[currentQuestionIndex].options[selectedOptIndx].id,
			point: quizData[currentQuestionIndex].options[selectedOptIndx].points,
		};
		selectedFinalAnswer[currentQuestionIndex] = selectedData;
		setFinalArray(selectedFinalAnswer);
	};

	const handleContinue = (nextQuestionIndex, type, selectedQuestionIndex) => {
		let data = [...quizData];
		/*		
		if (nextQuestionIndex % 5 == 0) {
			nextQuestionIndex = 1;			
		}
		*/
		if (type === "Next") {
			window.scrollTo(0, 0);
			data[nextQuestionIndex].attempted = true;
			setSelectedQuestionIndex(nextQuestionIndex);
			const isAnyOptionSelected = data[nextQuestionIndex].options.some(option => option.isUserSelected);
			if (isAnyOptionSelected) {
				setTotalAttempt(nextQuestionIndex % 5 == 0 ? 20 : data[nextQuestionIndex]?.sequence * 20);
			} else {
				setTotalAttempt(nextQuestionIndex % 5 == 0 ? 0 : (data[nextQuestionIndex]?.sequence - 1) * 20);
			}

		} else if (type === "Back") {
			window.scrollTo(0, 0);
			setSelectedQuestionIndex(nextQuestionIndex);
			if (data[selectedQuestionIndex].attempted) {
				setTotalAttempt(selectedQuestionIndex % 5 == 0 ? 20 : data[selectedQuestionIndex]?.sequence * 20);
			}
			setTotalAttempt(nextQuestionIndex % 5 == 0 ? 20 : data[nextQuestionIndex]?.sequence * 20);
		}
		dispatch(
			smartQuizData({
				attemptedQuestions: quizData.filter((q) => q?.attempted),
				onQuestionClick: handleQuestionClick,
			})
		);

		setQuizData(data);
	};

	const handleSubmit = () => {
		setLoader(true);
		if (scSurveyId) {
			dispatch(attemptedAcquisitionMultiSurvey(
				params.id,
				scSurveyId,
				finalArray
			)
			);
		}

	};

	useEffect(() => {
		if (setScSurvey) {
			dispatch(beginMultiQuiz(false));
		}
	}, [userAcquisitionAttemptedResponse]);

	let quizResponse = acquisitionMultiQuizData && acquisitionMultiQuizData?.records[0]?.multiintel;

	const handleQuestionClick = (quesIndex) => {
		setSelectedQuestionIndex(quesIndex);
		let data = [...quizData];
		const isAnyOptionSelected = data[quesIndex].options.some(option => option.isUserSelected);
		if (isAnyOptionSelected) {
			setTotalAttempt(quesIndex % 5 == 0 ? 20 : data[quesIndex]?.sequence * 20);
		}

	};

	const handleQuiz = () => {
		dispatch(beginMultiQuiz(true));
		setTotalAttempt(0);
		setSelectedQuestionIndex(0);
		if (acquisitionMultiQuizData) {
			let data = [...quizData];
			data[0].attempted = true;
			setQuizData(data);
			window.scrollTo(0, 0);
		}
		dispatch(
			smartQuizData({
				attemptedQuestions: quizData.filter((q) => q.attempted),
				onQuestionClick: handleQuestionClick,
			})
		);
	};

	const backToMyPlan = () => {
		dispatch(getMyPlan())
		history.push({
			pathname: PATHS.STR_MYPLAN + defaultChildData?.id,
		});
	}

	return (
		<>

			{userAcquisitionAttemptedResponse ||
				(acquisitionMultiQuizData && quizResponse?.length > 0) ||
				scSurvey ? (
				<>
					<div class="backpageStrip  false">
						<a
							href="#"
							className={`flexone false`}
							onClick={() => backToMyPlan()}
						>
							<span clclassNameass="">
								<i className="fa-solid fa-angle-left mr-1"></i>
							</span>
							Back
						</a>
					</div>
					<IntelligenceAcquisitionResult
						quizResponse={quizResponse}
						loadRespone={loadRespone}
						showResult={scSurvey}
					/>
				</>
			) : !acquisitionMultiQuizData ? (
				// || (!quizResponse && !loadRespone)
				<div className="quizblok">
					<ShimmerCategoryList items={5} categoryStyle="STYLE_SIX" />{" "}
				</div>
			) : !loadRespone ? (
				// quizResponse?.length === 0 &&
				!beginQuiz ? (
					<>
						<div className="Course_height">
							<div class="backpageStrip  false">
								<a
									href="#"
									className={`flexone false`}
									onClick={() => backToMyPlan()}
								>
									<span clclassNameass="">
										<i className="fa-solid fa-angle-left mr-1"></i>
									</span>
									Back
								</a>
							</div>
							<div class="smartquizbanner text-center">
								<img src={image.acquisitionbnr} alt="" className="m-auto" />
							</div>
							<div className="pt-3 mt-3 mb-3 beginAssessmentbtn">
								<button
									onClick={() => handleQuiz()}
									className="btn-blue btn-login d-block  m-auto w-auto"
								>
									<i className="fa-solid fa-paper-plane mr-2"></i>Begin
									Survey
								</button>
							</div>
						</div>
					</>
				) : (
					beginQuiz && (
						<div className="fullHeight100 skillAquisationSurver">
							<div className="Course_height">
								<div className=" skill_aquization_survery ">
									<div className="w-75 p-0 m-auto flextwo   mt-3">
										<h4>{quizData[selectedQuestionIndex]?.skillName}</h4>
									</div>
									<div className="quizblok skill_aquization_survery m-0">

										<div class="backpageStrip p-0 false">


											<a
												href="#"
												className={`flexone false ${selectedQuestionIndex === 0 ? "disabledevent" : ""
													}`}
												onClick={() =>
													handleContinue(
														selectedQuestionIndex - 1,
														"Back",
														selectedQuestionIndex
													)
												}
											>
												<span clclassNameass="">
													<i className="fa-solid fa-angle-left mr-1"></i>
												</span>
												Back
											</a>
										</div>
										<ProgressBar value={totalAttempt} data={totalAttempt} />
										{/* <ProgressBar value={20} data={totalAttempt} /> */}

										<span className="procompt">
											{/* {totalAttempt > 0 && getRoundNumber(totalAttempt)}{" "} */}
											{/* {totalAttempt > 0 && "%"} {totalAttempt == 0 && "0%"}  */}
											{/* {selectedQuestionIndex + 1}/{acquisitionMultiQuizData?.recordsCount} */}
											{/* {((selectedQuestionIndex + 1) % 5) == 0 ? 5 : (selectedQuestionIndex + 1) % 5}/5 */}
											{quizData[selectedQuestionIndex]?.sequence} / 5
										</span>

										<div class="backpageStrip p-0 false flexone">
											<div className="CourseSlidearrrow">
												<span class="ScenePagination">
													<strong class="mr-2 ">{quizData[selectedQuestionIndex]?.surveySequence}</strong>of<strong class="ml-2">{acquisitionMultiQuizData?.recordsCount / 5}</strong>
												</span>
											</div>
											<span class=" false">

												<a
													href="#"
													className={`false ${!quizData[selectedQuestionIndex]?.options.some(
														(option) => option.isUserSelected
													) ||
														selectedQuestionIndex + 1 ===
														acquisitionMultiQuizData?.recordsCount
														? "disabledevent"
														: ""
														}`}
													onClick={() => {
														handleContinue(
															selectedQuestionIndex + 1,
															"Next",
															selectedQuestionIndex
														);
													}}
												>
													Next
													<span className="">
														<i className="fa-solid fa-angle-right ml-1"></i>
													</span>
												</a>
											</span>
										</div>
									</div>

									<div className="smartquizwrapper">
										<div className="smqzQueslist">
											<div className="signupType m-0 mb-3">
												<div key={Math.random()}>
													<h3 className="flexone align-items-baseline mb-3">
														<span>
															{/* <i className='fa-light fa-clipboard-question mr-2'></i> */}
															<img src={image.questionIcon} />
														</span>{" "}
														{quizData &&
															quizData[selectedQuestionIndex]?.question}
													</h3>
													{quizData && quizData[selectedQuestionIndex]?.sequence != 5 &&
														getSequnceSort(
															quizData[selectedQuestionIndex]?.options
														)?.map((opt, optInd) => (
															<label
																className={`Selcheckbox ${quizData[selectedQuestionIndex].options[
																	optInd
																].isUserSelected
																	? "selActivelabel"
																	: ""
																	}`}
																key={optInd}
															>
																{opt?.question}

																<input
																	type="radio"
																	id={opt.id}
																	value={opt?.id}
																	checked={opt?.isUserSelected}
																	onChange={(event) =>
																		selectOption(
																			event,
																			selectedQuestionIndex,
																			optInd,
																			quizData[selectedQuestionIndex]
																		)
																	}
																></input>

																<span className="checkmark"></span>
															</label>
														))
													}
													{quizData && quizData[selectedQuestionIndex]?.sequence == 5 &&
														quizData[selectedQuestionIndex]?.options?.map((opt, optInd) => (
															<label
																className={`Selcheckbox ${quizData[selectedQuestionIndex].options[
																	optInd
																].isUserSelected
																	? "selActivelabel"
																	: ""
																	}`}
																key={optInd}
															>
																{opt?.question}

																<input
																	type="radio"
																	id={opt.id}
																	value={opt?.id}
																	checked={opt?.isUserSelected}
																	onChange={(event) =>
																		selectOption(
																			event,
																			selectedQuestionIndex,
																			optInd,
																			quizData[selectedQuestionIndex]
																		)
																	}
																></input>

																<span className="checkmark"></span>
															</label>
														))
													}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="input-group full-Width-group basic_details_form pagebuttonStrip bottom-0">
									<div class="form-group BDsubmitbutton d-flex justify-content-between align-items-center m-0">
										{selectedQuestionIndex > 0 && (
											<button
												type="button"
												className="btn-blue btn-login d-block mb-5 ml-auto back_button"
												onClick={() =>
													handleContinue(
														selectedQuestionIndex - 1,
														"Back",
														selectedQuestionIndex
													)
												}
											>
												<i className="fa-solid fa-arrow-left mr-2"></i> Back
											</button>
										)}

										{quizData && quizData.length > 0 && (
											<div className="w-100">
												{selectedQuestionIndex + 1 <
													acquisitionMultiQuizData?.recordsCount && (
														// selectedQuestionIndex + 1 < acquisitionMultiQuizData?.recordsCount &&
														<div className="priceWrap p-0">
															<button
																type="submit"
																className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
																disabled={
																	!quizData[selectedQuestionIndex]?.options.some(
																		(option) => option.isUserSelected
																	)
																}
																onClick={() => {
																	handleContinue(
																		selectedQuestionIndex + 1,
																		"Next",
																		selectedQuestionIndex
																	);
																}}
															>
																Next
																<i className="fa-solid fa-arrow-right ml-2 m-0"></i>
															</button>
														</div>
													)}
												{/* {selectedQuestionIndex + 1 === acquisitionMultiQuizData?.recordsCount && */}
												{selectedQuestionIndex + 1 ===
													acquisitionMultiQuizData?.recordsCount &&
													(loader ? (
														<div className="priceWrap p-0">
															<button
																className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
																key={Math.random()}
																disabled
															>
																<span className="RounAnimation mr-1"></span>
																Please wait
															</button>
														</div>
													) : (
														<div className="priceWrap p-0">
															<button
																type="submit"
																className="btn-blue btn-login d-block w-auto mb-5 ml-auto"
																onClick={() => {
																	handleSubmit();
																}}
																disabled={
																	!quizData[
																		selectedQuestionIndex
																	]?.options.some(
																		(option) => option.isUserSelected
																	)
																}
															>
																<i className="fa-solid fa-paper-plane mr-2"></i>
																Submit
															</button>
														</div>
													))}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					)
				)
			) : (
				<ShimmerCategoryList items={5} categoryStyle="STYLE_SIX" />
			)}
		</>
	);
};

export default AcquisitionMultiQuiz;
